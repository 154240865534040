import React from "react"
import "../styles/review-sub-simple.css"
import { useSwiper } from "swiper/react"

export default function ReviewSubSimple(props) {
  let { settings, onReviewSelect, data } = props
  const swiper = useSwiper()

  return (
    <div className="review-simple-container">
      <div className="review-sub-simple-popup">
        <div className="popup-content">
          <p className="icon">🤩</p>
          <h3 className="highlight">
            {settings?.reviewPageTitle?.text ?? "Review Submitted!"}
          </h3>
          <p className="review-simple-highlight">
            {settings?.reviewPageTitle?.text ??
              "Thanks for sharing your feedback with us!"}
          </p>
          <div className="center">
            <button
              onClick={() => {
                setTimeout(
                  () => window.parent.postMessage({ showRcf: true }, "*"),
                  200
                )
                setTimeout(() => {
                  swiper.slideTo(0)
                }, 300)
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
