import React from 'react'
import "swiper/css"
import "swiper/css/navigation"
import "./ManCraftNuggets.scss"

// import required modules
import { Autoplay, Navigation } from "swiper"
import { SwiperSlide, Swiper } from "swiper/react"
import { useSearchParams } from "react-router-dom"
import { isMobile } from "react-device-detect"
import { MancraftVerifiedBadge } from '../../Svgs/Svg'
import moment from 'moment'
import { getFirstNameAndLastInitial } from '../../helper'

export default function ManCraftNuggets({ nuggetSettings, nuggets, storeName }) {

    const [searchParams] = useSearchParams()


    let nuggetLayout = searchParams.get("nuggetLayout") ?? ""
    return (
        <>

            {nuggetLayout === "2" ?

                <div
                    className="mancraft-nuggests-slider-content"
                    style={{
                        background: nuggetSettings?.reviewNuggets?.backgroundColor ?? "#E6E7ED",
                        "--nugget-scroll-color":
                            nuggetSettings?.reviewNuggets?.scrollColor,
                    }}
                >
                    <Swiper
                        navigation={true}
                        autoplay={
                            nuggetSettings?.generalSetting?.autoScroll
                                ? {
                                    delay:
                                        nuggetSettings?.generalSetting?.autoPlaySpeed ??
                                        5000,
                                }
                                : false
                        }
                        slidesPerView={1}
                        spaceBetween={42}
                        modules={[Navigation, Autoplay]}
                        className="mancraft-nuggets-slider"
                    >
                        {nuggets?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="nuggets-slide">
                                    <div
                                        className="top-section"
                                        style={{
                                            color: nuggetSettings?.reviewNuggets?.textColor,
                                            fontSize: isMobile ? nuggetSettings?.typoGraphy?.mobileFontSize : nuggetSettings?.typoGraphy?.desktopFontSize
                                        }}
                                    >
                                        <div className='top-section-inside d-flex justify-content-between w-100'>
                                            <div className="happy-customer-stars d-flex gap-1">
                                                {Array(item.reviewRating)
                                                    .fill()
                                                    .map((_, i) => (
                                                        <div className="star">

                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" fill={"#041231"}
                                                                    stroke={"#041231"} />
                                                            </svg>

                                                        </div>
                                                    ))}
                                                {Array(5 - item.reviewRating)
                                                    .fill()
                                                    .map((_, i) => (
                                                        <div className="star">

                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" fill={"transparent"}
                                                                    stroke={"#041231"} />
                                                            </svg>
                                                        </div>
                                                    ))}
                                            </div>

                                            {!(nuggetSettings?.reviewNuggets?.hideCustomerName) && (
                                                <div className='verified-buyer d-flex gap-1 align-items-center'>
                                                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.125 0L0 3.23745V8.09363C0 12.5856 3.04 16.7862 7.125 17.806C11.21 16.7862 14.25 12.5856 14.25 8.09363V3.23745L7.125 0ZM5.54167 12.9498L2.375 9.71235L3.49125 8.57115L5.54167 10.6593L10.7587 5.32561L11.875 6.4749L5.54167 12.9498Z" fill="#041231" />
                                                    </svg>
                                                    <span>Verified Buyer</span>

                                                </div>
                                            )}
                                        </div>
                                        {/* {getFirstNameAndLastInitial(item?.reviewerName)} */}
                                        {/* {(!nuggetSettings?.reviewNuggets?.hideCustomerName &&
                                    item?.reviewerName) ?? item?.reviewerName}&nbsp;&nbsp;
                                {(!nuggetSettings?.reviewNuggets?.hideStoreName &&
                                    storeName) ?? storeName} */}
                                    </div>
                                    <p
                                        style={{
                                            color: nuggetSettings?.reviewNuggets?.textColor,
                                            fontSize: isMobile ? nuggetSettings?.typoGraphy?.mobileFontSize :
                                                nuggetSettings?.typoGraphy?.desktopFontSize
                                        }}
                                    >
                                        {item?.reviewText}
                                    </p>

                                    <div className='d-flex justify-content-between bottom-section'>
                                        <div className='customer-name'>
                                            {(!nuggetSettings?.reviewNuggets?.hideCustomerName &&
                                                item?.reviewerName) ? getFirstNameAndLastInitial(item?.reviewerName) : ""}
                                        </div>
                                        <div className='review-date'>
                                            {moment(item?.createdAt).format("DD MMM YYYY")}
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div> : <div
                    className="mancraft-nuggests-slider-content"
                    style={{
                        background: "transparent",

                    }}
                >
                    <Swiper
                        navigation={true}
                        autoplay={
                            nuggetSettings?.generalSetting?.autoScroll
                                ? {
                                    delay:
                                        nuggetSettings?.generalSetting?.autoPlaySpeed ??
                                        5000,
                                }
                                : false
                        }
                        slidesPerView={1}
                        spaceBetween={42}
                        modules={[Navigation, Autoplay]}
                        className="mancraft-nuggets-slider"
                        style={{ padding: "0px" }}
                    >
                        {nuggets?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="nuggets-slide">

                                    <p
                                        style={{
                                            color: nuggetSettings?.reviewNuggets?.textColor,
                                            fontSize: isMobile ? nuggetSettings?.typoGraphy?.mobileFontSize :
                                                nuggetSettings?.typoGraphy?.desktopFontSize,
                                            lineHeight: "22.5px"
                                        }}
                                    >
                                        {item?.reviewText}
                                    </p>

                                    <div className='d-flex justify-content-between bottom-section'>
                                        <div className='customer-name' >
                                            {(!nuggetSettings?.reviewNuggets?.hideCustomerName &&
                                                item?.reviewerName) ? getFirstNameAndLastInitial(item?.reviewerName) : ""}
                                        </div>
                                        <div className='review-date'>
                                            {/* {moment(item?.createdAt).format("DD MMM YYYY")} */}
                                        </div>
                                    </div>

                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>}
        </>
    )
}
