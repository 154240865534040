import React, { useState } from "react"
import QuoliLogo from "../../../asst/quoli-logo.svg"
import "../../../styles/ali-express-popup.css"
import ReactSelect from "react-select"
import { Button, Input } from "reactstrap"
import ApiManager from "../../../classes/ApiManager"
import { useSearchParams } from "react-router-dom"

const ImportPopUp = () => {
  const [prodArray, setProdArray] = useState([])
  const [productId, setProductId] = useState(null)
  const [productHandle, setProductHandle] = useState(null)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isCheckbox, setIsCheckbox] = useState(false)
  const [SelectedRating, setSelectedRating] = useState("all")

  const [searchParams] = useSearchParams()
  const apiManager = ApiManager.getInstance()
  let xToken = searchParams.get("store")
  let url = searchParams.get("url")
  apiManager._setXToken(xToken)

  React.useEffect(() => {
    try {
      apiManager
        .get("getAllProduct")
        .then((res) => {
          console.log("getAllProductssss", res)
          const options = res.product.map((item) => ({
            value: item.id,
            label: item.title,
            handle: item.handle,
          }))
          setProdArray(options)
          console.log(options, "thisIsBusy")
        })
        .catch((error) => {
          console.error("Error fetching data:", error)
        })
    } catch (error) {
      console.log("error here", error)
    }
  }, [])

  const callAliExpress = () => {
    let body = {
      productId: productId,
      url: encodeURIComponent(url),
      reviewsCount: selectedOption,
      productHandle: productHandle,
      isMostRecent: isCheckboxChecked,
      isPhotoVideo: isCheckbox,
      selectedRating: SelectedRating ?? 'all',
    }
    console.log("body", body)
    apiManager
      .post("importAliExpress", body)
      .then((res) => {
        console.log("importAliExpress", res)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "7px",
      display: "flex",
      justifyContent: "end",
      border: "1px solid #E7E7E7",
      transition: "border-color 0.3s",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
  }

  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
    console.log(selectedOption, "selectedoptionhere")
  }

  const handleFetchCheckBox = (event) => {
    setIsCheckboxChecked(event.target.checked)
    console.log(isCheckboxChecked, "MostRecent")
  }
  const handleFetchCheckBox2 = (event) => {
    setIsCheckbox(event.target.checked)
    console.log(isCheckboxChecked, "PhotVide")
  }
  return (
    <div className="container-popup">
      <div className="box-popup">
        <div className="main-heading-div">
          <img src={QuoliLogo} alt="quoli-logo" width={37} />
          <span className="popup-main-heading">Quoli</span>
        </div>

        <div>
          <div className="select-product-popup">
            <span>Select Product</span>
          </div>
          <ReactSelect
            styles={customStyles}
            name="colors"
            options={prodArray}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={`Select product from https://${xToken}`.substring(55, 0) + "..."}
            onChange={(e) => {
              setProductId(e.value)
              setProductHandle(e.handle)
            }}
          />
        </div>

        <div>
          <div className="review-import-heading">
            <span>Reviews to import</span>
          </div>
          <div className="flex-container-popup">
            <div className="radio-review-div">
              <input
                type="radio"
                name="review"
                id="20"
                value="20"
                checked={selectedOption === "20"}
                onChange={handleOptionChange}
              />
              <label htmlFor="20">20 Reviews</label>
            </div>
            <div className="radio-review-div">
              <input
                type="radio"
                name="review"
                id="50"
                value="50"
                checked={selectedOption === "50"}
                onChange={handleOptionChange}
              />
              <label htmlFor="50">50 Reviews</label>
            </div>
            <div className="radio-review-div">
              <input
                type="radio"
                name="review"
                id="100"
                value="100"
                checked={selectedOption === "100"}
                onChange={handleOptionChange}
              />
              <label htmlFor="100">100 Reviews</label>
            </div>
          </div>
        </div>

        <div className="custom-container-popup">
          <div className="parent-div-popup">
            <div className="child-div-popup">
              <span className="popup-rating">Rating</span>
            </div>
            <Input
              className="dropDown-select"
              type="select"
              name="drop"
              id="dropdowm"
              onChange={(e) => {
                console.log("setSelectedRating", e.target.value)
                setSelectedRating(e.target.value)
              }}
            >
              <option

                value="all"
                selected
                className="dropdown-selected-new-rcf"
              >
                All Rating
              </option>
              <option

                value="5"

                className="dropdown-selected-new-rcf"
              >
                5 Star
              </option>
              <option

                value="4"

                className="dropdown-selected-new-rcf"
              >
                4 Star
              </option>
              <option

                value="3"

                className="dropdown-selected-new-rcf"
              >
                3 Star
              </option>

            </Input>
          </div>
          {/* <div className="parent-div-popup">
            <div className="child-div-popup">
              <span className="popup-rating">Reviews from</span>
            </div>
            <Input
              className="dropDown-select"
              type="select"
              name="drop"
              id="dropdowm"
            >
              <option
                disabled
                value=""
                selected
                className="dropdown-selected-new-rcf"
              >
                All Countries
              </option>
            </Input>
          </div> */}
        </div>

        <div>
          <div className="rating-container-popup ">
            <input
              type="checkbox"
              name=""
              id=""
              className="checkbox-input-popup"
              onChange={handleFetchCheckBox}
            />
            <span>Fetch most recent reviews only</span>
          </div>
          <div className="rating-container-popup ">
            <input
              type="checkbox"
              name=""
              id=""
              className="checkbox-input-popup"
              onChange={handleFetchCheckBox2}
            />
            <span>Fetch reviews that have any media</span>
          </div>
        </div>
        <div className="button-container-popup">
          {/* <Button className="go-back-btn">Go Back</Button> */}
          <Button className="start-btn" onClick={callAliExpress}>
            Start Importing
          </Button>
        </div>
        <div className="powered-quoli-popup">
          <span>Powered by Quoli</span>
        </div>
      </div>
    </div>
  )
}

export default ImportPopUp
