import React, { useEffect, useState } from "react"
import "../rcf/new-rcf-style.css"
import PlusImage from "../../../asst/Plus-more-pics.svg"
import imgShareUpload from "../../../asst/ImageShareUpload.svg"
import Cancel from "../../../asst/Cancel.svg"
import {
  decryptData,
  encryptData,
  getThumbnailImageUrl,
  link,
  uploadToS3,
} from "../../../helper"
import ApiManager from "../../../classes/ApiManager"
import { useSearchParams } from "react-router-dom"
import NewTellUsStarter from "./NewTellUsStarted"
import NewRatePopup from "./NewRatePopup"
import ReviewImage from "../../../images/review-popup-image.png"
import NewThatsAll from "./NewThatsAll"
import NewReviewSubSimple from "./NewReviewSubSimple"
import SectionLoader from "./SectionLoader"
import ReactSelect from "react-select"

export default function StoreReview() {
  const apiManager = ApiManager.getInstance()

  const [searchParams, setSearchParams] = useSearchParams()
  const paramsdata = searchParams.get("params")
  const decrypttData = decryptData(paramsdata)
  console.log("decryptData", decrypttData)
  apiManager._setXToken(decrypttData.merchantId)

  const [currentComponentIndex, setCurrentComponentIndex] = useState(0)
  const [error, setError] = useState(true)
  const [attributeValue, setAttribute] = useState([])
  const [requiredAttributes, setRequiredAttributes] = useState(false)
  const [data, setData] = useState({})
  const [isTyped, setisTyped] = useState(false)
  const [starCheck, setStarCheck] = useState(null)
  const [starReview, setReviewCheck] = useState(null)
  const [attributeAnswerValue, setAttributeAnswerValue] = useState(true)
  const [attributeAnswerMultipleValue, setAttributeAnswerMultipleValue] =
    useState(true)
  const [settings, setSettings] = useState({})
  const [collectSettings, setCollectSettings] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [showLoaderSub, setShowLoaderSub] = useState(true)
  const [pageLoader, setPageLoader] = useState(true)
  const [errorAttribute, setErrorAttribute] = useState(false)

  // const [alreadyAdded, setAlreadyAdded] = useState(false)
  const [files, setFiles] = useState([])
  const [codeData, setCodeData] = useState(null)
  const [attributes, setAttributes] = useState([])
  // const [displayReviewData, setDisplayReviewData] = useState([])
  console.log("asjdhd", files)
  const handleContinueClick = () => {
    setCurrentComponentIndex(currentComponentIndex + 1)
    window.scrollTo({ top: 0 })
  }
  const handleBack = () => {
    setCurrentComponentIndex(currentComponentIndex - 1)
    window.scrollTo({ top: 0 })
  }
  // function getQueryString() {
  //   var queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');
  //   var qsJsonObject = {};
  //   if (queryStringKeyValue != '') {
  //     for (let i = 0; i < queryStringKeyValue.length; i++) {
  //       qsJsonObject[queryStringKeyValue[i].split('=')[0]] = queryStringKeyValue[i].split('=')[1];
  //     }
  //   }
  //   return qsJsonObject;
  // }
  let rcfvalue = true

  // const [locationName, setLocationName] = useState({
  //   name: "",
  //   latitude: "",
  //   longitude: "",
  // })
  const [photoRcf, setPhotoRcf] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [prodArray, setProdArray] = useState([])
  const [productHandle, setProductHandle] = useState(null)
  const [productName, setProductName] = useState(null)
  const [productImage, setProductImage] = useState(null)

  console.log(prodArray, "prodArrayprodArray")
  const togglePhotoReview = () => {
    setPhotoRcf(!true)
  }

  let photoReviews = collectSettings?.photoReviews?.show ?? true
  let videoReviews = collectSettings?.videoReviews?.show ?? true

  console.log(photoReviews, "mylengthPhoto")

  React.useEffect(() => {
    const receiveMessage = (event) => {
      rcfvalue = event.data // Access the rcf value sent from parent
      // Use the received value here
      console.log("Received rcf value:", rcfvalue)
    }

    window.addEventListener("message", receiveMessage)

    return () => {
      // Clean up event listener when the component unmounts
      window.removeEventListener("message", receiveMessage)
    }
  }, [])
  let xToken = decrypttData.merchantId
  let storeName = decrypttData.storeName
  let url = searchParams.get("url")
  apiManager._setXToken(xToken)

  React.useEffect(() => {
    try {
      apiManager
        .get("getAllProduct", { block: true })
        .then((res) => {
          console.log("getAllProductssss", res)
          const options = res.product.map((item) => ({
            value: item.id,
            label: item.title,
            handle: item.handle,
            image: item?.image?.src,
          }))
          setProdArray(options)
          console.log(options, "thisIsBusy")
        })
        .catch((error) => {
          console.error("Error fetching data:", error)
        })
    } catch (error) {
      console.log("error here", error)
    }
  }, [])

  // const [locationError, setLocationError] = useState(null)
  // const getLocation = async () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const latitude = position.coords.latitude
  //         const longitude = position.coords.longitude

  //         // Fetch location name based on latitude and longitude
  //         try {
  //           const response = await fetch(
  //             `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoibnVtYW5pcWJhbCIsImEiOiJjbHQ4d3U2a3owMzJ5MnFvYmttMHQzMXB3In0.BI8gpBPZIEP8jbUXMb6Peg`
  //           )
  //           console.log("responseMylocation", response)
  //           if (!response.ok) {
  //             throw new Error("Failed to fetch location name")
  //           }
  //           const data = await response.json()
  //           console.log(data, "=====dataa")
  //           setLocationName({
  //             name: data?.features[1]?.place_name,
  //             latitude: latitude,
  //             longitude: longitude,
  //           })
  //         } catch (error) {
  //           console.error("Error fetching location name:", error)
  //           setLocationError(error.message)
  //         }
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error)
  //         setLocationError("Error getting location: " + error.message)
  //       }
  //     )
  //   } else {
  //     setLocationError("Geolocation is not supported by this browser.")
  //   }
  // }

  // useEffect(() => {
  //   getLocation()
  // }, [])

  const submit = async (userData) => {
    setShowLoader(true)
    console.log("submit1231231231111", userData)
    let body = {
      merchantId: xToken,
      reviewRating: data?.review,
      reviewTitle: data?.title ?? "",
      reviewDescription: data?.description,
      productName: productName,
      title: productName,
      productHandle: productHandle,
      productID: productId,
      productImage: productImage,
      source: "store",
      mediaType: 3,
      contentType: 2,
      storeName: storeName,
      reviewerName: userData?.reviewerName ?? data?.reviewerName,
      reviewerEmail: userData?.reviewerEmail ?? data?.reviewerEmail,
      images: [],
      isAuthorized: rcfvalue ? rcfvalue : false,
    }

    console.log("submit123123123", body)

    if (!body.mediaUrl) {
    }
    console.log("body==>", body)
    console.log("data", { data: encryptData(body) })

    try {
      // Create a new FormData object
      const formData = new FormData()

      if (files.length > 0) {
        body.contentType = 1
        // Append files to the FormData object
        files.forEach((file, index) => {
          formData.append(`files`, file)
        })
      }

      formData.append("data", encryptData(body))

      fetch(link + "addVReview", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "x-token": searchParams.get("id"),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("submit", res)
          setCodeData(res?.review)
          setShowLoaderSub(false)
          setShowLoader(false)
          if (res?.message === 2003) handleContinueClick()
          else alert("already added")
        })
        .catch((error) => {
          setShowLoader(false)
          console.error("Error fetching data:", error)
        })
    } catch (error) {
      setShowLoader(false)
      console.error("Error fetching data:2", error)
    }
  }
  console.log("data==>", data)

  React.useEffect(() => {
    getPlan()
    try {
      let body = {
        productID: searchParams.get("productId"),
        merchantId: searchParams.get("merchantId"),
        productImage: searchParams.get("productImage"),
        item: {
          image: searchParams.get("productImage"),
          title: searchParams.get("productName"),
        },
      }
      console.log("body==>", body)
      setData({ ...body, attributes: [] })
      console.log("bodycheckStatus", body)
      console.log("body", encryptData(body))

      fetch(link + "getCollectReviews?merchantId=" + body?.merchantId)
        .then((response) => response.json())
        .then((res) => {
          console.log("getCollectReviews", res?.result)
          setSettings(res?.result?.newReviewForm)
          setCollectSettings(res?.result)
        })
        .catch((error) => {
          console.error("Error fetching data:", error)
        })

      getAttributes()
      setError(false)
    } catch (error) {
      setError(true)
    }
  }, [])

  const [brandSettingsData, setBrandSettingsData] = useState({})
  const [productId, setProductId] = useState(null)

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsRFC", response)
        setBrandSettingsData(response.result)
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
  }, [])

  const getPlan = async () => {
    apiManager
      .get("getPlan")
      .then((res) => {
        console.log("getPlan232", res)
        apiManager?._setSubscription(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  const getAttributes = async () => {
    apiManager
      .get("getAttributes")
      .then((res) => {
        console.log("setAttributes", res)
        setAttributes(res?.result)
        setPageLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  useEffect(() => {
    console.log(data, "thisIsDataClg")
    console.log(data, "....thisIsDataClg")
    if (
      data?.review === undefined ||
      data?.description == "" ||
      data?.description === undefined
      // ||
      // data?.attributes.length === 0
    ) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }, [data])

  const callAliExpress = () => {
    let body = {}
    console.log("body", body)
    apiManager
      .post("importAliExpress", body)
      .then((res) => {
        console.log("importAliExpresssj", res)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  console.log("settings---rate---my", data)
  console.log("settings-->", settings)

  console.log(showLoader, "loaderrrrr")

  console.log(starCheck, "setStarChecksetStarCheck")
  console.log(starReview, "starReviewstarReview")
  // console.log(attributeAnswerValue, "attributeAnswerValue====>>")
  // console.log(attributeAnswerMultipleValue,"attributeAnswerMultipleValue====>>>")

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "7px",
      display: "flex",
      justifyContent: "end",
      border: "1px solid #E7E7E7",
      transition: "border-color 0.3s",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
  }

  console.log(prodArray, "======>product")
  console.log(
    brandSettingsData?.generalAppearance?.logo,
    "brandSettingsData?.generalAppearance?.logo"
  )
  return (
    <>
      {pageLoader ? (
        <SectionLoader />
      ) : showLoaderSub ? (
        showLoader ? (
          <SectionLoader />
        ) : (
          <div className="rcf-product-main-container">
            <div
              className="rcf-product-div-heading"
              style={{
                color: settings?.fontColor ?? "#000",
                backgroundColor: settings?.backgroundColor ?? "#fff",
                padding: "20px",
              }}
            >
              <div className="rcf-main-heading">
                {brandSettingsData?.generalAppearance?.logo ? (
                  <img
                    src={getThumbnailImageUrl(
                      brandSettingsData?.generalAppearance?.logo
                    )}
                    alt="Description of the image"
                    style={{ width: "100px" }}
                  ></img>
                ) : (
                  "Store logo"
                )}
              </div>
              <hr style={{ margin: "0px" }} />
              <NewRatePopup
                onTitleChange={(e) => {
                  setData({ ...data, title: e.target.value })
                }}
                onReviewSelect={(rating) => {
                  console.log("rating", rating)
                  setData({ ...data, review: rating })
                }}
                data={data}
                settings={settings}
                setStarCheck={setStarCheck}
                starCheck={starCheck}
                storeName={storeName}
                xToken={xToken}
              />

              <hr style={{ margin: "0px" }} />
              <div className="store-optional-product">
                <p>Leaving review for a product? (optional)</p>
                <ReactSelect
                  styles={customStyles}
                  name="colors"
                  options={prodArray}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Product"
                  onChange={(e) => {
                    setProductId(e.value)
                    setProductHandle(e.handle)
                    setProductName(e.label)
                    setProductImage(e.image)
                  }}
                />
              </div>
              {files.length < 1 ? (
                <label htmlFor="share-img1">
                  <div
                    className="rcf-picture-uploader"
                    style={{
                      color: settings?.fontColor ?? "#000",
                      "--rate-background": settings?.backgroundColor ?? "#fff",
                    }}
                  >
                    <div>
                      <img src={imgShareUpload} alt="imgShareUpload" />
                    </div>
                    <div className="img-rfc-main-div-upload">
                      <div className="share-section">
                        <div
                          className="share-img"
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        >
                          <input
                            style={{ display: "none" }}
                            type="file"
                            name="share"
                            id="share-img1"
                            accept="image/*,video/*"
                            multiple
                            onChange={(e) => {
                              console.log("asjdhd", e.target.files)
                              setFiles((prevFiles) => [
                                ...prevFiles,
                                ...e.target.files,
                              ])
                            }}
                          />

                          <span
                            className="img-uploader-text"
                            style={{
                              color: settings?.fontColor ?? "#000",
                            }}
                          >
                            {/* {settings?.reviewPageTitle?.text ??
                              collectSettings?.discountForPhotoAndVideoReview
                                ?.typeOfDiscount === "no"
                              ? "Share Photo"
                              : `Share photo/Videos & get ${collectSettings
                                ?.discountForPhotoAndVideoReview
                                ?.typeOfDiscountValue === "Custom"
                                ? collectSettings
                                  ?.discountForPhotoAndVideoReview
                                  ?.typeOfDiscountValueCustom
                                : collectSettings
                                  ?.discountForPhotoAndVideoReview
                                  ?.typeOfDiscountValue ?? "15"
                              }${collectSettings
                                ?.discountForPhotoAndVideoReview
                                ?.typeOfDiscount === "perc"
                                ? "$"
                                : "%"
                              } off`} */}
                            {"Share Photos/Videos & Help Others"}
                          </span>
                          <p
                            className="upload-img-span-tag"
                            style={{
                              color: settings?.fontColor ?? "#000",
                            }}
                          >
                            Click here to upload
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              ) : (
                <div
                  className="rcf-picture-uploader"
                  style={{
                    color: settings?.fontColor ?? "#000",
                    "--rate-background": settings?.backgroundColor ?? "#fff",
                  }}
                >
                  <div>
                    <div className="new-rcf-plus-uploader">
                      <div className="share-img">
                        <input
                          style={{ display: "none" }}
                          type="file"
                          name="share"
                          id="share-img"
                          accept="image/*,video/*"
                          multiple
                          onChange={(e) => {
                            console.log("asjdhd", e.target.files)
                            setFiles((prevFiles) => [
                              ...prevFiles,
                              ...e.target.files,
                            ])
                          }}
                        />
                        <label htmlFor="share-img">
                          <img src={PlusImage} alt="PlusImage" />
                        </label>
                      </div>
                    </div>
                  </div>
                  {files.map((file, index) => {
                    console.log("here it", files)
                    console.log("here it", file)
                    return (
                      <div className="added-img" key={index}>
                        <div className="img-info">
                          {file.type.startsWith("video/") ? (
                            <video
                              src={URL.createObjectURL(file)}
                              alt={`review ${index + 1}`}
                              className="uplaod-content-rcf"
                            />
                          ) : (
                            <div className="new-rcf-seen-uploader">
                              <img
                                className="rcf-new-image-upload uplaod-content-rcf"
                                key={index}
                                src={URL.createObjectURL(file) ?? ReviewImage}
                                alt={`review ${index + 1}`}
                              />
                            </div>
                          )}
                          {/* <div className="info">
                            <p className="image-name">{file.name}</p>
                            <p className="image-size">{file.size} kb</p>
                          </div> */}
                        </div>
                        <div
                          onClick={() => {
                            setFiles((prevFiles) => {
                              return prevFiles.filter((prevFile) => {
                                return prevFile.name !== file.name
                              })
                            })
                          }}
                        >
                          <img
                            src={Cancel}
                            className="seenImage-cancel-cross"
                            alt="SeenImage"
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}

              <NewTellUsStarter
                storeName={storeName}
                errorAttribute={errorAttribute}
                setErrorAttribute={setErrorAttribute}
                requiredAttributes={requiredAttributes}
                setRequiredAttributes={setRequiredAttributes}
                attributeValue={attributeValue}
                setAttribute={setAttribute}
                settings={settings}
                setisTyped={setisTyped}
                data={data}
                setFile={setFiles}
                attributes={attributes}
                onDescriptionChange={(e) => {
                  setData({ ...data, description: e.target.value })
                }}
                onTitleChange={(e) => {
                  setData({ ...data, title: e.target.value })
                }}
                onAttributeSelect={(attribute) => {
                  setData({ ...data, attributes: attribute })
                }}
                setReviewCheck={setReviewCheck}
                starReview={starReview}
                setAttributeAnswerValue={setAttributeAnswerValue}
                setAttributeAnswerMultipleValue={
                  setAttributeAnswerMultipleValue
                }
              />
              <hr style={{ margin: "0px" }} />
              <NewThatsAll
                errorAttribute={errorAttribute}
                setErrorAttribute={setErrorAttribute}
                requiredAttributes={requiredAttributes}
                setRequiredAttributes={setRequiredAttributes}
                setAttribute={setAttribute}
                attributeValue={attributeValue}
                isTyped={isTyped}
                onContinueClick={submit}
                handleBack={handleBack}
                attributes={attributes}
                isDisable={isDisable}
                settings={settings}
                data={data}
                starCheck={starCheck}
                setStarCheck={setStarCheck}
                starReview={starReview}
                setReviewCheck={setReviewCheck}
                setFile={setFiles}
                setShowLoaderSub={setShowLoaderSub}
                onNameChange={(e) => {
                  setData({ ...data, reviewerName: e })
                }}
                onEmailChange={(e) => {
                  setData({ ...data, reviewerEmail: e })
                }}
              />
            </div>
          </div>
        )
      ) : (
        <NewReviewSubSimple settings={settings} />
      )}
    </>
  )
}
