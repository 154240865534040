import React, { useEffect } from "react"
import ApiManager from "../../classes/ApiManager"
import { QuoliVerifyIcon, QuoliVerifyIconDark } from "../../Svgs/Svg"
let apiManager = ApiManager.getInstance()

const PoweredByQuoli = () => {
  useEffect(() => {
    apiManager
      .get("getPlan")
      .then((res) => {
        console.log("getPlan", res)
        apiManager?._setSubscription(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }, [])
  return (
    <div>
      {apiManager?._getSubscription()?.name?.includes("Free") && (
        <div class="verified-section d-flex align-items-center">
          <a
            target="_blank"
            href="https://quoli.io"
            className="verified-text-tag"
          >
            <QuoliVerifyIconDark /> <span>Powered by Quoli</span>
          </a>
        </div>
      )}
    </div>
  )
}

export default PoweredByQuoli
