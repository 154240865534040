import React, { useState, useEffect } from "react"
import { Button } from "reactstrap"
import QuoliLogo from "../../../asst/quoli-logo.svg"

import { useSwiper } from "swiper/react"
import PoweredByQuoli from "../PoweredByQuoli"

export default function NewThatsAll(props) {
  let {
    settings,
    attributeValue,
    errorAttribute,
    setErrorAttribute,
    setRequiredAttributes,
    requiredAttributes,
    setAttribute,
    isTyped,
    onEmailChange,
    onNameChange,
    onContinueClick,
    setShowLoader,
    setShowLoaderSub,
    setReviewCheck,
    isDisable,
    starCheck,
    starReview,
    setStarCheck,
    data,
    attributes,
  } = props
  const swiper = useSwiper()

  const [isTyped1, setIsTyped1] = useState(false)
  const [isTyped2, setIsTyped2] = useState(false)
  const [isTyped3, setIsTyped3] = useState(false)

  const [fName, setFName] = useState("")
  const [lName, setLName] = useState("")
  const [email, setEmail] = useState("")

  const [error, setError] = useState({})

  const [isValid, setIsValid] = useState(true)

  const handleInputChange1 = (e) => {
    setIsTyped1(e.target.value !== "")
    setFName(e.target.value)
    setError({
      ...error,
      firstName: null,
    })
  }

  const handleInputChange2 = (e) => {
    setIsTyped2(e.target.value !== "")
    setLName(e.target.value)
    setError({
      ...error,
      lastName: null,
    })
  }

  const handleInputChange3 = (e) => {
    setIsTyped3(e.target.value != "")
    console.log("emaail", e.target.value, isTyped3)
    const newEmail = e.target.value
    // Regular expression to validate email addresses
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    setIsValid(emailRegex.test(newEmail))
    console.log("submit123123123", newEmail)
    // if (isValid) {
    setEmail(newEmail)
    // onEmailChange(newEmail)
    // }
  }
  console.log("hello", email)

  console.log("font--->", settings?.fontColor)
  console.log("backgroundColor--->", settings?.backgroundColor)
  console.log(data.attributes, "thisISdara")
  console.log(isDisable, "this is disable btn")
  console.log(error.firstName, "1982hdqjsdg")
  console.log(settings, "thisIsattributeSetting")
  return (
    <div className="thats-all-container new-thats-all">
      <div
        className="thats-all-popup"
        style={{
          color: settings?.fontColor ?? "#000",
          "--rate-background": settings?.backgroundColor ?? "#fff",
        }}
      >
        <div className="popup-content new-popup-rcf">
          <div className="thats-all-inputs new-thats-all-rcf">
            <div className="input-feild-rcf-main-div">
              <div className="input-field-main-div-rcf">
                {/* <label
                  htmlFor="first-name"
                  className={isTyped1 ? "label-active" : "label"}
                >
                  First Name
                </label> */}
                <input
                  type="text"
                  id="first-name"
                  autoComplete={"off"}
                  className={`input-field-rcf-review ${error.firstName ? "error" : ""
                    }`}
                  placeholder="First Name*"
                  onChange={handleInputChange1}
                  style={{ color: settings?.fontColor ?? "#000" }}
                />
                <p className="error-description" style={{ color: "red" }}>
                  {error.firstName && "Please enter first name"}
                </p>
              </div>

              <div className="input-field-main-div-rcf">
                <input
                  type="text"
                  id="last-name"
                  autoComplete={"off"}
                  className={`input-field-rcf-review ${error.lastName ? "error" : ""
                    }`}
                  placeholder="Last Name*"
                  onChange={handleInputChange2}
                  style={{ color: settings?.fontColor ?? "#000" }}
                />
                <p className="error-description" style={{ color: "red" }}>
                  {error.lastName && "Please enter last name"}
                </p>
              </div>
            </div>
            <div className="email-input">
              {/* <label
                htmlFor="email-input"
                className={isTyped3 ? "label-active" : "label"}
              >
                Email
              </label> */}
              <input
                type="email"
                name=""
                id="email"
                autoComplete={"off"}
                className={`input-field-rcf-review ${!isValid ? "error" : ""}`}
                placeholder="Email*"
                onChange={handleInputChange3}
                style={{ color: settings?.fontColor ?? "#000" }}
              />
              <p className="error-description" style={{ color: "red" }}>
                {!isValid ? "Please enter valid email" : ""}
              </p>
            </div>
          </div>
          <p className="error-description" style={{ color: "red" }}>
            {(starCheck || starReview) && "Please fill all required field {*}"}
          </p>
          <div style={{ display: "flex", flexDirection: "column", gap: "0px" }}>
            <div>
              <p
                className="rcf-policy-term-tag"
                style={{
                  color: settings?.toolTipColor ?? "grey",
                }}
              >
                By continuing you agree to Quoli’s{" "}
                <a
                  className="rcf-policy-term-tag"
                  href="https://quoli.io/terms-of-service/"
                  target="_blank"
                  style={{
                    color: settings?.toolTipColor ?? "grey ",
                  }}
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  className="rcf-policy-term-tag"
                  href="https://quoli.io/privacy-policy/"
                  target="_blank"
                  style={{
                    color: settings?.toolTipColor ?? "grey ",
                  }}
                >
                  Privacy Policy.
                </a>{" "}
              </p>
              <p
                className="rcf-policy-term-tag"
                style={{
                  color: settings?.toolTipColor ?? "grey",
                }}
              >
                Your email won’t be shown publicly and we never share emails
                with others.
              </p>
            </div>
          </div>
          <div className="submit-btn-rcf-main-div-cont">
            <Button
              // disabled={isDisable}
              className={`rcf-submit-btn ${error.isTyped1 && error.isTyped2 && error.isTyped3 && isValid
                  ? "next-btn"
                  : "next-btn-dis"
                }`}
              onClick={() => {
                console.log(error, "this is error data")
                console.log(starCheck, starReview, "hithereStarCheck")
                console.log(starReview, "hithereReviewcheck")
                setError({
                  ...error,
                  firstName: !Boolean(isTyped1),
                  lastName: !Boolean(isTyped2),
                  email: !Boolean(isTyped3),
                })
                if (starCheck == null) {
                  console.log(starCheck, "aagsdalskdhladlahf")
                  setStarCheck(true)
                }
                if (starReview == null) {
                  setReviewCheck(true)
                }
                if (isValid === true) {
                  setIsValid(true)
                }
                // if (!error && isTyped) {
                //   const areAllRequiredOptionsSelected = attributes?.filter((obj) => obj.required)
                //     .every((requiredObj) =>
                //       attributeValue?.some(
                //         (selected) => selected._id === requiredObj._id
                //       )
                //     )
                console.log(data.attributes, "asjdasdasss")

                // else if (areAllRequiredOptionsSelected) {
                //   swiper.slideNext()
                //   window.scrollTo({ top: 0 })
                // }

                // console.log("this is SetIsdisbale site", se)
                console.log("Boolean(isTyped1)", Boolean(isTyped1))
                console.log("hello", email)
                if (
                  Boolean(isTyped1) &&
                  Boolean(isTyped2) &&
                  Boolean(isTyped3) &&
                  isValid &&
                  !starCheck &&
                  !starReview
                ) {
                  console.log("hello", email)
                  console.log(starCheck, starReview, "starcech")

                  onContinueClick({
                    reviewerName: fName + " " + lName,
                    reviewerEmail: email,
                  })

                  // setShowLoaderSub(false)
                }
              }}
            >
              {settings?.continueButtonTitle ?? "Agree & Submit"}
            </Button>
          </div>

          <PoweredByQuoli />
          {/* <div className="last-submit-heading-main-container">
            <span className="rcf-company-heading-powered">Powered by</span>
            <div className="quoli-logo-main-div">
              <img src={QuoliLogo} alt="QuoliLogo" />
              <span className="quoli-title-text-tag">Quoli</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
