import React, { useState, useEffect } from "react";
import "./Pagination.scss";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [displayedPages, setDisplayedPages] = useState([]);

  // Function to handle pagination click
  const handlePageClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  // Function to update displayed pages based on current page
  const updateDisplayedPages = () => {
    const totalDisplayPages = 3; // You can change this to control the number of displayed pages
    let startPage = Math.max(
      1,
      currentPage - Math.floor(totalDisplayPages / 2)
    );

    if (currentPage <= 3) {
      startPage = 1;
    } else if (currentPage >= totalPages - 2) {
      startPage = Math.max(1, totalPages - totalDisplayPages + 1);
    }

    const pages = [];
    for (let i = 0; i < totalDisplayPages && startPage <= totalPages; i++) {
      pages.push(startPage);
      startPage++;
    }

    setDisplayedPages(pages);
  };

  // Call the updateDisplayedPages function when totalPages or currentPage changes
  useEffect(() => {
    updateDisplayedPages();
  }, [totalPages, currentPage]);

  return (
    <nav className="pdp-pagination-wrapper pt-4">
      <ul className="pdp-pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => handlePageClick(currentPage - 1)}
          >
            Prev
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? "active" : ""}`}>
          <button className="page-link" onClick={() => handlePageClick(1)}>
            1
          </button>
        </li>

        {displayedPages[0] > 1 && (
          <li className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        )}

        {displayedPages.map((page, index) => {
          if (page < totalPages && page > 1) {
            return (
              <li
                key={index}
                className={`page-item ${currentPage === page ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageClick(page)}
                >
                  {page}
                </button>
              </li>
            );
          }
        })}

        {displayedPages[displayedPages.length - 1] < totalPages && (
          <li className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        )}

        {totalPages > 1 && (
          <li
            className={`page-item ${
              currentPage === totalPages ? "active" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageClick(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        )}

        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => handlePageClick(currentPage + 1)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
