import { useSearchParams } from "react-router-dom"
import ApiManager from "../../../../classes/ApiManager"
import { TaskCompleted } from "../../PDPConstants/PDPContants"
import "./QuestionForm.scss"
import { useState } from "react"

const QuestionForm = ({ ctaFillColor, ctaTextColor, handleSubmit, isSubmit }) => {
  const apiManager = ApiManager.getInstance()

  const [searchParams] = useSearchParams()
  apiManager._setXToken(searchParams.get("merchantId"))
  let xToken = searchParams.get("store")
  let url = searchParams.get("url")
  let productName = searchParams.get("productName")
  apiManager._setXToken(xToken)

  console.log(url, "urlsideIstHis")
  console.log(xToken, "xTokenxTokenxToken")
  console.log(productName, "productNameproduc")
  // console.log(xToken, "xTokenxTokenxToken")

  const submit = false
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const [email, setEmail] = useState("")

  const [isValid, setIsValid] = useState(true)
  const handleEmailChange = (e) => {
    const newEmail = e.target.value
    // Regular expression to validate email addresses
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    setIsValid(emailRegex.test(newEmail))
    console.log("submit123123123", newEmail)
    setEmail(newEmail)
    if (isValid)
      setData({
        ...data,
        email: newEmail,
        lastName: null,
        productName: productName,
      })
  }

  console.log(data, "thisisdataaaa")

  // console.log(data.question.length, "datatatatata")

  return (
    <>
      {!isSubmit ? (
        <div className="question-form-wrapper ">
          <p
            className="pdp-widget-product-heading"
            style={{ textAlign: "start" }}
          >
            Ask question about {productName}
          </p>
          <hr style={{ margin: "25px 0px" }} />
          <form className="question-form">
            <div className="input-wrapper" style={{ marginBottom: "15px" }}>
              <label className="pdp-widget-question-heading">First Name*</label>
              <div className="half-width">
                <div
                  className="error-wrapper"
                // style={{ border: "1px solid red" }}
                >
                  <input
                    placeholder=""
                    type="text"
                    autoComplete={"off"}
                    className={error.firstName ? "error" : ""}
                    onChange={(e) => {
                      setData({ ...data, firstName: e.target.value })
                    }}
                    style={{ backgroundColor: "#EAEAEA" }}
                  />
                  <p className="error-description">
                    {error.firstName && "Please enter firstName"}
                  </p>
                </div>
              </div>
              <label className="pdp-widget-question-heading">Email*</label>
              <input
                placeholder=""
                type="email"
                autoComplete={"off"}
                className={
                  "full-width " + (error.email || !isValid ? "error" : "")
                }
                onChange={(e) => {
                  setData({ ...data, email: e.target.value })
                  handleEmailChange(e)
                }}
                style={{ backgroundColor: "#EAEAEA" }}
              />
              <p className="error-description hide-user-email">
                {error.email ||
                  (!isValid
                    ? "Please enter email"
                    : "Your email won’t be shown publicly or shared with others.")}
              </p>
            </div>
            <div className="input-wrapper" style={{ marginBottom: "15px" }}>
              <label className="pdp-widget-question-heading">
                What would you like to ask?*
              </label>
              <textarea
                placeholder=""
                className={error.question ? "error" : ""}
                autoComplete={"off"}
                onKeyDown={(e) => {
                  if (e.target.value.length >= 500 && e.key !== "Backspace") {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  const text = e.target.value
                  if (text.length <= 500) {
                    setData({ ...data, question: text })
                    console.log("data", data)
                  }
                }}
                style={{ backgroundColor: "#EAEAEA" }}
              />
              <p style={{ textAlign: "end" }} className="text-field-count">
                {data?.question?.length ?? 0}/500
              </p>
              <p className="error-description">
                {error.question && "Please enter question"}
              </p>
            </div>

            <button
              onClick={(e) => {
                e.preventDefault()
                setError({
                  ...error,
                  question: !Boolean(data.question),
                  firstName: !Boolean(data.firstName),
                  email: !Boolean(data.email),
                })
                if (
                  Boolean(data.question) &&
                  Boolean(data.firstName) &&
                  Boolean(data.email) &&
                  isValid
                ) {
                  handleSubmit(data)
                } else console.log("Not Submitted due to errors")
              }}
              style={{ width: "100%", borderRadius: "5px", backgroundColor: ctaFillColor, color: ctaTextColor }}
            >
              Submit Question
            </button>
            {/* <p>Your email address won't be published with your question.</p> */}
          </form>
        </div>
      ) : (
        <div className="task-completed">
          <TaskCompleted />
          <h4>Thanks for submitting!</h4>
          <p>You'll receive an email with your answer.</p>
        </div>
      )}
    </>
  )
}

export default QuestionForm