import React, { useState, useEffect } from "react"
import "../styles/thats-all.css"
import { useSwiper } from "swiper/react"
import {
  CircleCheck,
  CircleFill,
  ProggressLineDark,
  ProggressLineLite,
} from "../Svgs/Svg"

export default function ThatsAll(props) {
  let { settings, onEmailChange, onNameChange, onContinueClick } = props
  const swiper = useSwiper()

  const [isTyped1, setIsTyped1] = useState(false)
  const [isTyped2, setIsTyped2] = useState(false)
  const [isTyped3, setIsTyped3] = useState(false)

  const [fName, setFName] = useState("")
  const [lName, setLName] = useState("")
  const [email, setEmail] = useState("")

  const [error, setError] = useState({})

  const [isValid, setIsValid] = useState(true)

  const handleInputChange1 = (e) => {
    setIsTyped1(e.target.value !== "")
    setFName(e.target.value)
  }

  const handleInputChange2 = (e) => {
    setIsTyped2(e.target.value !== "")
    setLName(e.target.value)
  }

  const handleInputChange3 = (e) => {
    setIsTyped3(e.target.value != "")
    console.log("emaail", e.target.value, isTyped3)
    const newEmail = e.target.value
    // Regular expression to validate email addresses
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    setIsValid(emailRegex.test(newEmail))
    if (isValid) {
      setEmail(newEmail)
      // onEmailChange(newEmail)
    }
  }

  useEffect(() => {
    // onNameChange(fName + " " + lName)
  }, [fName, lName])


  console.log("font--->", settings?.fontColor)
  console.log("backgroundColor--->", settings?.backgroundColor)

  return (
    <div className="thats-all-container">
      <div className="thats-all-popup" style={{ color: settings?.fontColor ?? '#000', '--rate-background': settings?.backgroundColor ?? '#fff', }}>
        <div className="thats-all-progress-bar">
          <CircleCheck />
          <ProggressLineDark />
          <CircleCheck />
          <ProggressLineLite />
          <CircleCheck />
          <ProggressLineLite />
          <CircleFill />
        </div>
        <div className="popup-content">
          <h3 className="thats-all-highlight">
            {settings?.thatsAll?.text ?? "That's All."}
          </h3>
          <div className="thats-all-inputs">
            <div className="name-inputs">
              <div className="first-name-input">
                <label
                  htmlFor="first-name"
                  className={isTyped1 ? "label-active" : "label"}
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first-name"
                  autoComplete={"off"}
                  className={error.firstName ? "error" : ""}
                  placeholder="First Name"
                  onChange={handleInputChange1}
                  style={{ color: settings?.fontColor ?? '#000', }}
                />
                <p className="error-description">
                  {error.firstName && "Please enter first name"}
                </p>
              </div>

              <div className="last-name-input">
                <label
                  htmlFor="last-name"
                  className={isTyped2 ? "label-active" : "label"}

                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="last-name"
                  autoComplete={"off"}
                  className={error.lastName ? "error" : ""}
                  placeholder="Last Name"
                  onChange={handleInputChange2}
                  style={{ color: settings?.fontColor ?? '#000', }}
                />
                <p className="error-description">
                  {error.lastName && "Please enter last name"}
                </p>
              </div>
            </div>
            <div className="email-input">
              <label
                htmlFor="email-input"
                className={isTyped3 ? "label-active" : "label"}
              >
                Email
              </label>
              <input
                type="email"
                name=""
                id="email"
                autoComplete={"off"}
                className={!isValid ? "error" : ""}
                placeholder="Email"
                onChange={handleInputChange3}
                style={{ color: settings?.fontColor ?? '#000', }}
              />
              <p className="error-description">
                {!isValid ? "Please enter valid email" : ""}
              </p>
            </div>
          </div>
          <p className="terms" style={{ color: settings?.fontColor ?? '#000', }}>
            By continuing you agree to Quoli’s{" "}
            <a href="https://quoli.io/privacy-policy/" style={{ color: settings?.fontColor ?? '#000', }}>Terms and Conditions</a> and{" "}
            <a href="https://quoli.io/terms-of-service/" style={{ color: settings?.fontColor ?? '#000', }}>Privacy Policy.</a>{" "}
          </p>
        </div>
        <div className="popup-footer-btns">
          <button
            className="back-btn"
            onClick={() => {
              swiper.slidePrev()
            }}
          >
            {" "}
            &lt; Back{" "}
          </button>
          <button
            className={
              error.isTyped1 && error.isTyped2 && error.isTyped3 && isValid
                ? "next-btn"
                : "next-btn-dis"
            }
            onClick={() => {
              setError({
                ...error,
                firstName: !Boolean(isTyped1),
                lastName: !Boolean(isTyped2),
                email: !Boolean(isTyped3),
              });

              console.log("Boolean(isTyped1)", Boolean(isTyped1));
              if (
                Boolean(isTyped1) &&
                Boolean(isTyped2) &&
                Boolean(isTyped3) &&
                isValid
              ) {
                console.log("hello");
                onContinueClick({
                  reviewerName: fName + " " + lName,
                  reviewerEmail: email,
                });
                setTimeout(
                  () => window.parent.postMessage({ showRcf: true }, "*"),
                  4000
                )
                swiper.slideNext()
                window.scrollTo({ top: 0 })
              }
            }}
          >
            {settings?.continueButtonTitle ?? "Agree & Submit"}
          </button>
        </div>
      </div>
    </div>
  )
}
