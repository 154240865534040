import { Dropdown, ProgressBar } from "react-bootstrap"
import AddBtn from "../ChildComponents/AddBtn/AddBtn"
import CustomDropdown from "../ChildComponents/CustomDropdown/CustomDropdown"
import EGCSlider from "../ChildComponents/EGCSlider/EGCSlider"
import PDPSelect from "../ChildComponents/PDPSelect/PDPSelect"
import RatingBar from "../ChildComponents/RatingBar/RatingBar"
import RatingStars from "../ChildComponents/RatingStars/RatingStars"
import SearchInput from "../ChildComponents/SearchInput/SearchInput"
import {
  RedHeart,
  SimpleStar,
  StarIconBlack,
} from "../PDPConstants/PDPContants"
import "./Reviews.scss"
import "../Reviews/SingleReview/SingleReview.scss"
import SingleReview from "./SingleReview/SingleReview"
import PDPProgressBar from "../ChildComponents/PDPProgressBar/PDPProgressBar"
import ApiManager from "../../../classes/ApiManager"
import { useSearchParams, Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { getAttribute, getAverageRating, getRating } from "../../../helper"

import SingleImageSlider from "../ChildComponents/SingleImageSlider/SingleImageSlider"
import VideoModal from "../ChildComponents/VideoModal/VideoModal"
import PoweredByQuoli from "../../v2/PoweredByQuoli"
import LizandezReview from "./SingleReview/LizandezReview"

const items = [
  { label: <div>Most Recent</div>, value: "Most Recent" },
  { label: <div>Oldest</div>, value: "Oldest" },
  { label: <div>Photo Video</div>, value: "Photo Video" },
  { label: <div>Most Helpful</div>, value: "Most Helpful" },
  { label: <div>Positive First</div>, value: "Positive First" },
  { label: <div>Negative First</div>, value: "Negative First" },
]

// console.log("ratings", ratings)
const Reviews = ({
  sendMaxHeightToParent,
  reviewCardBorderColor,
  brandSettingsData,
}) => {
  const apiManager = ApiManager.getInstance()
  const [searchParams] = useSearchParams()
  const [reviews, setReviews] = useState({ docs: [] })
  const [photoReviews, setPhotoReviews] = useState({ docs: [] })

  const [reviewStats, setReviewStats] = useState()

  const [searchPhrase, setSearchPhrase] = useState("")

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectedRating, setSelectedRating] = useState("All")
  const [show, setShow] = useState(false)
  const [url, setUrl] = useState("")
  const [selectedAnswer, setSelectedAnswer] = useState([])
  const [loading, setLoading] = useState(false)

  console.log(selectedAnswer, "===>selectedAnswer")

  const handleSelectChange = (newAnswer) => {
    setSelectedAnswer((prevAnswers) => {
      if (prevAnswers.includes(newAnswer)) {
        return prevAnswers.filter((answer) => answer !== newAnswer)
      } else {
        return [...prevAnswers, newAnswer]
      }
    })
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  let productId = searchParams.get("productId")
  let xToken = searchParams.get("store")
  let happyCustomer = searchParams.get("happyCustomer") === "true"
  let productName = searchParams.get("productName")
  let productImage = searchParams.get("productImage")

  let showFilter = searchParams.get("showFilter") == "true"

  let limit = searchParams.get("limit")

  let showHelpfulButton = searchParams.get("showHelpfulButton")
  let sorting = searchParams.get("sort")
  let showProductLink = searchParams.get("showProductLink")
  let attributes = searchParams.get("attributes")

  let contentcolor = searchParams.get("contentcolor")

  let ugc = searchParams.get("ugc")
  let onlyText = false
  let reviewCta = searchParams.get("reviewCta") === "true"

  console.log(brandSettingsData, "===>>>branddddddata")
  let starColor = brandSettingsData?.generalAppearance?.starColor ?? "#ffc803"
  let primaryColor =
    brandSettingsData?.generalAppearance?.brandColor ?? "#ffffff"
  let secondaryColor =
    `${brandSettingsData?.generalAppearance?.brandColor}80` ?? "#cccccc"
  let reviewCtaText = "Write a Review"
  let noReviewText = "No Review"
  // let noReviewText = searchParams.get("noReviewText")
  // let attributes = searchParams.get("attributes")

  let ctaFillColor =
    brandSettingsData?.generalAppearance?.ctaFillColor ?? "#ffffff"
  console.log(ctaFillColor, "ctaFillColorReview")
  console.log(primaryColor, "primaryReviewColor")
  //ctaFOntNot Get Doubt their---------
  let ctaFontSize = searchParams.get("ctaFontSize") + "px"
  let ctaTextColor =
    brandSettingsData?.generalAppearance?.ctaTextColor ?? "#000000"
  let ctaBorderColor =
    brandSettingsData?.generalAppearance?.ctaBorderColor ?? "#e7e7e7"

  const [sort, setSort] = useState(sorting ?? "Most Recent")
  const [displayEfc, setDisplayEfc] = useState(false)
  const [efc, setEfc] = useState({})
  const [attributesArray, setAttributesArray] = useState([])
  const [avg, setAvg] = useState({})
  const [statsReviews, setStatsReviews] = useState({ docs: [] })

  apiManager._setXToken(xToken)
  useEffect(() => {
    apiManager.get("getGeneralSettings").then((res) => {
      apiManager._setGeneralSettings(
        res?.result?.reviewersNameFormat?.displayName
      )
    })
    getAvgAttributes()
    getAttributes()
    const getData = setTimeout(() => {
      getReviews()
    }, 500)
    return () => clearTimeout(getData)
  }, [selectedRating, sort, searchPhrase, selectedAnswer])

  useEffect(() => {
    let body2 = {
      merchantId: xToken,
      reviewStatus: 2,
    }
    let body = {
      merchantId: xToken,
      limit: limit || 20,
      reviewStatus: 2,
    }
    if (productId) {
      body.productId = productId
      body2.productId = productId
    }

    apiManager
      .get("getReviews", { ...body, contentType: 1, limit: 20 })
      .then((res) => {
        console.log("res===>", res.result)
        setPhotoReviews(res.result)
      })
    apiManager.get("getReviews", body2).then((res) => {
      console.log("statsReviews===>", res.result)
      setStatsReviews(res.result)
    })
    apiManager.get("getReviewStats", body2).then((res) => {
      console.log("getReviewStats===>", res.result)
      setReviewStats(res.result)
    })
  }, [])

  const getAttributes = () => {
    apiManager
      .get("getAttributes", { type: 2 })
      .then((res) => {
        console.log("getAttributes", res)
        setAttributesArray(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  const getReviews = (page = 1) => {
    let body = {
      merchantId: xToken,
      limit: limit ? parseInt(limit) : 10,
      page: parseInt(page),
      reviewStatus: 2,
      // sort = -1,
    }
    console.log(sort, "thisIsSortSide")
    console.log(sorting, "thisIsSortingSide")
    if (productId) {
      body.productId = productId
    }
    if (selectedRating !== "All") {
      body.reviewRating = selectedRating
    }
    if (sort === "Most Recent") {
      body.sortValue = "_id"
      body.sort = "desc"
    }
    if (sort === "Oldest") {
      body.sortValue = "_id"
      body.sort = "asc"
    }
    if (sort === "Photo Video") {
      body.contentType = 1
    }
    if (sort === "Positive First") {
      body.sortValue = "reviewRating"
      body.sort = "desc"
    }
    if (sort === "Negative First") {
      body.sortValue = "reviewRating"
      body.sort = "asc"
    }
    if (sort === "Most Helpful") {
      body.sortValue = "helpUpCount"
      body.sort = "desc"
    }
    if (searchPhrase !== "") {
      body.reviewDescription = searchPhrase
    }
    if (onlyText) {
      body.contentType = 2
    }
    if (selectedAnswer.length > 0) {
      // Assuming `body.selectedAnswer` is an array
      body.selectedAnswer = selectedAnswer
    }
    console.log(selectedAnswer, "selected===>")
    console.log("Body===>", body)

    apiManager.get("getReviews", body).then((res) => {
      console.log("setReviewsres===>", res.result)
      var data = []
      data = [...reviews?.docs, ...res?.result?.docs]
      if (body.page === 1) data = res?.result?.docs
      console.log(data, "datadata==>")
      setReviews({ ...res?.result, docs: data })
      getPlan()
      setLoading(false)
      setTimeout(() => {
        sendMaxHeightToParent()
      }, 200)
    })
  }
  const handleDropdownHover = () => {
    setDropdownOpen(true)
  }

  const handleDropdownLeave = () => {
    setDropdownOpen(false)
  }

  const getPlan = async () => {
    apiManager
      .get("getPlan")
      .then((res) => {
        console.log("getPlan232", res)
        apiManager?._setSubscription(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  const likeUnlikeReview = (id, type) => {
    console.log("id", id, type)
    apiManager
      .post("likeUnlikeReview", {
        id,
        type,
      })
      .then((res) => {
        console.log("res===>", res.response)
        reviews.docs?.forEach((review) => {
          console.log("review===>", review.id === id)
          if (review.id === id) {
            if (type === "like") {
              review.helpUpCount = review.helpUpCount + 1
            } else {
              review.helpDownCount = review.helpDownCount + 1
            }
          }
        })
        setTimeout(() => {
          setReviews({ ...reviews, docs: [...reviews.docs] })
        }, 500)
      })
      .catch((err) => {
        console.log("err===>", err)
      })
  }

  console.log(reviews, "===>>>reviews")

  const getAvgAttributes = () => {
    let body = {}
    if (productId) {
      body.productId = productId
    }
    apiManager
      .get("getAverageAttribute", body)
      .then((res) => {
        if (res?.totalEfc?.length > 0) {
          setEfc(res?.totalEfc[0])
          setDisplayEfc(true)
        }
        if (res?.totalAvg?.length > 0) setAvg(res?.totalAvg[0])

        console.log("getAverageAttribute", res)
      })
      .catch((err) => { })
  }

  console.log("starColor", starColor)

  const ratingValues = [1, 2, 3, 4, 5]
  const ratings = [
    // { label: <div>All</div>, value: "All" },
    ...ratingValues.map((item) => ({
      label: Array(item)
        .fill(
          <StarIconBlack
            color={starColor === "#null" ? "#000" : starColor}
            key={item}
          />
        )
        .map((star, index) => (
          <div key={index} className="rating-star-black">
            {star}
          </div>
        )),
      value: item,
    })),
  ]
  const loadMoreReview = () => {
    if (reviews.page < reviews.totalPages) {
      setLoading(true)
      getReviews(reviews.page + 1)
    }
  }


  function getTotalCount(data) {
    return data?.reduce((total, item) => total + item.count, 0);
  }


  console.log(primaryColor, "primaryColorprimaryColor")
  return (
    <div
      className={`pdp-reviews-wrapper `}
      style={{
        "--load-morehover-color": ctaFillColor,
        marginTop: xToken === "conatural.myshopify.com" ? "0px" : ''
      }}
    >
      {statsReviews?.docs?.length > 0 && (
        <div
          className={`review-rating-section  ${ugc && photoReviews?.docs?.length > 4 ? "" : "mb-4"
            } `}
        >
          {!(
            happyCustomer ||
            apiManager?._getSubscription()?.name?.includes("Free") ||
            !displayEfc
          ) ? (
            <div className={`based-on `} style={{ color: primaryColor }}>
              Based on
              <span className="no-of-reviews">
                {statsReviews?.totalDocs ?? "0"} Review
                {statsReviews?.totalDocs > 1 && "s"}
              </span>
            </div>
          ) : (
            <div className={`based-on `} style={{ color: primaryColor }}></div>
          )}
          <div className={`hover-rating-dropdown `}>
            <Dropdown
              onMouseEnter={handleDropdownHover}
              onMouseLeave={handleDropdownLeave}
              show={isDropdownOpen}
            >
              <div className={`total-rating ${xToken === "conatural.myshopify.com" && "conaturals"}`} style={{ color: primaryColor }}>
                <>{getAverageRating(reviewStats, statsReviews?.totalDocs)}/5</>
                <div className="d-flex gap-2">
                  <RatingStars
                    rating={getAverageRating(
                      reviewStats,
                      statsReviews?.totalDocs
                    )}
                    color={starColor ?? "#EB2136"}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                  >
                    <path
                      d="M15.1582 19.6401L7.6582 12.1401L9.4082 10.3901L15.1582 16.1401L20.9082 10.3901L22.6582 12.1401L15.1582 19.6401Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>

              {(happyCustomer ||
                apiManager?._getSubscription()?.name?.includes("Free") ||
                !displayEfc) && (
                  <>
                    <div
                      className="based-on based-on-noefc flex-row gap-1 justify-content-center pb-2"
                      style={{ color: secondaryColor, fontSize: xToken === "conatural.myshopify.com" && "14px" }}
                    >
                      Based on
                      <span className="no-of-reviews">
                        {statsReviews?.totalDocs ?? 0} Review
                        {statsReviews?.totalDocs > 1 && "s"}
                      </span>
                    </div>
                    <PoweredByQuoli />
                  </>
                )}
              <div
                className="based-on only-mobile based-on-noefc flex-row gap-2 justify-content-center pb-2"
                style={{ color: secondaryColor }}
              >
                Based on
                <span className="no-of-reviews">
                  {statsReviews?.totalDocs ?? 0} Review
                  {statsReviews?.totalDocs > 1 && "s"}
                </span>
              </div>
              <Dropdown.Menu show>
                <div className="rating-dropdown">
                  <div className={`rating-menu-item ${selectedRating === 5 ? "active" : ""}`} onClick={() => setSelectedRating(5)}>
                    <div className="item-amnt">
                      <div className="rating-star">
                        5 <SimpleStar color={starColor ?? "#EB2136"} />
                      </div>{" "}
                      <PDPProgressBar
                        currentValue={getRating(reviewStats, 5)}
                        totalValue={getTotalCount(reviewStats)}
                        color={starColor}
                      />
                      <div className="rating-no">
                        {getRating(reviewStats, 5)}
                      </div>
                    </div>

                  </div>
                  <div className={`rating-menu-item ${selectedRating === 4 ? "active" : ""}`} onClick={() => setSelectedRating(4)}>
                    <div className="item-amnt">
                      <div className="rating-star">
                        4 <SimpleStar color={starColor ?? "#EB2136"} />
                      </div>{" "}
                      <PDPProgressBar
                        currentValue={getRating(reviewStats, 4)}
                        totalValue={getTotalCount(reviewStats)}
                        color={starColor}
                      />
                      <div className="rating-no">
                        {getRating(reviewStats, 4)}
                      </div>
                    </div>

                  </div>
                  <div className={`rating-menu-item ${selectedRating === 3 ? "active" : ""}`} onClick={() => setSelectedRating(3)}>
                    <div className="item-amnt">
                      <div className="rating-star">
                        3 <SimpleStar color={starColor ?? "#EB2136"} />
                      </div>{" "}
                      <PDPProgressBar
                        currentValue={getRating(reviewStats, 3)}
                        totalValue={getTotalCount(reviewStats)}
                        color={starColor}
                      />
                      <div className="rating-no">
                        {getRating(reviewStats, 3)}
                      </div>
                    </div>

                  </div>
                  <div className={`rating-menu-item ${selectedRating === 2 ? "active" : ""}`} onClick={() => setSelectedRating(2)}>
                    <div className="item-amnt">
                      <div className="rating-star">
                        2 <SimpleStar color={starColor ?? "#EB2136"} />
                      </div>{" "}
                      <PDPProgressBar
                        currentValue={getRating(reviewStats, 2)}
                        totalValue={getTotalCount(reviewStats)}
                        color={starColor}
                      />
                      <div className="rating-no">
                        {getRating(reviewStats, 2)}
                      </div>
                    </div>

                  </div>
                  <div className={`rating-menu-item ${selectedRating === 1 ? "active" : ""}`} onClick={() => setSelectedRating(1)}>
                    <div className="item-amnt">
                      <div className="rating-star">
                        1 <SimpleStar color={starColor ?? "#EB2136"} />
                      </div>{" "}
                      <PDPProgressBar
                        currentValue={getRating(reviewStats, 1)}
                        totalValue={getTotalCount(reviewStats)}
                        color={starColor}
                      />
                      <div className="rating-no">
                        {getRating(reviewStats, 1)}
                      </div>
                    </div>

                  </div>

                  {avg?.totalCount && (
                    <div className="recommendation">
                      <RedHeart />{" "}
                      {isNaN((avg?.totalAvg / avg?.totalCount)?.toFixed(1) * 100) ? 0 : (avg?.totalAvg / avg?.totalCount)?.toFixed(1) * 100}%
                      Recommendation
                    </div>
                  )}
                  <PoweredByQuoli />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Powered By Version */}

          {/* <div className="powered-by-version">
          <div className="hover-rating-dropdown">
            <Dropdown
              onMouseEnter={handleDropdownHover}
              onMouseLeave={handleDropdownLeave}
              show={isDropdownOpen}
            >
              <div className="total-rating">
                3.5 / 5
                <RatingStars rating={3.5} color={"#EB2136"} />
              </div>
              <div className="based-on">
                Based on
                <span className="no-of-reviews">955 Reviews</span>
              </div>

              <div className="powered-by">
                Powered by <QuoliLogo />
              </div>
              <Dropdown.Menu>
                <div className="rating-dropdown">
                  <div className="rating-menu-item">
                    <div className="item-amnt">
                      <div className="rating-star">
                        5 <SimpleStar />
                      </div>{" "}
                      <div className="rating-no">850</div>
                    </div>
                    <PDPProgressBar currentValue={850} totalValue={935} />
                  </div>
                  <div className="rating-menu-item">
                    <div className="item-amnt">
                      <div className="rating-star">
                        4 <SimpleStar />
                      </div>{" "}
                      <div className="rating-no">450</div>
                    </div>
                    <PDPProgressBar currentValue={450} totalValue={935} />
                  </div>
                  <div className="rating-menu-item">
                    <div className="item-amnt">
                      <div className="rating-star">
                        3 <SimpleStar />
                      </div>{" "}
                      <div className="rating-no">304</div>
                    </div>
                    <PDPProgressBar currentValue={304} totalValue={935} />
                  </div>
                  <div className="rating-menu-item">
                    <div className="item-amnt">
                      <div className="rating-star">
                        2 <SimpleStar />
                      </div>{" "}
                      <div className="rating-no">106</div>
                    </div>
                    <PDPProgressBar currentValue={106} totalValue={935} />
                  </div>
                  <div className="rating-menu-item">
                    <div className="item-amnt">
                      <div className="rating-star">
                        1 <SimpleStar />
                      </div>{" "}
                      <div className="rating-no">50</div>
                    </div>
                    <PDPProgressBar currentValue={50} totalValue={935} />
                  </div>
                  <div className="recommendation">
                    <RedHeart /> 80% Recommendation
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}

          <div className="top-effectiveness-wrapper">
            {/* <div className="top-effectiveness">
            <span className="effectiveness-text"> Effectiveness</span>
            <div className="rating-bar-wrapper">
              <RatingBar rating={3.2} />
            </div>
            <div className="worked">
              <span>Not Worked</span>
              <span>Worked Very Well</span>
            </div>
           </div> */}
            {!apiManager?._getSubscription()?.name?.includes("Free") &&
              displayEfc &&
              getAttribute(efc, primaryColor)}
          </div>
        </div>
      )}
      {ugc === "true" && photoReviews?.docs?.length > 4 && (
        <div className="pdp-slider">
          <EGCSlider reviews={photoReviews} />
        </div>
      )}
      {!happyCustomer && (
        <div className="search-section d-flex align-items-center justify-content-between">
          {statsReviews?.docs?.length > 0 ? (
            xToken !== "conatural.myshopify.com" &&
            <SearchInput
              placeholder="Search for name or content"
              onChange={(e) => setSearchPhrase(e.target.value)}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
            />
          ) : (
            <div className="based-on" style={{ color: primaryColor }}>
              No reviews yet
            </div>
          )}
          {reviewCta && (
            <AddBtn
              ctaColor={{
                ctaFillColor: ctaFillColor,
                ctaTextColor: ctaTextColor,
                ctaBorderColor: ctaBorderColor,
                ctaFontSize: ctaFontSize,

              }}
              xToken={xToken}
              ctaFontSize={ctaFontSize}
              btnText={reviewCtaText ?? "Write a Review"}
              onClick={() => {
                // handleShow()
                if (xToken === "nbcpepsi.myshopify.com")
                  window.open(
                    `https://review.quoli.io/rcf?productId=${productId}&merchantId=nbcpepsi.myshopify.com&productName=${productName}&productImage=${productImage}&offside=true`,
                    "_blank"
                  )
                else
                  setTimeout(
                    () => window.parent.postMessage({ show: true }, "*"),
                    200
                  )
              }}
            />
          )}
        </div>
      )}
      {statsReviews?.docs?.length > 0 && (
        <>
          <div className={`dropdown-wrapper ${xToken !== "conatural.myshopify.com" && "pt-5"} justify-content-between`}>
            <div className="showing-number">
              <span
                className="pdp-showing-review"
                style={{
                  color: primaryColor,
                }}
              >{`Showing ${reviews?.docs?.length} Reviews`}</span>
            </div>
            {showFilter && (
              <div className="d-flex gap-3 filters-box">
                {attributesArray.length > 0 && (
                  <div className="pdp-filters-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                        >
                          <path
                            d="M10.5 18.3623V16.3623H14.5V18.3623H10.5ZM6.5 13.3623V11.3623H18.5V13.3623H6.5ZM3.5 8.3623V6.3623H21.5V8.3623H3.5Z"
                            fill="black"
                          />
                        </svg>{" "}
                        Filters
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div
                          className="selects-wrapper"
                          style={{ alignItems: "normal" }}
                        >
                          {(selectedRating !== "All" ||
                            selectedAnswer !== "") && (
                              <div
                                className="c-pointer new-clear-filter"
                                onClick={() => {
                                  setSelectedRating("All")
                                  setSelectedAnswer([])
                                  setAttributesArray([])
                                  getAttributes()
                                }}

                              >
                                Clear Filter
                              </div>
                            )}

                          <div>
                            <hr style={{ marginBottom: "0px", marginTop: "10px" }} />
                          </div>
                          {attributesArray?.map((item, index) => (
                            <PDPSelect
                              key={index}
                              defaultValue={item?.question}
                              options={item?.answers}
                              onChange={handleSelectChange}
                              primaryColor={primaryColor}
                              secondaryColor={secondaryColor}
                              setSelectedAnswer={setSelectedAnswer}
                            />
                            // <p> {item?.answers}</p>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                <div className="pdp-filters-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          d="M15.0858 6.05444H4.5"
                          stroke="black"
                          stroke-width="1.53246"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.0864 10.0242H7.80859"
                          stroke="black"
                          stroke-width="1.53246"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.0849 13.9949H11.1152"
                          stroke="black"
                          stroke-width="1.53246"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21.5001 17.4705L17.9375 21.0331L14.375 17.4705"
                          stroke="black"
                          stroke-width="1.53246"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.9375 5.80444V21.0332"
                          stroke="black"
                          stroke-width="1.38941"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      Sort
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {selectedAnswer?.length > 0 ?

                        "" :
                        <>
                          <p
                            style={{
                              textAlign: "end",
                              width: "100%",
                            }}
                            className="c-pointer new-clear-filter"
                            onClick={() => {
                              setSelectedAnswer([])
                              setSelectedRating("All")
                              setSort("Most Recent")
                              console.log("!selectedAnswer?.length > 0 ", selectedAnswer?.length)
                            }}
                          >
                            Clear Filter
                          </p>
                          <hr style={{ marginBottom: "0px", marginTop: "10px" }} />
                        </>}
                      <CustomDropdown
                        items={items}
                        textBefore="Sort by"
                        onSelect={(e) => {
                          console.log("rrrrrrrrr", e)
                          setSort(e)
                        }}
                        value={sort}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        setSelectedAnswer={setSelectedAnswer}
                        selectedRating={selectedRating}
                        sort={sort}
                      />
                      <CustomDropdown
                        items={ratings}
                        textBefore="Rating"
                        onSelect={(e) => {
                          setSelectedRating(e)
                        }}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        setSelectedAnswer={setSelectedAnswer}
                        selectedRating={selectedRating}
                        selectedAnswer={selectedAnswer}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>

          {reviews?.docs?.length > 0 && (
            <div className="reviews-wrapper gap-0">
              {reviews?.docs?.length > 0 ? (
                reviews?.docs?.map((review, index) => {
                  return (
                    <>
                      <SingleReview
                        key={index}
                        review={review}
                        multiImg={review % 2 !== 0}
                        likeUnlikeReview={likeUnlikeReview}
                        onSelect={(url) => {
                          setShow(true)
                          setUrl(url)
                        }}
                        reviewCardBorderColor={reviewCardBorderColor}
                        brandSettingsData={brandSettingsData}
                        xToken={xToken}
                      />

                      <hr style={{ margin: "0px" }} />
                    </>
                  )
                })
              ) : (
                <p>{noReviewText ?? "No Review"}</p>
              )}
              {reviews?.page === reviews?.totalPages ? null : (
                <div className="col-12 d-flex justify-content-center pt-4">
                  <div
                    className={`load-more-btn text-center c-pointer `}
                    onClick={loadMoreReview}
                    style={{
                      border: `1px solid ${xToken === "conatural.myshopify.com" ? "#acacac" : primaryColor}`,
                      color: primaryColor,
                      fontSize: "14px",
                      width: xToken === "conatural.myshopify.com" ? "180px" : "120px",
                      padding: xToken === "conatural.myshopify.com" ? "10px 14px" : "4px 4px",
                      borderRadius: xToken === "conatural.myshopify.com" ? "40px" : "5px",
                      textTransform: "uppercase",
                      fontWeight: xToken === "conatural.myshopify.com" ? "500" : ""


                    }}
                  >
                    {loading ? "Loading..." : "Show More"}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {/* <VideoModal url={url} show={show} handleClose={handleClose} /> */}
      {/* <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        className="popup-modal-content"
      >
        <Modal.Body>
          <Rcf />
        </Modal.Body>
      </Modal> */}
    </div>
  )
}

export default Reviews
