import {
  formatDate,
  getCookie,
  getFirstNameAndLastInitial,
  getTimeFromNow,
} from "../../../../helper"
import {
  Dislike,
  Dislike1,
  Like,
  Like1,
  StoreIcon,
  VerifiedIcon,
} from "../../PDPConstants/PDPContants"
import "./SingleQuestion.scss"

const SingleQuestion = ({
  key,
  id,
  question,
  likes,
  dislikes,
  askBy,
  verified,
  questionTiming,
  answers,
  likeUnlikeQa,
  qaSetting,
  brandSettingsData,
}) => {
  let questionCookie = getCookie("questionId")
  console.log(
    "getFirstNameAndLastInitial(askBy)",
    getFirstNameAndLastInitial(askBy)
  )
  console.log("getFirstNameAndLastInitial(askBy)", askBy)
  let primaryColor =
    brandSettingsData?.generalAppearance?.brandColor ?? "#000000"
  let secondaryColor =
    `
    ${brandSettingsData?.generalAppearance?.brandColor}80` ?? "#000000"
  let reviewCardColor =
    brandSettingsData?.generalAppearance?.cardFillColor ?? "#ffffff"
  let reviewCardColorAns =
    ` ${brandSettingsData?.generalAppearance?.reviewColor}15` ?? "#ffffff"
  let reviewCardBorderColor =
    brandSettingsData?.generalAppearance?.cardBorderColor ?? "#e7e7e7"

  console.log(reviewCardColorAns, "reviewCardColorAns")
  console.log(reviewCardColor, "reviewCardColor")
  console.log(secondaryColor, "==>secondaryColor")
  console.log(primaryColor, "==>primaryColor")
  console.log(brandSettingsData, "brandbrmadnmasnd")
  return (
    <>
      <div
        className="pdp-single-question"
        style={{
          "--primary-color": primaryColor,
          // "--questionitem-border": reviewCardBorderColor,

        }}
        key={key}
      >
        <p
          className="question"
          style={{
            fontWeight: 600,
            fontSize: "16px",
            color: primaryColor,
          }}
        >
          {question}
        </p>
        <div
          className="question-info"
          style={{
            color: primaryColor,
          }}
        >
          <span>Ask by: {getFirstNameAndLastInitial(askBy)}</span>
          {verified && (
            <span className="verified">
              <VerifiedIcon bgColor="#979797" />
              verified customer
            </span>
          )}
          <span className="time-now" style={{ color: secondaryColor }}>
            - {getTimeFromNow(questionTiming)}
          </span>
        </div>
        {answers?.map((answer, index) => (
          <div
            className="answer-wrapper"
            key={index}
            style={{
              padding: "0px",
              color: primaryColor
            }}
          >
            <div
              className="answer-content d-flex flex-column gap-3"
              style={{
                borderLeft: "none",
                backgroundColor: reviewCardColor,
                padding: "20px",
              }}
            >
              <p className="answer">{answer.value}</p>
              <div className="answer-info d-flex- gap-1">
                {!qaSetting?.hideStoreName && (
                  <span className="store">
                    {/* <StoreIcon /> */}
                    {answer.userName}
                  </span>
                )}
                <span className="time-now" style={{ color: secondaryColor, paddingLeft: "2px" }}>
                  - {getTimeFromNow(answer?.createdAt)}
                </span>
              </div>
            </div>
          </div>
        ))}

        {!qaSetting?.hideHelpful && (
          <div className="helpful-wrapper" style={{
            color: primaryColor
          }}>
            {/* <span>Helpful?</span> */}
            <span className="like-dislike">
              <div
                onClick={() => {
                  if (questionCookie !== id) {
                    document.cookie = "questionId=" + id
                    likeUnlikeQa(id, "like")
                  }
                }}
              >
                <Like1 color={primaryColor} />
              </div>
              ({likes ?? 0})
            </span>
            <span className="like-dislike">
              <div
                onClick={() => {
                  if (questionCookie !== id) {
                    likeUnlikeQa(id, "unlike")
                  }
                }}
              >
                <Dislike1 color={primaryColor} />
              </div>
              ({dislikes ?? 0})
            </span>
          </div>
        )}
      </div >
      <hr style={{ margin: "0px" }} />
    </>
  )
}

export default SingleQuestion
