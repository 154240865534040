import "./SingleImageSlider.scss"
import "swiper/css"
import "swiper/css/navigation"

// import required modules
import { Navigation } from "swiper"
import { SwiperSlide, Swiper } from "swiper/react"
import imgOne from "../../../../asst/slider-img-7.png"
import { getThumbnailImageUrl } from "../../../../helper"

const SingleImageSlider = ({ images, onSelect, _id }) => {
  return (
    <div className="single-image-slider-wrapper">
      <Swiper
        navigation={true}
        // slidesPerView={1}
        spaceBetween={42}
        modules={[Navigation]}
        className="slider_1"
        breakpoints={{
          300: {
            slidesPerView: 1.3,
            spaceBetween: 24,
            centeredSlides: false,
          },
          375: {
            slidesPerView: 2,
            spaceBetween: 24,
            centeredSlides: false,
          },
          400: {
            slidesPerView: 3,
            spaceBetween: 24,
            centeredSlides: false,
          },
          500: {
            slidesPerView: 4,
            spaceBetween: 24,
            centeredSlides: false,
          },
          600: {
            slidesPerView: 5,
            spaceBetween: 24,
            centeredSlides: false,
          },

          768: {
            slidesPerView: 1,
            spaceBetween: 24,
            centeredSlides: false,
          },
        }}
      >
        {images?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">
              {item?.mediaType === 1 ? (
                <div onClick={() => onSelect(item?.mediaUrl)}>
                  <video
                    src={item?.mediaUrl}
                    onClick={() => {
                      setTimeout(
                        () => window.parent.postMessage({ reviewId: _id }, "*"),
                        200
                      )
                    }}
                  />
                </div>
              ) : (
                <img
                  src={getThumbnailImageUrl(item?.mediaUrl)}
                  alt="slide-review"
                  onClick={() => {
                    setTimeout(
                      () => window.parent.postMessage({ reviewId: _id }, "*"),
                      200
                    )
                  }}
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SingleImageSlider
