import "./sliders.scss"
import React, { useEffect, useState } from "react"
import "swiper/css"
import "swiper/css/navigation"

// import required modules
import { Navigation, Autoplay } from "swiper"
import { SwiperSlide, Swiper } from "swiper/react"
import { useSearchParams } from "react-router-dom"
import ApiManager from "../../classes/ApiManager"
import { getImageUrl, getThumbnailImageUrl } from "../../helper"

let apiManager = ApiManager.getInstance()

const Ugc = () => {
  const [searchParams] = useSearchParams()

  const [displayReviewData, setDisplayReviewData] = useState({})
  const [reviewStats, setReviewStats] = useState()
  const [reviews, setReviews] = useState({ docs: [] })
  const [totalDocs, setTotalDocs] = useState(0)
  let xToken = searchParams.get("store")
  let productId = searchParams.get("productId")
  // let carouselSelected = searchParams.get("carouselSelected")
  let carouselTitle = searchParams.get("carouselTitle")
  apiManager?._setXToken(xToken)

  const [brandSettingsData, setBrandSettingsData] = useState({})

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsPDPWidget", response.result)
        setBrandSettingsData(response.result)
        console.log(brandSettingsData, "")
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
  }, [])

  let totalreviews = searchParams.get("total-reviews")
  let nooflines = parseInt(searchParams.get("no-of-lines") ?? 50)
  let auto_scroll = searchParams.get("auto_scroll") == "true"
  let auto_scrol_speed = parseInt(searchParams.get("auto_scrol_speed")) * 1000
  let slider_font = searchParams.get("slider_font")
  let videophotoonly = searchParams.get("video_photo_only")
  let onlyVideo = searchParams.get("only_video")
  // let starcolor = "#" + searchParams.get("star_color")
  let read_more_color = "#" + searchParams.get("read_more_color")
  // console.log("BackgroundColor=====>", backgroundcolor)
  // let card_border_color = "#" + searchParams.get("card_border_color")

  let backgroundcolor = "#" + searchParams.get("background_color")
  let card_shadow_color = "#" + searchParams.get("card_shadow_color")
  let card_hover_color = "#" + searchParams.get("card_hover_color")
  let card_border_hover_color =
    "#" + searchParams.get("card_border_hover_color")
  let card_shadow_hover_color =
    "#" + searchParams.get("card_shadow_hover_color")
  let chevron_icon_color = "#" + searchParams.get("chevron_icon_color")

  let cardbordercolor = brandSettingsData?.generalAppearance?.cardBorderColor
  // let starcolor = brandSettingsData?.generalAppearance?.starColor

  let card_overlay_color = "#" + searchParams.get("card_overlay_color")
  let slider_icon_color = "#" + searchParams.get("slider_icon_color")
  let slider_overlay_color = "#" + searchParams.get("slider_overlay_color")
  let textcolor = "#" + searchParams.get("text_color")
  let verified_badge_color = "#" + searchParams.get("verified_badge_color")
  apiManager._setXToken(xToken)

  useEffect(() => {
    getDisplayReviewData()
    getReviews()
  }, [])

  const getReviews = () => {
    let body = {
      contentType: 1,
      merchantId: xToken,
      reviewStatus: 2,
    }
    if (productId) {
      body.productId = productId
    }

    console.log("videophotoonly--->", videophotoonly)
    if (videophotoonly == "photo") {
      body.onlyImage = 1
    }
    console.log("onlyVideo", onlyVideo)
    if (videophotoonly == "video") {
      body.onlyVideo = 1
    }
    if (videophotoonly == "all") {
      body.onlyImage = 1
      body.onlyVideo = 1
      // body.productId = productId
    }
    console.log("totalreviews====>", totalreviews)
    if (totalreviews && totalreviews !== "all") {
      body.limit = parseInt(totalreviews)
    }
    apiManager.get("getReviews", body).then((res) => {
      console.log("res===>", res.result)
      setTotalDocs(res.result?.totalDocs ?? 0)
      setReviews(res.result)
    })
    apiManager.get("getReviewStats", body).then((res) => {
      console.log("getReviewStats===>", res.result)
      setReviewStats(res.result)
    })
  }

  const sendMaxHeightToParent = () => {
    const maxHeight = document.body.scrollHeight
    console.log("carouselHeight", maxHeight)
    window.parent.postMessage(
      { carouselHeight: maxHeight },
      "https://adziv-review-app-store.myshopify.com/"
    )
  }

  useEffect(() => {
    window.addEventListener("load", sendMaxHeightToParent)

    return () => {
      window.removeEventListener("load", sendMaxHeightToParent)
    }
  }, [])

  const getDisplayReviewData = async () => {
    apiManager
      .get("getDisplayReview", null)
      .then((response) => {
        console.log("===>display", response.result?.widgets)
        if (response.result) {
          setDisplayReviewData(response.result?.widgets)
        }
      })
      .catch((error) => {
        console.error("Error:In display", error)
      })
  }
  let ugcDisplay = displayReviewData?.ugc ?? true

  // auto_scroll = JSON.parse(auto_scroll)

  console.log(card_hover_color, "card_hover_colorcard_hover_color")
  console.log(
    card_border_hover_color,
    "card_border_hover_colorcard_border_hover_color"
  )
  console.log(
    card_shadow_hover_color,
    "card_shadow_hover_colorcard_shadow_hover_color"
  )
  console.log(chevron_icon_color, "chevron_icon_colorchevron_icon_color")
  return (
    <div
      className="sliders"
      style={{
        "--card_hover_color": card_hover_color,
        "--card_border_hover_color": card_border_hover_color,
        "--card_shadow_hover_color": card_shadow_hover_color,
        "--chevron_icon_color": chevron_icon_color,
      }}
      onClick={() => window.parent.postMessage({ QuoliWidgetTrack: "ugc-clicked" }, "*")}
    >
      {ugcDisplay && (
        <div className="slider-wrapper-5">
          <h2 className="carouselTitle">{carouselTitle}</h2>

          <>
            <Swiper
              navigation={true}
              slidesPerView={5}
              spaceBetween={42}
              autoplay={
                auto_scroll
                  ? {
                    delay: auto_scrol_speed ?? 5000,
                  }
                  : false
              }
              // initialSlide={"3"}
              loop={true}
              // centeredSlides={true}
              modules={[Navigation, Autoplay]}
              breakpoints={{
                300: {
                  slidesPerView: 1.3,
                  spaceBetween: 24,
                  centeredSlides: false,
                  initialSlide: "3",
                },
                375: {
                  slidesPerView: 4,
                  spaceBetween: 6,
                  centeredSlides: false,
                  initialSlide: "3",
                },

                550: {
                  slidesPerView: 2.6,
                  spaceBetween: 24,
                  centeredSlides: false,
                  initialSlide: "3",
                },

                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 24,
                  centeredSlides: false,
                },
                1000: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                  centeredSlides: false,
                },

                1200: {
                  slidesPerView: 4.8,
                  spaceBetween: 10,
                },
                1440: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
                1920: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
              }}
              className="slider_5"
            >
              {reviews?.docs?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    class="slide-content c-pointer"
                    style={{
                      // background: backgroundcolor ??"auto",
                      // borderColor: card_border_color,
                      boxShadow: card_shadow_color,
                      border: `1px solid ${cardbordercolor}`,
                    }}
                    onClick={() => {
                      setTimeout(
                        () =>
                          window.parent.postMessage(
                            { reviewId: item._id },
                            "*"
                          ),
                        200
                      )
                    }}
                  >
                    {item?.images && item?.images[0]?.mediaType === 2 ? (
                      <img
                        src={getThumbnailImageUrl(item?.images[0]?.mediaUrl)}
                        class="happy-customer-img"
                        alt="product"
                      />
                    ) : (
                      <video
                        src={item?.images ? getImageUrl(item?.images[0]?.mediaUrl) + '#t=0.5' : ""}
                        class="happy-customer-img"
                        alt="product"
                      />
                    )}
                    <div
                      class="bottom-section"
                      style={{
                        backgroundColor: backgroundcolor ?? "#000",
                        border: `1px solid ${cardbordercolor}`,
                        borderTop: "0px",
                        // borderColor: card_border_color,
                        borderTopColor: "transparent",
                        borderRadius: "10px",
                      }}
                    >
                      <div class="happy-customer-stars">
                        {Array(item?.reviewRating)
                          .fill()
                          .map((_, i) => (
                            <div class="star" key={i}>
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.51141 0.635986L10.5567 6.10575L16.3908 6.36068L11.8207 9.9961L13.3811 15.6234L8.51141 12.4005L3.6417 15.6234L5.20208 9.9961L0.632052 6.36068L6.46613 6.10575L8.51141 0.635986Z"
                                  fill={"#ffff"}
                                />
                              </svg>
                            </div>
                          ))}
                      </div>

                      <p class="description pb-2" style={{ color: "#ffff" }}>
                        {item?.reviewDescription?.substring(0, nooflines) +
                          "..."}
                      </p>
                      <a
                        href="#"
                        class="redirect-link"
                        style={{ color: "#ffff" }}
                      >
                        Read more
                      </a>
                      {/* {item.isAuthorized && (
                        <div className="verified-customer pt-4">
                          <svg
                            width="12"
                            height="14"
                            viewBox="0 0 12 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                              fill={verified_badge_color}
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                              fill="white"
                            />
                          </svg>
                          <span className="text" style={{ color: textcolor }}>
                            verified customer
                          </span>
                        </div>
                      )} */}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
      )}
    </div>
  )
}

export default Ugc
