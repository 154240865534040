import RatingStars from "../../ChildComponents/RatingStars/RatingStars"
import {
  Dislike,
  HeartIcon,
  Like,
  VerifiedIcon,
  VideoBtn,
} from "../../PDPConstants/PDPContants"
import "./SingleReview.scss"
import SingleImageSlider from "../../ChildComponents/SingleImageSlider/SingleImageSlider"
import {
  RATING_BOXES,
  WRAPPER_STYLE,
  formatDate,
  getCookie,
  getFirstNameAndLastInitial,
  getTimeFromNow,
  AttributesComponent,
  getImageUrl,
  getThumbnailImageUrl,
} from "../../../../helper"
import ImgOne from "../../../../asst/DSC093296 1.png"
import RatingBarBoxes from "../../../RatingBarBoxes"
import Slider from "rc-slider"
import { useSearchParams } from "react-router-dom"
import { isMobile } from "react-device-detect"

const SingleReview = ({
  review,
  index,
  likeUnlikeReview,
  onSelect,
  brandSettingsData,
  xToken
}) => {
  let {
    reviewerName,
    isAuthorized,
    reviewDetails,
    reviewerLocation,
    reviewDescription,
    images,
    reviewRating,
    createdAt,
    attributes,
    replies,
    helpUpCount,
    helpDownCount,
    isReplyPrivate,
    updatedAt,
    _id,
  } = review
  console.log("helpUpCount", helpUpCount)
  console.log("helpUpCount", helpDownCount)
  console.log("===>Pribvate", isReplyPrivate)
  console.log("===>reviewerName", reviewerName)
  console.log("===>replies", replies)
  // console.log(likeUnlikeReview(),"===>likereview")
  const [searchParams] = useSearchParams()
  // let reviewCardColor = "#" + searchParams.get("reviewCardColor")
  // let reviewCardBorderColor = "#" + searchParams.get("reviewCardBorderColor")
  // let badgeColor = "#" + searchParams.get("badgeColor")
  // let starColor = "#" + searchParams.get("starColor")
  // let primaryColor = "#" + searchParams.get("primaryColor")
  // let secondaryColor = "#" + searchParams.get("secondaryColor")
  let showHelpfulButton = searchParams.get("showHelpfulButton")
  let showProductLink = searchParams.get("showProductLink")
  let store = searchParams.get("storeName")
  let attribute = searchParams.get("attributes")
  let primaryColor = brandSettingsData?.generalAppearance?.brandColor
  let secondaryColor = `${brandSettingsData?.generalAppearance?.brandColor}80`
  let secondaryColorBackGround = `${brandSettingsData?.generalAppearance?.reviewColor}15`
  let badgeColor = brandSettingsData?.generalAppearance?.verifiedColor
  let starColor = brandSettingsData?.generalAppearance?.starColor
  let reviewCardColor = "transparent"
  let reviewCardBorderColor =
    brandSettingsData?.generalAppearance?.cardBorderColor
  let reviewCookie = getCookie("reviewId")
  let helpful = getCookie("helpful")
  let nhelpful = getCookie("nhelpful")
  let reportCookie = getCookie("report")

  let primaryColorBar = `${brandSettingsData?.generalAppearance?.reviewColor}30`

  console.log(brandSettingsData, "brandcolorrrr")
  console.log(primaryColor, "primarycolorrrr")

  console.log(reviewCookie, "reviewCookiereviewCookie")
  console.log(review, "reviewreview")
  console.log(store, "===>store")

  const date = new Date(parseInt(replies[0]?.createdAt))

  // Array to hold the names of months
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  // Format the date as desired ("Month Day, Year")
  const formattedDate = `${months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`

  // const storeName = store.split(".")[0] ?? store

  return (
    <>
      <div
        className="review-content"
        key={index}
        style={{
          background: "transparent",
          borderColor: reviewCardBorderColor,
          "--primary-color-bar": primaryColorBar,
          "--primary-color": primaryColor,
        }}
      // onClick={() => {
      //   setTimeout(() => window.parent.postMessage({ reviewId: _id }, "*"), 200)
      // }}
      >
        <div className="review-info">
          <div className="verified-section">
            <h4
              style={{ color: primaryColor }}
              className="d-flex gap-1 align-items-center"
            >
              {getFirstNameAndLastInitial(reviewerName)}
              <div>{isAuthorized && <VerifiedIcon bgColor={badgeColor} />}</div>
            </h4>

            {/* <span style={{ color: primaryColor }}>Reviewed from Dellas</span> */}

            {/* {reviewerLocation?.location && (
            <div className="reviewer-location">
              {reviewerLocation?.location}
            </div>
          )} */}
          </div>

          {/* {attributes?.map((attribute) => {
          if (
            attribute?.widgetType === 6 ||
            attribute?.widgetType === 7 ||
            attribute?.widgetType === 8
          ) {
            return (
              <div className="information-section">
                <span style={{ color: secondaryColor }}>
                  <strong style={{ color: primaryColor }}>
                    {attribute?.question}:{" "}
                  </strong>{" "}
                  {attribute?.selectedAnswer.value}
                </span>
              </div>
            )
          }
        })} */}
          <div className="information-section">
            <AttributesComponent
              attributes={attributes}
              secondaryColor={secondaryColor}
              primaryColor={primaryColor}
            />
          </div>
          {attributes?.map((attribute) => {
            if (
              attribute?.widgetType === 2 &&
              attribute?.selectedAnswer?.value === "Yes"
            ) {
              return (
                <div
                  className="recommended"
                  style={{
                    backgroundColor: secondaryColorBackGround,
                    color: primaryColor,
                  }}
                >
                  <HeartIcon color={primaryColor} />
                  Recommended
                </div>
              )
            }
          })}
          {/* <div className="helpful-people" style={{ color: primaryColor }}>
          {helpUpCount} people find this helpful
        </div> */}
        </div>
        <div className="review-detail-wrapper">
          <div className="review-detail gap-3">
            <div className="review-detail-top d-flex flex-column gap-3">
              <div
                className="rating-section c-pointer"
                style={{ color: primaryColor }}
                onClick={() => {
                  setTimeout(
                    () => window.parent.postMessage({ reviewId: _id }, "*"),
                    200
                  )
                }}
              >
                <RatingStars rating={reviewRating} color={starColor} />
                <span className="time-now" style={{ color: secondaryColor }}>
                  {getTimeFromNow(createdAt)}
                </span>
              </div>
              {reviewDetails &&
                <p className="review-title" style={{ color: primaryColor }}>
                  {reviewDetails}
                </p>}
              <p className="review-desc" style={{ color: primaryColor }}>
                {reviewDescription}
              </p>
            </div>
            {!isMobile && (
              <div className="extra-details d-flex justify-content-between">
                {attributes?.map((attribute) => {
                  if (attribute?.widgetType === 3) {
                    return (
                      <div className="effectiveness-helpful">
                        <div className="effectiveness">
                          <span
                            className="effectiveness-text"
                            style={{ color: primaryColor, fontWeight: "500" }}
                          >
                            {attribute?.question}
                          </span>
                          <div className="effectiveness-no">
                            {RATING_BOXES?.map((item) => (
                              <span
                                className={`effectiveness-item ${attribute?.selectedAnswer?.value === item.value
                                  ? "active"
                                  : ""
                                  }`}
                                style={{
                                  backgroundColor:
                                    attribute?.selectedAnswer?.value ===
                                      item.value
                                      ? primaryColor
                                      : "",
                                  border:
                                    attribute?.selectedAnswer?.value ===
                                      item.value
                                      ? primaryColor
                                      : "",
                                  color:
                                    attribute?.selectedAnswer?.value ===
                                      item.value
                                      ? brandSettingsData?.generalAppearance
                                        ?.ctaTextColor
                                      : "",
                                }}
                                key={item.value}
                              >
                                {item.label}
                              </span>
                            ))}
                          </div>
                          <div className="worked" style={{ color: primaryColor }}>
                            <span style={{ color: primaryColor }}>
                              {attribute?.answers?.[0]?.label}
                            </span>
                            <span style={{ color: primaryColor }}>
                              {attribute?.answers?.[1]?.label}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  } else if (attribute?.widgetType === 4) {
                    return (
                      <div className="attribute-four">
                        <div style={WRAPPER_STYLE}>
                          <p
                            className="description"
                            style={{ color: primaryColor }}
                          >
                            {attribute?.question}
                          </p>
                          <Slider
                            min={0}
                            defaultValue={50}
                            marks={{
                              0: attribute?.answers?.[0]?.label,
                              50: attribute?.answers?.[1]?.label,
                              100: attribute?.answers?.[2]?.label,
                            }}
                            step={50}
                            included={false}
                            disabled
                          />
                        </div>
                      </div>
                    )
                  } else if (attribute?.widgetType === 5) {
                    return (
                      <div className="attribute-five">
                        <div style={WRAPPER_STYLE}>
                          <p
                            className="description my-description"
                            style={{ color: primaryColor }}
                          >
                            {attribute?.question}
                          </p>
                          <Slider
                            min={0}
                            defaultValue={50}
                            marks={{
                              0: attribute?.answers?.[0]?.label,
                              50: attribute?.answers?.[1]?.label,
                              100: attribute?.answers?.[2]?.label,
                            }}
                            step={50}
                            included={false}
                            disabled
                          />
                        </div>
                      </div>
                    )
                  }
                })}
                <div >
                </div>
                {xToken !== "conatural.myshopify.com" &&
                  <div className="effectiveness-helpful">
                    <div
                      className="helpful c-pointer"
                      style={{ color: primaryColor, textDecoration: "none" }}
                    >
                      {/* //ReviewLikeUnlike state here */}
                      <span
                        onClick={() => {
                          if (reviewCookie !== review?.id) {
                            likeUnlikeReview(review?.id, "like")
                            document.cookie = "reviewId=" + review?.id
                            document.cookie = "helpful=" + review?.id
                          }
                        }}
                        style={{
                          color: primaryColor,
                          fontWeight: helpful === review?.id ? "normal" : "normal",
                        }}
                        className="d-flex align-items-center gap-1 "
                      >
                        <Like color={primaryColor} />
                        <span
                          style={{
                            color: primaryColor,
                            textDecoration: "none !important",
                          }}
                        >
                          {`(${helpUpCount ?? 0})`}
                        </span>
                      </span>
                      <span
                        onClick={() => {
                          if (reviewCookie !== review?.id) {
                            likeUnlikeReview(review?.id, "unlike")
                            document.cookie = "nhelpful=" + review?.id
                          }
                        }}
                        style={{
                          color: primaryColor,
                          fontWeight: nhelpful === review?.id ? "normal" : "normal",
                        }}
                        className="d-flex align-items-center gap-1 "
                      >
                        <Dislike color={primaryColor} />
                        <span
                          style={{ color: primaryColor }}
                        >{`(${helpDownCount ?? 0})`}</span>
                      </span>
                      {/* <div className="report">
                  <span onClick={() => {
                    if (reportCookie !== review?.id) {
                      document.cookie = "report=" + review?.id
                      likeUnlikeReview(review?.id, "unlike")
                    }
                  }}>
                    {reportCookie !== review?.id ? " Report" : "Reported"}
                  </span>
                </div> */}
                    </div>
                  </div>}
              </div>
            )}
          </div>
          {images?.length > 0 && (
            <div className="reviewer-img c-pointer">
              {images?.length > 0 ? (
                images?.length > 1 ? (
                  <SingleImageSlider
                    images={images}
                    onSelect={onSelect}
                    _id={_id}
                  />
                ) : images?.[0]?.mediaType === 1 ? (
                  <div
                    className="video-img c-pointer"
                    onClick={() => {
                      setTimeout(
                        () => window.parent.postMessage({ reviewId: _id }, "*"),
                        200
                      )
                      onSelect(images?.[0]?.mediaUrl)
                    }}
                  >
                    <video src={getImageUrl(images?.[0]?.mediaUrl) + '#t=0.5'} />
                    <button className="video-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                      >
                        <path
                          d="M34.2351 25.1063L23.6038 17.3782C23.2136 17.0954 22.6957 17.0528 22.268 17.2726C21.837 17.4906 21.5678 17.9336 21.5678 18.4124V33.8635C21.5678 34.3474 21.837 34.7887 22.268 35.0067C22.4503 35.0987 22.6497 35.1447 22.8507 35.1447C23.1131 35.1447 23.3789 35.0612 23.6038 34.896L34.2351 27.1747C34.5707 26.9276 34.7666 26.546 34.7666 26.1405C34.7683 25.7282 34.5673 25.3483 34.2351 25.1063Z"
                          fill="white"
                        />
                        <path
                          d="M25.7896 0.511719C11.6486 0.511719 0.18927 11.971 0.18927 26.112C0.18927 40.2479 11.6486 51.7038 25.7896 51.7038C39.9272 51.7038 51.3882 40.2462 51.3882 26.112C51.3899 11.971 39.9272 0.511719 25.7896 0.511719ZM25.7896 47.4326C14.0134 47.4326 4.46564 37.8899 4.46564 26.112C4.46564 14.3392 14.0134 4.78468 25.7896 4.78468C37.5641 4.78468 47.1101 14.3375 47.1101 26.112C47.1118 37.8899 37.5641 47.4326 25.7896 47.4326Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <img
                    src={getThumbnailImageUrl(images?.[0]?.mediaUrl)}
                    alt="slide-review c-pointer"
                    onClick={() => {
                      setTimeout(
                        () => window.parent.postMessage({ reviewId: _id }, "*"),
                        200
                      )
                    }}
                  />
                )
              ) : (
                <div></div>
              )}
              {/* Video Image */}
              {/* <div className="video-img">
            <img src={ImgOne} />
            <button className="video-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
              >
                <path
                  d="M34.2351 25.1063L23.6038 17.3782C23.2136 17.0954 22.6957 17.0528 22.268 17.2726C21.837 17.4906 21.5678 17.9336 21.5678 18.4124V33.8635C21.5678 34.3474 21.837 34.7887 22.268 35.0067C22.4503 35.0987 22.6497 35.1447 22.8507 35.1447C23.1131 35.1447 23.3789 35.0612 23.6038 34.896L34.2351 27.1747C34.5707 26.9276 34.7666 26.546 34.7666 26.1405C34.7683 25.7282 34.5673 25.3483 34.2351 25.1063Z"
                  fill="white"
                />
                <path
                  d="M25.7896 0.511719C11.6486 0.511719 0.18927 11.971 0.18927 26.112C0.18927 40.2479 11.6486 51.7038 25.7896 51.7038C39.9272 51.7038 51.3882 40.2462 51.3882 26.112C51.3899 11.971 39.9272 0.511719 25.7896 0.511719ZM25.7896 47.4326C14.0134 47.4326 4.46564 37.8899 4.46564 26.112C4.46564 14.3392 14.0134 4.78468 25.7896 4.78468C37.5641 4.78468 47.1101 14.3375 47.1101 26.112C47.1118 37.8899 37.5641 47.4326 25.7896 47.4326Z"
                  fill="white"
                />
              </svg>
            </button>
          </div> */}
            </div>
          )}
          {isMobile && (
            <div className="extra-details">
              {attributes?.map((attribute) => {
                if (attribute?.widgetType === 3) {
                  return (
                    <div className="effectiveness-helpful">
                      <div className="effectiveness">
                        <span
                          className="effectiveness-text"
                          style={{ color: primaryColor, fontWeight: "500" }}
                        >
                          {attribute?.question}
                        </span>
                        <div className="effectiveness-no">
                          {RATING_BOXES?.map((item) => (
                            <span
                              className={`effectiveness-item ${attribute?.selectedAnswer?.value === item.value
                                ? "active"
                                : ""
                                }`}
                              style={{
                                backgroundColor:
                                  attribute?.selectedAnswer?.value ===
                                    item.value
                                    ? primaryColor
                                    : "",
                                border:
                                  attribute?.selectedAnswer?.value ===
                                    item.value
                                    ? primaryColor
                                    : "",
                                color:
                                  attribute?.selectedAnswer?.value ===
                                    item.value
                                    ? brandSettingsData?.generalAppearance
                                      ?.ctaTextColor
                                    : "",
                              }}
                              key={item.value}
                            >
                              {item.label}
                            </span>
                          ))}
                        </div>
                        <div className="worked" style={{ color: primaryColor }}>
                          <span style={{ color: primaryColor }}>
                            {attribute?.answers?.[0]?.label}
                          </span>
                          <span style={{ color: primaryColor }}>
                            {attribute?.answers?.[1]?.label}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                } else if (attribute?.widgetType === 4) {
                  return (
                    <div className="attribute-four">
                      <div style={WRAPPER_STYLE}>
                        <p
                          className="description"
                          style={{ color: primaryColor }}
                        >
                          {attribute?.question}
                        </p>
                        <Slider
                          min={0}
                          defaultValue={50}
                          marks={{
                            0: attribute?.answers?.[0]?.label,
                            50: attribute?.answers?.[1]?.label,
                            100: attribute?.answers?.[2]?.label,
                          }}
                          step={50}
                          included={false}
                        />
                      </div>
                    </div>
                  )
                } else if (attribute?.widgetType === 5) {
                  return (
                    <div className="attribute-five">
                      <div style={WRAPPER_STYLE}>
                        <p
                          className="description my-description"
                          style={{ color: primaryColor }}
                        >
                          {attribute?.question}
                        </p>
                        <Slider
                          min={0}
                          defaultValue={50}
                          marks={{
                            0: attribute?.answers?.[0]?.label,
                            50: attribute?.answers?.[1]?.label,
                            100: attribute?.answers?.[2]?.label,
                          }}
                          step={50}
                          included={false}
                        />
                      </div>
                    </div>
                  )
                }
              })}
              {xToken !== "conatural.myshopify.com" &&
                <div className="effectiveness-helpful">
                  <div
                    className="helpful c-pointer"
                    style={{ color: primaryColor, textDecoration: "none" }}
                  >
                    {/* //ReviewLikeUnlike state here */}
                    <span
                      onClick={() => {
                        if (reviewCookie !== review?.id) {
                          likeUnlikeReview(review?.id, "like")
                          document.cookie = "reviewId=" + review?.id
                          document.cookie = "helpful=" + review?.id
                        }
                      }}
                      style={{
                        color: primaryColor,
                        fontWeight: helpful === review?.id ? "normal" : "normal",
                      }}
                      className="d-flex align-items-center gap-1 "
                    >
                      <Like color={primaryColor} />
                      <span
                        style={{
                          color: primaryColor,
                          textDecoration: "none !important",
                        }}
                      >
                        {`(${helpUpCount ?? 0})`}
                      </span>
                    </span>
                    <span
                      onClick={() => {
                        if (reviewCookie !== review?.id) {
                          likeUnlikeReview(review?.id, "unlike")
                          document.cookie = "nhelpful=" + review?.id
                        }
                      }}
                      style={{
                        color: primaryColor,
                        fontWeight: nhelpful === review?.id ? "normal" : "normal",
                      }}
                      className="d-flex align-items-center gap-1 "
                    >
                      <Dislike color={primaryColor} />
                      <span
                        style={{ color: primaryColor }}
                      >{`(${helpDownCount ?? 0})`}</span>
                    </span>
                    {/* <div className="report">
                  <span onClick={() => {
                    if (reportCookie !== review?.id) {
                      document.cookie = "report=" + review?.id
                      likeUnlikeReview(review?.id, "unlike")
                    }
                  }}>
                    {reportCookie !== review?.id ? " Report" : "Reported"}
                  </span>
                </div> */}
                  </div>
                </div>}
            </div>
          )}
        </div>
      </div>
      {isReplyPrivate
        ? ""
        : replies[0]?.comment && (
          <div className="priavte-rply-main-div">
            <div className="d-flex gap-3 align-items-center">
              <div className="Reply-customer-div d-flex gap-1">
                <span>Reply from</span>
                {/* <span>{reviewerName}</span> */}
                <span>@{store}</span>
              </div>
              <span className="reply-date-customer">{formattedDate}</span>
            </div>
            <div className="reply-des-customer">{replies[0]?.comment}</div>
          </div>
        )}
    </>
  )
}

export default SingleReview
