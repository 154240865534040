import React, { useEffect, useState } from "react"
import "../../../styles/tellus-starter.scss"
import "../../../styles/tellus-starter.css"
import Slider from "rc-slider"
import { Input } from "reactstrap"
import { RATING_BOXES, checkAccess } from "../../../helper"

export default function NewTellUsStarter(props) {
  let {
    settings,
    attributeValue,
    storeName,
    requiredAttributes,
    setRequiredAttributes,
    errorAttribute,
    setErrorAttribute,
    setAttribute,
    attributes,
    onTitleChange,
    setisTyped,
    onDescriptionChange,
    data,
    onAttributeSelect,
    setReviewCheck,
    starReview,
    setAttributeAnswerValue,
    setAttributeAnswerMultipleValue,
  } = props
  // const [attributeValue, setAttribute] = useState([])
  const [answer, setAnswer] = useState(3)
  const [answerss, setAnswerss] = useState([])
  const [activeValue, setActiveValues] = useState(null)
  const [activeSingleValue, setActiveSingleValues] = useState(null)

  const setAttributes = (attribute, answer, isMultiple = false) => {
    console.log("attributeValue", attribute)
    console.log(data, "thisissetting")
    console.log(data.attributes, "thisissettingattributes")
    console.log(attributes, "this Is attributes side")
    console.log(attributeValue, "this Is attributeValue side")
    console.log(onDescriptionChange, "onDescriptionChangeonDescriptionChange")

    if (isMultiple) {
      if (
        attributeValue.findIndex(
          (item) => item.selectedAnswer.value === answer.value
        ) !== -1
      ) {
        attributeValue.splice(
          attributeValue.findIndex(
            (item) => item.selectedAnswer.value === answer.value
          ),
          1
        )
      } else {
        attributeValue.push({ ...attribute, selectedAnswer: answer })
      }
    } else {
      if (
        attributeValue.findIndex((item) => item._id === attribute._id) !== -1
      ) {
        attributeValue.splice(
          attributeValue.findIndex((item) => item._id === attribute._id),
          1
        )
      }
      attributeValue.push({ ...attribute, selectedAnswer: answer })
      if (attributeValue) {
        console.log(attributeValue, "selectedAnswerAnswer")
      }
      if (attributeValue.selectedAnswer && attributeValue.widgetType === 6) {
        setAttributeAnswerMultipleValue(false)
      }
    }
    setAttribute(attributeValue)
    setRequiredAttributes(false)
    onAttributeSelect(attributeValue)
    console.log("attributeValue--->", attributeValue)
  }

  console.log(settings, "settings?.newReviewForm?.reviewPageTitle?.text")
  console.log(
    data?.description?.length,
    "settings?.description?.textsettings?.description?.text"
  )

  return (
    <>
      <div>
        <input
          className="input-field-rcf-review"
          name="title"
          type="text"
          style={{ color: settings?.fontColor ?? "#000" }}
          placeholder={`${settings?.reviewPageTitle?.text ?? "Review title"}`}
          onChange={(e) => {
            setisTyped(true)
            console.log("e.target.value====here", e.target.value == "")
            // if (e.target.value == "") setErrorAttribute(true)
            // else {
            //   setErrorAttribute(false)
            onTitleChange(e)
            // }
          }}
        ></input>
      </div>

      {/* <p>{starReview && "Please enter title"}</p> */}
      <div>
        <textarea
          name="tellus"
          id="tellus-starter"
          cols="30"
          rows="10"
          className={`input-field-rcf-description ${errorAttribute ? "error" : ""
            }`}
          style={{ color: settings?.fontColor ?? "#000" }}
          placeholder={`Write Your review About ${storeName ?? "{Store_name}"}`}
          // placeholder={
          //   storeName
          //     ? storeName.replace("{product_name}", "") +
          //       `${
          //         storeName.includes("{product_name}") &&
          //         data &&
          //         data?.item?.title
          //           ? data?.item?.title
          //           : ``
          //       }`
          //     : `write Your review About ${
          //       storeName?.includes("{product_name}") &&
          //         data
          //           ? data?.item?.title
          //           : ""
          //       }`
          // }
          onChange={(e) => {
            setisTyped(true)
            const inputValue = e.target.value
            if (inputValue.length > 500) {
              // Truncate the input if it exceeds 500 characters
              e.target.value = inputValue.slice(0, 500)
            } else {
              if (inputValue.length > 0) {
                setReviewCheck(false)
              } else if (inputValue === "") {
                setReviewCheck(true)
              }
              onDescriptionChange(e)
              setErrorAttribute(false)
            }
          }}
        ></textarea>
        <div style={{ paddingTop: "5px" }}>
          <p className="text-field-count">
            {data?.description?.length ?? 0}
            /500
          </p>
        </div>
        {/* <p className="error-description">
          {starReview && "Please enter your description"}
        </p> */}
      </div>
    </>
  )
}
