import Reviews from "./pages/reviews"
import OnSiteReferral from "./pages/OnSiteReferral"
import GetReward from "./pages/GetReward"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import NotFound from "./pages/notFound"
import "bootstrap/dist/css/bootstrap.min.css"
import PostPurchaseReferral from "./pages/PostPurchaseReferral"
import Sliders from "./components/Sliders/Sliders"
import Popup from "./components/Popup/Popup"
import PDPWidget from "./components/PDPWidget/PDPWidget"
import Rcf from "./pages/Rcf"
import NuggetsSlider from "./components/NuggetsSlider/NuggetsSlider"
import Attributes from "./components/Attributes/Attributes"
import PopupCard from "./components/PopupCard/PopupCard"
import SidebarWidget from "./components/PDPWidget/SiderbarWidget"
import Ugc from "./components/Sliders/Ugc"
import HappyCustomers from "./components/PDPWidget/HappyCustomers"
import NewRcf from "./components/v2/rcf/NewRcf"
import ImportPopUp from "./components/v2/aliExpress/ImportPopUp"
import StoreReview from "./components/v2/storeReview/StoreReview"
import "./asst/fonts/visby-cf/style.css"



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/review" element={<Reviews />} />
        <Route path="/onsite-referral" element={<OnSiteReferral />} />
        <Route path="/attributes" element={<Attributes />} />
        <Route
          path="/post-checkout-referral"
          element={<PostPurchaseReferral />}
        />
        <Route path="/d/:id" element={<GetReward />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/sliders" element={<Sliders />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/popup-card" element={<PopupCard />} />
        <Route path="/pdp-widget" element={<PDPWidget />} />
        <Route path="/old-rcf" element={<Rcf />} />
        <Route path="/nuggets-slider" element={<NuggetsSlider />} />
        <Route path="/sidebar" element={<SidebarWidget />} />
        <Route path="/ugc" element={<Ugc />} />
        <Route path="/rcf" element={<NewRcf />} />
        <Route path="/store-review" element={<StoreReview />} />
        <Route path="/import-popup" element={<ImportPopUp />} />
        {/* <Route path="/happycustomer" element={<HappyCustomers />} />      */}
      </Routes>
    </BrowserRouter>
  )
}

export default App
