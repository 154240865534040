import React from "react";
import { useState } from "react";
import ThankYou from "../components/ThankYou";

import "../styles/reviews.css";
import { useSearchParams } from "react-router-dom";
import { decryptData, encryptData, uploadToS3 } from "../helper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import RateItemPopup from "../components/RatePopup";
import ShareMedia from "../components/ShareMedia";
import TellUsStarter from "../components/TellUsStarter";
import ThatsAll from "../components/ThatsAll";
import ReviewSubSimple from "../components/ReviewSubSimple";
import ApiManager from "../classes/ApiManager";
import { link } from "../helper";

function Reviews() {
  const apiManager = ApiManager.getInstance();
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [error, setError] = useState(true);
  const [data, setData] = useState({});
  const [settings, setSettings] = useState({});
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const [files, setFiles] = useState([]);
  const [codeData, setCodeData] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [collectSettings, setCollectSettings] = useState({});

  const handleContinueClick = () => {
    setCurrentComponentIndex(currentComponentIndex + 1);
    window.scrollTo({ top: 0 });
  };
  const handleBack = () => {
    setCurrentComponentIndex(currentComponentIndex - 1);
    window.scrollTo({ top: 0 });
  };

  const submit = async () => {
    console.log("submit");
    let body = {
      reviewRating: data?.review,
      reviewTitle: data?.title,
      reviewDescription: data?.description,
      productID: data?.item?.id,
      orderNo: data?.orderNo,
      title: data?.item?.title,
      mediaType: 3,
      contentType: 2,
      merchantId: data?.merchantId,
      reviewerName: data?.reviewerName,
      reviewerEmail: data?.reviewerEmail,
      attributes: data?.attributes,
      images: [],
    };
    console.log("submit", body);
    if (files?.length > 0) {
      for await (const file of files) {
        console.log(file);
        let image = await uploadToS3(file);
        console.log("image", image);
        body.images.push({
          mediaUrl: image?.location,
          mediaType: file?.type?.startsWith("video/") ? 1 : 2,
          contentType: 1,
        });
      }
      body.contentType = 1;
    }
    if (!body.mediaUrl) {
    }
    console.log("body", body);
    console.log("data", { data: encryptData(body) });

    try {
      fetch(link + "addVReview", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": searchParams.get("id"),
        },
        body: JSON.stringify({ data: encryptData(body) }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("submit", res);
          setCodeData(res?.review);
          if (res?.message === 2003) handleContinueClick();
          else alert("already added");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:2", error);
    }
  };
  console.log("data==>", data);

  React.useEffect(() => {
    try {
      let data = JSON.parse(decryptData(searchParams.get("id")));
      console.log("body==>", data);
      setData({ ...data, attributes: [] });
      let body = {
        orderNo: data?.orderNo,
        productID: data?.item?.id,
        merchantId: data?.merchantId,
      };
      console.log("bodycheckStatus", body);
      console.log("body", encryptData(body));
      fetch(link + "getCollectReviews?merchantId=" + body?.merchantId)
        .then((response) => response.json())
        .then((res) => {
          console.log("getCollectReviews", res?.result);
          setSettings(res?.result?.reviewForm);
          setCollectSettings(res?.result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      fetch(link + "checkStatus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": searchParams.get("id"),
        },
        body: JSON.stringify({ data: encryptData(body) }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("checkStatus", res);
          if (res?.message !== 2004) {
            fetch(link + "getCollectReviews?merchantId=" + data?.merchantId)
              .then((response) => response.json())
              .then((res) => {
                console.log("res", res?.result);
                setSettings(res?.result?.reviewForm);
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });
            fetch(link + "getAttributes?merchantId=" + data?.merchantId)
              .then((response) => response.json())
              .then((res) => {
                console.log("setAttributes", res?.result);
                setAttributes(res?.result);
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });
          } else {
            setAlreadyAdded(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      setError(false);
    } catch (error) {
      setError(true);
    }
  }, []);
  let videoReviews = collectSettings?.videoReviews?.show ?? true;
  let photoReviews = collectSettings?.photoReviews?.show ?? true;

  return (
    // alreadyAdded ? (
    //   <div className="container2">
    //     <main className={`content error}`}>
    //       <p>{"Review already added"}</p>
    //     </main>
    //   </div>
    // ) : error ? (
    //   <div className="container2">
    //     <main className={`content error}`}>
    //       <p>{"You cannot place this review"}</p>
    //     </main>
    //   </div>
    // ) : (
    <div>
      <Swiper className="mySwiper" allowTouchMove={false}>
        <SwiperSlide>
          <RateItemPopup
            onContinueClick={handleContinueClick}
            handleBack={handleBack}
            onTitleChange={(e) => {
              setData({ ...data, title: e.target.value });
            }}
            onReviewSelect={(rating) => {
              setData({ ...data, review: rating });
            }}
            data={data}
            settings={settings}
          />
        </SwiperSlide>
        {(videoReviews || photoReviews) && (
          <SwiperSlide>
            <ShareMedia
              onContinueClick={submit}
              handleBack={handleBack}
              settings={settings}
              collectSettings={collectSettings}
              videoReviews={videoReviews}
              photoReviews={photoReviews}
              setFiles={setFiles}
              files={files}
            />
          </SwiperSlide>
        )}
        <SwiperSlide>
          <TellUsStarter
            onContinueClick={submit}
            handleBack={handleBack}
            settings={settings}
            setFile={setFiles}
            attributes={attributes}
            onDescriptionChange={(e) => {
              setData({ ...data, description: e.target.value });
            }}
            onAttributeSelect={(attribute) => {
              let dataToSend = [];
              if (
                data?.attributes?.some(
                  (prevFile) => prevFile._id === attribute._id
                )
              ) {
                dataToSend = data.attributes?.filter((prevFile) => {
                  return prevFile._id !== attribute._id;
                });
              } else dataToSend = [...data.attributes, attribute];

              setData({ ...data, attributes: dataToSend });
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ThatsAll
            onContinueClick={submit}
            handleBack={handleBack}
            settings={settings}
            setFile={setFiles}
            onNameChange={(e) => {
              setData({ ...data, reviewerName: e });
            }}
            onEmailChange={(e) => {
              setData({ ...data, reviewerEmail: e });
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ReviewSubSimple
            onContinueClick={submit}
            handleBack={handleBack}
            settings={settings}
            setFile={setFiles}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ThankYou settings={settings} merchantId={data?.merchantId} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Reviews;
