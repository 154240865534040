import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ApiManager from "../classes/ApiManager";
const baseUrl = "https://d1zdq1lsqiesh.cloudfront.net";

export default function PostPurchaseReferral() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [getDisc, setGetDisc] = useState(0);
  const [settings, setSettings] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const apiManager = ApiManager.getInstance();
  apiManager._setXToken(searchParams.get("id"));

  useEffect(() => {
    apiManager
      .get("getRefferels", { merchantId: searchParams.get("id") ?? "" })
      .then((res) => {
        console.log("res===>", res.result);
        setSettings(res.result);
      })
      .catch((err) => {
        console.log("err===>", err);
      });
  }, []);
  let firstDisc =
    settings?.setupReferelOffer?.typeOfDiscount === "percentage"
      ? settings?.setupReferelOffer?.percentageValue + "%"
      : settings?.setupReferelOffer?.percentageValue;
  let secondDisc = "$" + settings?.setupReferelOffer?.advocateReward;
  let activeWidget = settings?.postPurchaseWidget?.activeWidget;

  const getDiscount = () => {
    apiManager
      .post("addPostDiscounts", {
        merchantId: searchParams.get("id") ?? "",
        orderNo: searchParams.get("orderNo") ?? "",
      })
      .then((res) => {
        console.log("res===>", res.result);
        setId(res.result._id);
        setGetDisc(1);
      });
  };

  return activeWidget ? (
    <div class="popup-one-container">
      <div class="popup-one">
        <div
          class="popup-one-coupon"
          style={{
            backgroundColor: settings?.postPurchaseWidget?.backgroundColor,
          }}
        >
          <div class="coupon-top">
            <p class="give-get">
              {settings?.postPurchaseWidget?.friendDiscountTitle} {firstDisc},
              {settings?.postPurchaseWidget?.advocateRewardTitle} {secondDisc}
            </p>
            <p class="give-gift">
              {" "}
              {settings?.postPurchaseWidget?.subTitle ??
                "Give your friends a gift!"}
            </p>
            <p class="use-discount">
              {settings?.postPurchaseWidget?.content ??
                `For every friend that uses their 10% discount, you'll get a $5
                discount as well!`}
            </p>
          </div>
          <div class="coupon-border">
            <div class="eclipse-border-left"></div>
            <div class="eclipse-border-right"></div>
          </div>
          <div class="coupon-bottom">
            {id ? (
              ""
            ) : (
              <button onClick={getDiscount}>
                {settings?.postPurchaseWidget?.buttonCaption ??
                  "Share Discount"}
              </button>
            )}
          </div>
          {id && (
            <div className="copy-code">
              <p className="code">
                {" "}
                {baseUrl}/d/{id}
              </p>
              <button
                className="copy"
                onClick={() =>
                  navigator.clipboard.writeText(`${baseUrl}/d/${id}`)
                }
              >
                Copy
              </button>
            </div>
          )}
          <div class="eclipse-bottom">
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
            <div class="eclipse"></div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
