import { Modal } from "react-bootstrap"
import "./PopupCard.scss"
import { useEffect, useState } from "react"
import ImgOne from "../../asst/DSC093296 1.png"
import { EmptyStar, SimpleStar } from "../PDPWidget/PDPConstants/PDPContants"
import ApiManager from "../../classes/ApiManager"
import {
  formatDate,
  getFirstNameAndLastInitial,
  getImageUrl,
  getInitals,
  getThumbnailImageUrl,
} from "../../helper"
import { useSearchParams } from "react-router-dom"

const PopupCard = () => {
  const [searchParams] = useSearchParams()
  const apiManager = ApiManager.getInstance()
  let xToken = searchParams.get("store")

  apiManager._setXToken(xToken)

  const [brandSettingsData, setBrandSettingsData] = useState({})

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsPDPWidget", response.result)
        setBrandSettingsData(response.result)
        console.log(brandSettingsData, "")
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
  }, [])
  const [show, setShow] = useState(false)
  const [displayReviewData, setDisplayReviewData] = useState({})
  const [review, setReview] = useState({})

  let displayRatings = searchParams.get("displayRatings")
  let popupWidgetPosition = searchParams.get("popupWidgetPosition")
  let mobileEnable = searchParams.get("mobileEnable")
  let autoTimer = parseInt(searchParams.get("autoTimer"))
  let homepageCheckbox = searchParams.get("homepageCheckbox")
  let productPage = searchParams.get("productPage")
  let cartPage = searchParams.get("cartPage")
  let otherPages = searchParams.get("otherPages")

  let starcolor = brandSettingsData?.generalAppearance?.starColor ?? "#ffc803"
  let backgroundcolor = "white"
  let cardbordercolor =
    brandSettingsData?.generalAppearance?.cardBorderColor ?? "#e7e7e7"
  let badgecolor =
    brandSettingsData?.generalAppearance?.verifiedColor ?? "#e3e3e3"
  let textcolor = brandSettingsData?.generalAppearance?.reviewColor ?? "#000000"

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    apiManager
      .get("getDisplayReview", null)
      .then((response) => {
        console.log("===>display", response)
        if (response.result) {
          setDisplayReviewData(response.result)
          getRandomReview(response.result?.popup)
        }
      })
      .catch((error) => {
        console.error("Error:In display", error)
      })
  }, [])

  const getRandomReview = (data, show = true) => {
    apiManager
      .get("getRandomReview", {
        displayRatings: displayRatings,
        mobileEnable: mobileEnable,
      })
      .then((res) => {
        console.log("getRandomReview", res)
        setReview(res?.result)
        console.log("review===>", res?.result)
        handleShow()
        if (show)
          showPopup(
            data?.initialDelay ?? 5,
            data?.delayBetweenPopups ?? 5,

            data?.maximumPerPage ?? 5
          )
      })
      .catch((error) => {
        console.error("Error:In getRandomReview", error)
      })
  }

  function showPopup(initialDelay = 5, repeatDelay = 5, maxCount = 5) {
    console.log("showPopup", initialDelay, repeatDelay, maxCount)
    initialDelay = initialDelay * 1000
    repeatDelay = repeatDelay * 1000
    console.log("showPopup", initialDelay, repeatDelay, maxCount)

    let count = 0
    let timerId = setInterval(function () {
      if (count >= maxCount) {
        clearInterval(timerId)
        return
      }
      if (count > 0) {
        // Wait for repeat delay before showing popup again
        setTimeout(show, repeatDelay)
      } else {
        // Show popup after initial delay
        setTimeout(show, initialDelay)
      }
      count++
    }, repeatDelay + initialDelay)

    function show() {
      let popup = document.getElementById("mypopup")
      if (popup) {
        // Display popup if it exists

        getRandomReview(displayReviewData?.popup, false)
        // Display popup if it exists
        popup.style.opacity = 1
        popup.style.display = "block"
        popup.style.transition = "opacity 1s ease-in-out"
        // Hide popup after 2 seconds
        setTimeout(function () {
          popup.style.opacity = 0
          setTimeout(function () {
            popup.style.display = "none"
          }, 1000)
        }, `${autoTimer}000`)
      }
    }
  }

  // console.log(popupWidgetPosition, "popupWidgetPositionpopupWidgetPosition")
  return (
    <>
      {mobileEnable === "true" && (
        <div onClick={() => window.parent.postMessage({ QuoliWidgetTrack: "popupcard-clicked" }, "*")}>
          {show ? (
            <div className="popup-card-wrapper" id="mypopup">
              <div
                className="popup-card-content"
                style={{
                  background: backgroundcolor,
                  // borderColor: cardbordercolor,
                }}
              >
                <img
                  onClick={() => {
                    setTimeout(
                      () =>
                        window.parent.postMessage(
                          {
                            reviewId: review?._id,
                          },
                          "*"
                        ),
                      200
                    )
                  }}
                  src={
                    (review?.images &&
                      getImageUrl(review?.images[0]?.mediaUrl)) ??
                    ImgOne
                  }
                  className="popup-img"
                  alt="popup-img"
                />
                <button className="close-btn" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                  >
                    <line
                      x1="1.11157"
                      y1="1.12915"
                      x2="5.48048"
                      y2="5.49806"
                      stroke="black"
                      stroke-width="0.37047"
                      stroke-linecap="round"
                    />
                    <line
                      x1="0.980469"
                      y1="5.45117"
                      x2="5.34938"
                      y2="1.08226"
                      stroke="black"
                      stroke-width="0.37047"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
                <div className="rating-wrapper">
                  <div className="rating">
                    {Array(review?.reviewRating)
                      .fill()
                      .map((item) => (
                        <SimpleStar color={starcolor} />
                      ))}
                    {Array(5 - review?.reviewRating)
                      .fill()
                      .map((item) => (
                        <EmptyStar color={starcolor} />
                      ))}
                  </div>

                  <span className="day-ago">
                    {formatDate(review?.createdAt)}
                  </span>
                </div>
                <p style={{ color: textcolor }}>
                  {review?.reviewDescription?.length > 40
                    ? `${review?.reviewDescription?.substring(0, 40)}...`
                    : review?.reviewDescription}
                </p>
                <div className="verified">
                  <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.14117 13.228C9.34569 13.228 11.9435 10.4045 11.9435 6.92161C11.9435 3.4387 9.34569 0.615234 6.14117 0.615234C2.93665 0.615234 0.338867 3.4387 0.338867 6.92161C0.338867 10.4045 2.93665 13.228 6.14117 13.228Z"
                      fill={badgecolor}
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.21016 9.4257L3.31761 7.38106C3.26473 7.32358 3.23828 7.24967 3.23828 7.15935C3.23828 7.06902 3.26472 6.99512 3.31761 6.93765L3.73692 6.49423C3.7898 6.42854 3.85592 6.3957 3.93524 6.3957C4.01457 6.3957 4.08445 6.42854 4.14489 6.49423L5.41414 7.87375L8.13397 4.91763C8.19441 4.85195 8.2643 4.81909 8.34362 4.81909C8.42296 4.81909 8.48906 4.85194 8.54194 4.91763L8.96125 5.36105C9.01413 5.41853 9.04058 5.49244 9.04058 5.58276C9.04058 5.67309 9.01414 5.74698 8.96125 5.80446L5.61812 9.42571C5.56524 9.4914 5.49724 9.52425 5.41413 9.52425C5.33103 9.52425 5.26303 9.49141 5.21015 9.42571L5.21016 9.4257Z"
                      fill="white"
                    />
                  </svg>
                  <span className="verified-name" style={{ color: textcolor }}>
                    {" "}
                    {getFirstNameAndLastInitial(review?.reviewerName)}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  )
}

export default PopupCard
