import React from "react";
import { StarIcon } from "../../PDPConstants/PDPContants";
import "./RatingStars.scss";

const RatingStars = ({ rating, color }) => {
  const wholeStars = Math.floor(rating);
  const decimalPart = rating - wholeStars;
  const fractionPercentage =
    decimalPart === 0 ? 0 : Math.round(decimalPart * 100);

  const stars = [];

  for (let i = 0; i < wholeStars; i++) {
    stars.push(<StarIcon key={i} fillPercentage={100} color={color} />);
  }

  if (fractionPercentage > 0 && wholeStars < 5) {
    stars.push(
      <StarIcon
        key={wholeStars}
        fillPercentage={fractionPercentage}
        color={color}
      />
    );
  }

  for (let i = stars.length; i < 5; i++) {
    stars.push(<StarIcon key={i} fillPercentage={0} color={color} />);
  }

  return (
    <div className="pdp-rating-stars">
      {stars.map((star, index) => (
        <div key={index} className="star">
          {star}
        </div>
      ))}
    </div>
  );
};

export default RatingStars;
