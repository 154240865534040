import React from "react";
import "./PDPProgressBar.scss";

const PDPProgressBar = ({ currentValue, totalValue, color = "#eb2136" }) => {
  const normalizedCurrentValue = Math.min(
    Math.max(currentValue, 0),
    totalValue
  );
  const normalizedTotalValue = Math.max(totalValue, 1);

  const progressPercentage =
    (normalizedCurrentValue / normalizedTotalValue) * 100;

  return (
    <div className="pdp-progress">
      <div
        className="pdp-progress-bar"
        role="progressbar"
        style={{ width: `${progressPercentage}%`, background: color }}
        aria-valuenow={progressPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

export default PDPProgressBar;
