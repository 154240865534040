import React, { useState } from "react"
import "../styles/tellus-starter.css"
import "../styles/tellus-starter.scss"
import { useSwiper } from "swiper/react"
import {
  CircleCheck,
  CircleEmpty,
  CircleFill,
  ProggressLineDark,
  ProggressLineLite,
} from "../Svgs/Svg"
import Slider from "rc-slider"
import { FormGroup, Input, Label } from "reactstrap"
import { RATING_BOXES, WRAPPER_STYLE, checkAccess } from "../helper"

export default function TellUsStarter(props) {
  let { settings, onAttributeSelect, attributes, onDescriptionChange, onTitleChange } = props
  console.log("attributeshere", attributes)
  const swiper = useSwiper()
  const [attributeValue, setAttribute] = useState([])
  const [answer, setAnswer] = useState(3)
  const [answerss, setAnswerss] = useState([])

  const [error, setError] = useState(false)
  const [attError, setAttError] = useState(false)
  const [isTyped, setisTyped] = useState(false)

  const setAttributes = (attribute, answer, isMultiple = false) => {
    console.log("attributeValue", attribute)
    if (isMultiple) {
      if (
        attributeValue.findIndex(
          (item) => item.selectedAnswer.value === answer.value
        ) !== -1
      ) {
        attributeValue.splice(
          attributeValue.findIndex(
            (item) => item.selectedAnswer.value === answer.value
          ),
          1
        )
      } else {
        attributeValue.push({ ...attribute, selectedAnswer: answer })
      }
    } else {
      if (
        attributeValue.findIndex((item) => item._id === attribute._id) !== -1
      ) {
        attributeValue.splice(
          attributeValue.findIndex((item) => item._id === attribute._id),
          1
        )
      }
      attributeValue.push({ ...attribute, selectedAnswer: answer })
    }
    setAttribute(attributeValue)
    console.log("attributeValue--->", attributeValue)
  }

  return (
    <div className="tell-us-container" style={{ color: settings?.fontColor ?? '#000', '--rate-background': settings?.backgroundColor ?? '#fff', }}>
      <div className="tellus-details-popup">
        <div className="tellus-starter-progress-bar">
          <CircleCheck />
          <ProggressLineDark />
          <CircleCheck />
          <ProggressLineLite />
          <CircleFill />
          <ProggressLineLite />
          <CircleEmpty />
        </div>
        <div className="tell-us-popup-content" >
          <h3 className="tellus-starter-highlight">
            {settings?.sharePage?.text ?? "Tell Us Your Experience"}
          </h3>
          <div className="textarea-details" style={{ color: settings?.fontColor ?? '#000', }}>
            <input
              name="title"
              type="text"


              style={{ color: settings?.fontColor ?? '#000', }}
              placeholder="Give your review a title."
              onChange={(e) => {
                // setisTyped(true)
                console.log("e.target.value", e.target.value == "")
                // if (e.target.value == "") setError(true)
                // else {
                //   setError(false)
                onTitleChange(e)
                // }
              }}

            ></input>
            <textarea
              name="tellus"
              id="tellus-starter"
              cols="30"
              rows="10"
              className={`${error ? "error" : ""}`}
              style={{ color: settings?.fontColor ?? '#000', }}
              placeholder={settings?.sharePageToolTip?.text ?? "We'd love to know about results..."}
              onChange={(e) => {
                setisTyped(true)
                console.log("e.target.value", e.target.value == "")
                if (e.target.value == "") setError(true)
                else {
                  setError(false)
                  onDescriptionChange(e)
                }
              }}

            ></textarea>

            <p className="error-description">
              {error && "Please enter review feedback"}
            </p>
            {checkAccess(
              "attributeRatings",
              <div className="extra-details" >
                {attributes?.map((attribute) => {
                  if (attribute?.widgetType === 2) {
                    return (
                      <div className="health-concerns">
                        <p className="description">
                          <span style={{ color: "red" }}>
                            {attribute?.required && "*"}
                          </span>
                          {attribute?.question}
                        </p>
                        <div className="health-buttons" >
                          {[
                            { label: "Yes", value: "Yes" },
                            { label: "No", value: "No" },
                          ]?.map((ans) => (
                            <button
                              className={
                                ans.value === answer[attribute?.widgetType] &&
                                "active"
                              }
                              style={{ color: settings?.fontColor ?? '#000', }}
                              onClick={() => {
                                setAttributes(attribute, ans, false)
                                setAnswer({
                                  ...answer,
                                  [attribute?.widgetType]: ans.value,
                                })
                              }}
                            >
                              {ans.value}
                            </button>
                          ))}
                        </div>
                      </div>
                    )
                  } else if (attribute?.widgetType === 3) {
                    return (
                      <div className="effectiveness-helpful">
                        <div className="effectiveness">
                          <span className="effectiveness-text" style={{ color: settings?.fontColor ?? '#000', }}>
                            <span style={{ color: "red" }}>
                              {attribute?.required && "*"}
                            </span>
                            {attribute?.question}
                          </span>
                          <div className="effectiveness-no">
                            {RATING_BOXES?.map((item) => (
                              <span
                                onClick={() => {
                                  setAttributes(attribute, item, false)
                                  setAnswer({
                                    ...answer,
                                    [attribute?.widgetType]: item.value,
                                  })
                                }}
                                className={`c-pointer effectiveness-item ${answer[attribute?.widgetType] === item.value
                                  ? "active"
                                  : ""
                                  }`}
                                key={item.value}
                              >
                                {item.label}
                              </span>
                            ))}
                          </div>
                          <div className="worked" style={{ color: settings?.fontColor ?? '#000', }}>
                            <span>{attribute?.answers?.[0]?.label}</span>
                            <span>{attribute?.answers?.[1]?.label}</span>
                          </div>
                        </div>
                      </div>
                    )
                  } else if (attribute?.widgetType === 4) {
                    return (
                      <div className="attribute-five" style={{ padding: '0 0 7px 15px' }}>
                        <div style={WRAPPER_STYLE}>
                          <p className="description" style={{ color: settings?.fontColor ?? '#000', }}>
                            <span>
                              {attribute?.required && "*"}
                            </span>
                            {attribute?.question}
                          </p>
                          <Slider
                            min={0}
                            defaultValue={50}
                            marks={{
                              0: attribute?.answers?.[0]?.label,
                              50: attribute?.answers?.[1]?.label,
                              100: attribute?.answers?.[2]?.label,
                            }}
                            step={50}
                            included={false}
                            onChange={(value) => {
                              // if (value === 0) {
                              //   value = attribute?.answers?.[0]?.value
                              // }
                              // if (value === 50) {
                              //   value = attribute?.answers?.[1]?.value
                              // }
                              // if (value === 100) {
                              //   value = attribute?.answers?.[2]?.value
                              // }
                              console.log("attribute---?", value)
                              setAttributes(
                                attribute,
                                {
                                  value: value,
                                  label: "",
                                },
                                false
                              )
                            }}
                          />
                        </div>
                      </div>
                    )
                  } else if (attribute?.widgetType === 5) {
                    return (
                      <div className="attribute-four" style={{ padding: '0 0 7px 15px' }}>
                        <div style={WRAPPER_STYLE}>
                          <p className="description" style={{ color: settings?.fontColor ?? '#000', }}>
                            {" "}
                            <span>
                              {attribute?.required && "*"}
                            </span>
                            {attribute?.question}
                          </p>
                          <Slider
                            min={0}
                            defaultValue={50}
                            marks={{
                              0: attribute?.answers?.[0]?.label,
                              50: attribute?.answers?.[1]?.label,
                              100: attribute?.answers?.[2]?.label,
                            }}
                            step={50}
                            included={false}
                            onChange={(value) => {
                              // if (value === 0) {
                              //   value = attribute?.answers?.[0]?.value
                              // }
                              // if (value === 50) {
                              //   value = attribute?.answers?.[1]?.value
                              // }
                              // if (value === 100) {
                              //   value = attribute?.answers?.[2]?.value
                              // }
                              setAttributes(
                                attribute,
                                {
                                  value: value,
                                  label: attribute?.answers?.[2]?.label,
                                },
                                false
                              )
                            }}
                          />
                        </div>
                      </div>
                    )
                  } else if (attribute?.widgetType === 6) {
                    return (
                      <div className="health-concerns">
                        <p className="description" style={{ color: settings?.fontColor ?? '#000', }}>
                          {" "}
                          <span>
                            {attribute?.required && "*"}
                          </span>
                          {attribute?.question}
                        </p>
                        <div className={`health-buttons`}>
                          {attribute?.answers?.map((answers, index) => (
                            <button
                              key={index}
                              className={
                                answerss?.includes(index) ? "active" : ""
                              }
                              onClick={() => {
                                setAttributes(attribute, answers, true)
                                if (answerss?.includes(index)) {
                                  answerss.splice(answerss.indexOf(index), 1)
                                } else answerss.push(index)
                                setAnswerss(answerss)
                                setAnswer({
                                  ...answer,
                                  [attribute?.widgetType]: answers.value,
                                })
                              }}
                            >
                              {answers.value}
                            </button>
                          ))}
                        </div>
                      </div>
                    )
                  } else if (attribute?.widgetType === 7) {
                    return (
                      <div className="gender" style={{ color: settings?.fontColor ?? '#000', }}>
                        <label className="description" htmlFor="dropdown">
                          <span style={{ color: "red" }}>
                            {attribute?.required && "*"}
                          </span>
                          {attribute?.question}
                        </label>
                        <select
                          name="drop"
                          id="dropdowm"
                          style={{ color: settings?.fontColor ?? '#000', }}
                          onChange={(e) => {
                            setAttributes(
                              attribute,
                              {
                                value: e.target.value,
                                label: e.target.value,
                              },
                              false
                            )
                          }}
                        >
                          <option disabled value="" selected >
                            Select an option
                          </option>
                          {attribute?.answers?.map((answer) => (
                            <option value={answer.value}>{answer.label}</option>
                          ))}
                        </select>
                      </div>
                    )
                  } else if (attribute?.widgetType === 8) {
                    return (
                      <div className="radio-buttons">
                        <FormGroup tag="fieldset" style={{ color: settings?.fontColor ?? '#000', }}>
                          <label className="description">
                            <span >
                              {attribute?.required && "*"}
                            </span>
                            {attribute?.question}
                          </label>
                          {attribute?.answers?.map((answer) => (
                            <FormGroup
                              check
                              onChange={(e) => {
                                setAttributes(
                                  attribute,
                                  {
                                    value: e.target.value,
                                    label: e.target.value,
                                  },
                                  false
                                )
                              }}
                              style={{ color: settings?.fontColor ?? '#000', }}
                            >
                              <Input name="radio1" type="radio" />
                              <Label check>{answer?.value}</Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                      </div>
                    )
                  } else return <div></div>
                })}
              </div>
            )}
          </div>
        </div>
        <div className="popup-footer-btns">
          <button
            className="back-btn"
            onClick={() => {
              swiper.slidePrev()
            }}
          // style={{color: settings?.fontColor??'#000',}}
          >
            {" "}
            &lt; Back{" "}
          </button>
          <button
            className="next-btn"
            onClick={() => {
              if (!error && isTyped) {
                const areAllRequiredOptionsSelected = attributes?.filter((obj) => obj.required)
                  .every((requiredObj) =>
                    attributeValue?.some(
                      (selected) => selected._id === requiredObj._id
                    )
                  )

                if (attributes === undefined) {
                  onAttributeSelect(attributeValue)
                  swiper.slideNext()
                  window.scrollTo({ top: 0 })
                }
                else if (areAllRequiredOptionsSelected) {
                  swiper.slideNext()
                  window.scrollTo({ top: 0 })
                }

                else {
                  setAttError(true)
                }
              } else {
                setError(true)
              }
            }}
          >
            {settings?.continueButtonTitle ?? "Next"}
          </button>
        </div>
      </div>
    </div>
  )
}
