import React from "react"
import { Spinner } from "reactstrap"

export default function SectionLoader() {
  return (
    <div className="quoli-section-loader">
      <Spinner>Loading...</Spinner>
    </div>
  )
}
