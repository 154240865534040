import "./AddBtn.scss"

const AddBtn = ({ btnText, onClick, ctaColor, ctaFontSize, xToken }) => {
  console.log("ctaColor", ctaColor)
  console.log(ctaFontSize, "ajshgdjhsagdds")
  return (
    <button
      className="pdp-add-btn"
      style={{
        fontSize: ctaFontSize,
        fontWeight: xToken === "conatural.myshopify.com" ? "500" : xToken === "insignia-pk.myshopify.com" ? "700" : "",
        background: ctaColor?.ctaFillColor ?? "#fff",
        color: ctaColor?.ctaTextColor ?? "#000",
        // borderColor: ctaColor?.ctaBorderColor ?? "#e7e7e7",
        borderRadius: xToken === "conatural.myshopify.com" ? "45px" : "6px",
        borderColor: xToken === "conatural.myshopify.com" ? ctaColor?.ctaFillColor : "",
        textTransform: xToken === "conatural.myshopify.com" || xToken === "insignia-pk.myshopify.com" ? "uppercase" : "",
        padding: xToken === "conatural.myshopify.com" ? "4px 4px" : "",
        boxShadow: xToken === "insignia-pk.myshopify.com" ? "inset -.0625rem 0 .0625rem #0000001f,inset .0625rem 0 .0625rem #0000001f,inset 0 .125rem .0625rem #0003" : ""


      }}
      onClick={onClick}
    >
      {btnText}
    </button >
  )
}

export default AddBtn
