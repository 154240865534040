import React from "react"
import Slider from "rc-slider"
import "../Attributes/attributes.css"
import { FormGroup, Input, Label } from "reactstrap"

const wrapperStyle = { width: 400, margin: 50 }

export default function Attributes() {
  return (
    <div className="attribtes-main">
      <div className="attribute-four">
        <div style={wrapperStyle}>
          <p>Attribute 4</p>
          <Slider
            min={0}
            defaultValue={50}
            marks={{ 0: "Option 1", 50: "Option 2", 100: "Option 3" }}
            step={50}
            included={false}
          />
        </div>
      </div>
      <div className="attribute-five">
        <div style={wrapperStyle}>
          <p>Attribute 5</p>
          <Slider
            min={0}
            defaultValue={50}
            marks={{ 0: "Option 1", 50: "Option 2", 100: "Option 3" }}
            step={50}
            included={false}
          />
        </div>
      </div>
      <div className="attribute-avg-two">
        <div style={wrapperStyle}>
          <p>Attributes Average Two</p>
          <Slider min={0} max={20} defaultValue={3} />
        </div>
      </div>
      <div className="attribute-avg-three">
        <div style={wrapperStyle}>
          <p>Attributes Average Three</p>
          <Slider min={0} max={20} defaultValue={3} />
        </div>
      </div>
      <div className="attribute-avg-four">
        <div style={wrapperStyle}>
          <p>Attribute Average Four</p>
          <Slider
            min={0}
            defaultValue={50}
            marks={{ 0: "Option 1", 50: "Option 2", 100: "Option 3" }}
            step={50}
            included={false}
          />
        </div>
      </div>
      <div className="attribute-avg-five">
        <div style={wrapperStyle}>
          <p>Attribute Average Five</p>
          <Slider
            min={0}
            defaultValue={50}
            marks={{ 0: "Option 1", 50: "Option 2", 100: "Option 3" }}
            step={50}
            included={false}
          />
        </div>
      </div>
      <div className="radio-buttons">
        <FormGroup tag="fieldset">
          <legend>Attributes 3</legend>
          <FormGroup check>
            <Input name="radio1" type="radio" /> <Label check>1</Label>
          </FormGroup>
          <FormGroup check>
            <Input name="radio1" type="radio" /> <Label check>2</Label>
          </FormGroup>
          <FormGroup check>
            <Input name="radio1" type="radio" /> <Label check>3</Label>
          </FormGroup>
          <FormGroup check>
            <Input name="radio1" type="radio" /> <Label check>4</Label>
          </FormGroup>
        </FormGroup>
      </div>
    </div>
  )
}
