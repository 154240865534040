import React from "react"

const RatingBarBoxes = ({ rating, onClick, primaryColor }) => {
  const wholeBoxes = Math.floor(rating)
  const fractionBox = rating - wholeBoxes

  const boxes = []

  for (let i = 0; i < wholeBoxes; i++) {
    boxes.push(
      <div
        key={i}
        onClick={() => {
          console.log("ahsdkjhalksdjiiii", i)
          onClick && onClick(i)
        }}
        style={{
          width: "47px",
          height: "5.8px",
          backgroundColor: primaryColor,
          marginRight: "3px",
        }}
      />
    )
  }

  if (fractionBox > 0 && wholeBoxes < 5) {
    boxes.push(
      <div
        style={{
          backgroundColor: "#D9D9D9",
          width: "47px",
        }}
      >
        <div
          key={wholeBoxes}
          style={{
            width: `${Math.round(fractionBox * 47)}px`,
            height: "5.8px",
            backgroundColor: primaryColor,
            marginRight: "3px",
          }}
        />
      </div>
    )
  }

  for (let i = boxes.length; i < 5; i++) {
    boxes.push(
      <div
        onClick={() => {
          console.log("ahsdkjhalksdj", i)
          onClick && onClick(i)
        }}
        key={i}
        style={{
          width: "47px",
          height: "5.8px",
          backgroundColor: "#D9D9D9",
          marginLeft: "3px",
        }}
      />
    )
  }

  return <div style={{ display: "flex", alignItems: "center" }}>{boxes}</div>
}

export default RatingBarBoxes
