import { useEffect, useState } from "react"
import AddBtn from "../ChildComponents/AddBtn/AddBtn"
import CustomDropdown from "../ChildComponents/CustomDropdown/CustomDropdown"
import Pagination from "../ChildComponents/Pagination/Pagination"
import SearchInput from "../ChildComponents/SearchInput/SearchInput"
import SingleQuestion from "./SingleQuestion/SingleQuestion"
import "./questions.scss"
import QuestionForm from "./QuestionForm/QuestionForm"
import ApiManager from "../../../classes/ApiManager"
import { useSearchParams } from "react-router-dom"
import ShowMoreBtn from "../ChildComponents/ShowMoreBtn/ShowMoreBtn"
import { Dropdown } from "react-bootstrap"
import { isMobile } from "react-device-detect"

const items = [
  { label: <div>Most Recent</div>, value: "Most Recent" },
  { label: <div>Oldest</div>, value: "Oldest" },
  { label: <div>Most Helpful</div>, value: "Most Helpful" },
]

const Questions = ({
  sendMaxHeightToParent,
  brandSettingsData,
  primaryColor,
  secondaryColor,
}) => {
  const apiManager = new ApiManager()
  const [searchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState(1)
  const [visible, setVisible] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const [questions, setQuestions] = useState({ docs: [] })
  const [searchPhrase, setSearchPhrase] = useState("")
  const [qaSetting, setQaSetting] = useState({})
  const [sort, setSort] = useState("Most Recent")

  let productId = searchParams.get("productId")
  let xToken = searchParams.get("store")
  let productName = searchParams.get("productName")
  let questionCta = searchParams.get("questionCta")
  let showFilter = searchParams.get("showFilter")
  let questionCtaText = "Ask a Question"
  let noQuestionText = "No Questions yet"
  // let noQuestionText = searchParams.get("noQuestionText")

  questionCta = JSON.parse(questionCta)
  showFilter = JSON.parse(showFilter)

  let ctaFillColor =
    brandSettingsData?.generalAppearance?.ctaFillColor ?? "#ffffff"
  //ctaFOntNot Get Doubt their---
  let ctaFontSize = searchParams.get("ctaFontSize") + "px"
  let ctaTextColor =
    brandSettingsData?.generalAppearance?.ctaTextColor ?? "#000000"
  // let ctaBorderColor = brandSettingsData?.generalAppearance?.ctaFillColor
  let ctaBorderColor =
    brandSettingsData?.generalAppearance?.cardBorderColor ?? "#e7e7e7"

  console.log("questionCta", questionCta)
  apiManager._setXToken(xToken)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleSubmit = (data) => {
    console.log(data)
    let body = {
      question: data?.question,
      productId: productId,
      productName: productName,
      userId: xToken,
      userEmail: data?.email,
      userName: data?.firstName,
    }
    apiManager
      .post("addQa", { ...body, limit: 100 })
      .then((response) => {
        console.log(response, "addQA")
        getQas()
        // setVisible(false)
        setIsSubmit(true)
      })
      .catch((error) => {
        console.error("Error:In Collect", error)
      })
  }
  const getQaSettings = () => {
    apiManager
      .get("getQaSetting", {})
      .then((response) => {
        console.log("===>getQaSetting", response.result)
        setQaSetting(response.result)
      })
      .catch((error) => {
        console.error("Error:In Collect", error)
      })
  }

  const getQas = () => {
    let body = {
      merchantId: xToken,
      page: currentPage,
      limit: 5,
      qaStatus: 2,
    }
    if (productId) {
      body.productId = productId
    }
    if (searchPhrase !== "") {
      body.question = searchPhrase
    }
    if (sort === "Most Recent") {
      body.sortValue = "createdAt"
      body.sort = "desc"
    }
    if (sort === "Oldest") {
      body.sortValue = "createdAt"
      body.sort = "asc"
    }
    if (sort === "Most Helpful") {
      body.sortValue = "voteUpCount"
      body.sort = "desc"
    }
    apiManager.get("getQas", body).then((res) => {
      setQuestions(res.result)
      console.log(res.result, "getqasssss")
    })
  }

  useEffect(() => {
    apiManager.get("getQas").then((res) => {
      console.log(res.result, "===>getqasssss")
    })
  }, [])

  const likeUnlikeQa = (id, type) => {
    console.log("id", id, type)
    apiManager
      .post("likeUnlikeQa", {
        id,
        type,
      })
      .then((res) => {
        questions.docs.forEach((review) => {
          if (review.id === id) {
            if (type === "like") {
              review.voteUpCount = review.voteUpCount + 1
            } else {
              review.voteDownCount = review.voteDownCount + 1
            }
          }
        })
        setTimeout(() => {
          setQuestions({ ...questions, docs: [...questions.docs] })
        }, 500)
      })
      .catch((err) => {
        console.log("err===>", err)
      })
  }

  useEffect(() => {
    apiManager.get("getGeneralSettings").then((res) => {
      apiManager._setGeneralSettings(
        res?.result?.reviewersNameFormat?.displayName
      )
    })
    getQaSettings()
    const getData = setTimeout(() => {
      getQas()
    }, 500)
    return () => clearTimeout(getData)
  }, [searchPhrase, currentPage, sort])

  console.log(questions, "lenghtOfQuestions")
  console.log(primaryColor, "akshdkajhcolor")
  console.log(brandSettingsData, "datainQuestion")

  return (
    <div
      className="pdp-questions-wrapper"
      style={{
        "--primary-color": primaryColor,
      }}
    >
      <div className="search-section ">

        <span
          className={`pdp-showing-review ${questions?.docs?.length > 0 && isMobile ? "d-none" : "pe-4"}`}
          style={{
            color: brandSettingsData?.generalAppearance?.reviewColor
          }}
        >{`${questions?.totalDocs ?? "0"} Questions`}</span>


        {/* {questions?.docs?.length > 0 ? (
          <SearchInput
            placeholder="Search Question"
            onChange={(e) => {
              setSearchPhrase(e.target.value)
            }}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        ) : (
          "No questions yet"
        )} */}
        <div className="d-flex gap-3">
          {questionCta && (
            <AddBtn
              ctaColor={{
                ctaFillColor: ctaFillColor,
                ctaTextColor: ctaTextColor,
                ctaBorderColor: ctaBorderColor,
                ctaFontSize: ctaFontSize,
              }}
              ctaFontSize={ctaFontSize}
              btnText={questionCtaText ?? "Ask a Question"}
              onClick={() => {
                setVisible(true)
                setIsSubmit(false)
                setTimeout(sendMaxHeightToParent, 200)
              }}
            />
          )}
          {questions?.docs?.length > 0 && (
            <>

              <div className="pdp-filters-dropdown">
                <Dropdown >
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <path d="M15.0858 6.05444H4.5" stroke="black" stroke-width="1.53246" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M15.0864 10.0242H7.80859" stroke="black" stroke-width="1.53246" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M15.0849 13.9949H11.1152" stroke="black" stroke-width="1.53246" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M21.5001 17.4705L17.9375 21.0331L14.375 17.4705" stroke="black" stroke-width="1.53246" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.9375 5.80444V21.0332" stroke="black" stroke-width="1.38941" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>  Sort
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="dropdown-wrapper">
                      <CustomDropdown
                        items={items}
                        textBefore="Sort By"
                        onSelect={(e) => {
                          setSort(e)
                        }}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </>)}
        </div>
      </div>
      {visible && (
        <QuestionForm ctaFillColor={ctaFillColor} ctaTextColor={ctaTextColor} handleSubmit={handleSubmit} isSubmit={isSubmit} />
      )}
      {questions?.docs?.length > 0 && (
        <>


          <div className="questions-wrapper">
            {questions?.docs?.length > 0 ? (
              questions?.docs?.map((item, index) => (
                <SingleQuestion
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                  key={index}
                  id={item._id}
                  question={item.question}
                  likes={item.voteUpCount}
                  dislikes={item.voteDownCount}
                  answers={item.answers}
                  askBy={item.userName}
                  verified={item.isVerified}
                  questionTiming={item.createdAt}
                  likeUnlikeQa={likeUnlikeQa}
                  qaSetting={qaSetting}
                  brandSettingsData={brandSettingsData}
                />
              ))
            ) : (
              <p>{noQuestionText ?? "No Questions"}</p>
            )}
          </div>
          <Pagination
            totalPages={questions?.totalPages}
            currentPage={questions?.page}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {/* Show More Button */}
      {/* <ShowMoreBtn /> */}
    </div>
  )
}

export default Questions
