import React, { useState, useEffect } from "react";
import GetLink from "../components/GetLink";
import ApiManager from "../classes/ApiManager";
import { useSearchParams } from "react-router-dom";

export default function OnSiteReferral() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [getDisc, setGetDisc] = useState(0);
  const [settings, setSettings] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const apiManager = ApiManager.getInstance();
  apiManager._setXToken(searchParams.get("id"));

  useEffect(() => {
    apiManager
      .get("getRefferels", { merchantId: searchParams.get("id") ?? "" })
      .then((res) => {
        console.log("res===>", res.result);
        setSettings(res.result);
      })
      .catch((err) => {
        console.log("err===>", err);
      });
  }, []);

  const getDiscount = () => {
    if (name == null || name == "" || email == null || email == "") {
      alert("Please enter all fields");
      return;
    }
    apiManager
      .post("addOnSiteDiscounts", {
        merchantId: searchParams.get("id") ?? "",
        name,
        email,
      })
      .then((res) => {
        console.log("res===>", res.result);
        setId(res.result._id);
        setGetDisc(1);
      });
  };
  let activeWidget = settings?.onSiteWidget?.activeWidget;

  return (
    <>
      <div className="container-rate-items body-ref">
        {getDisc == 0 ? (
          <div class="referral-card">
            <div class="ref-header">
              <p class="gift-header">
                {settings?.onSiteWidget?.sharePage?.title ??
                  "Give your friends a gift!"}
              </p>
            </div>
            <p class="ref-offer">
              {settings?.onSiteWidget?.sharePage?.subTitle ??
                `Share your unique purchase link and your friends can claim 10% off
                  their next purchase!`}
            </p>
            <div class="email-input">
              <label for="Email">
                Email <span>*</span>
              </label>
              <input
                type="text"
                name=""
                id=""
                placeholder="Your Email"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div class="name-input">
              <label for="">
                Name <span>*</span>
              </label>
              <input
                type="text"
                name=""
                required
                id=""
                placeholder="Your Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <button
              class="get-disc"
              onClick={() => {
                getDiscount();
              }}
            >
              {settings?.onSiteWidget?.sharePage?.buttonCaption ??
                "Get Discount Link"}
            </button>
            <p class="terms-cond">
              By submitting, I acknowledge the <a href="/">Terms of Service</a>{" "}
              and <a href="/">Privacy Policy.</a>
            </p>
          </div>
        ) : (
          <GetLink settings={settings} id={id} />
        )}
      </div>
    </>
  );
}
