import React, { useState, useEffect } from "react"

import { Button } from "reactstrap"
import QuoliLogo from "../../../asst/quoli-logo.svg"

import { useSwiper } from "swiper/react"
import PoweredByQuoli from "../PoweredByQuoli"

export default function NewThatsAll(props) {
  let {
    settings,
    errorAttribute,
    setFName,
    fName,
    lName,
    setLName,
    showDetail,
    email,
    setEmail,
    setAnswer,
    setRequiredAttributes,
    ordersData,
    orderProductIndex,
    isTyped,
    onContinueClick,
    isDisable,
    starCheck,
    starReview,
    data,
    attributes,
    setOrderProductIndex,
    submitReview,
    setIsTyped1,
    setIsTyped2,
    setIsTyped3,
    isTyped1,
    isTyped2,
    isTyped3,
    orderNo,
  } = props

  // const [fName, setFName] = useState("")

  const [allAttributes, setAllAttributes] = useState(false)
  console.log(allAttributes, "allAttributesallAttributes")

  const [error, setError] = useState({})

  const [isValid, setIsValid] = useState(true)

  const handleInputChange1 = (e) => {
    setIsTyped1(e.target.value !== "")
    setFName(e.target.value)
    setError({
      ...error,
      firstName: null,
    })
  }
  console.log("errorerror", error)
  const handleInputChange2 = (e) => {
    setIsTyped2(e.target.value !== "")
    setLName(e.target.value)
    setError({
      ...error,
      lastName: null,
    })
  }
  useEffect(() => {
    console.log("last name", lName)
  }, [lName])

  const handleInputChange3 = (e) => {
    setIsTyped3(e.target.value != "")
    console.log("emaail", e.target.value, isTyped3)
    const newEmail = e.target.value
    // Regular expression to validate email addresses
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    setIsValid(emailRegex.test(newEmail))
    console.log("submit123123123", newEmail)
    // if (isValid) {
    setEmail(newEmail)
    setError({
      ...error,
      email: null,
    })
    // onEmailChange(newEmail)
    // }
  }
  console.log(error, "errorrrorrorrro")
  console.log("hello", email)

  console.log(showDetail, "showDetailshowDetail")

  // let [submitReview, setSubmitReview] = useState(false)

  console.log("font--->", settings?.fontColor)
  console.log("backgroundColor--->", settings?.backgroundColor)
  console.log(data.attributes, "thisISdara")
  console.log(isDisable, "this is disable btn")
  console.log(error.firstName, "1982hdqjsdg")
  console.log(settings, "thisIsattributeSetting", ordersData)
  return (
    <div className="thats-all-container new-thats-all">
      <div
        className="thats-all-popup"
        style={{
          color: settings?.fontColor ?? "#000",
          "--rate-background": settings?.backgroundColor ?? "#fff",
        }}
      >
        <div className="popup-content new-popup-rcf">
          {showDetail ? (
            <p className="error-description" style={{ color: "red" }}>
              {allAttributes && "Please fill all required fields {*}"}
            </p>
          ) : (
            ""
          )}
          {showDetail ? (
            ""
          ) : (
            <div>
              <hr style={{ marginBottom: "35px" }} />
              <div className="thats-all-inputs new-thats-all-rcf">
                <div className="input-feild-rcf-main-div">
                  <div className="input-field-main-div-rcf">
                    {/* <label
                htmlFor="first-name"
                className={isTyped1 ? "label-active" : "label"}
              >
                First Name
              </label> */}
                    <input
                      type="text"
                      id="first-name"
                      autoComplete={"off"}
                      className={`input-field-rcf-review ${
                        error.firstName ? "error" : ""
                      }`}
                      placeholder="First Name*"
                      value={
                        orderNo
                          ? ordersData?.[0]?.customerName?.split(" ")?.[0]
                          : fName
                      }
                      onChange={handleInputChange1}
                      style={{ color: settings?.fontColor ?? "#000" }}
                      disabled={orderNo}
                    />
                    <p className="error-description" style={{ color: "red" }}>
                      {error.firstName && "Please enter first name"}
                    </p>
                  </div>

                  <div className="input-field-main-div-rcf">
                    <input
                      type="text"
                      id="last-name"
                      autoComplete={"off"}
                      className={`input-field-rcf-review ${
                        error.lastName ? "error" : ""
                      }`}
                      placeholder="Last Name*"
                      value={
                        orderNo
                          ? ordersData?.[0]?.customerName?.split(" ")?.[1]
                          : lName
                      }
                      onChange={handleInputChange2}
                      style={{ color: settings?.fontColor ?? "#000" }}
                      disabled={orderNo}
                    />
                    <p className="error-description" style={{ color: "red" }}>
                      {error.lastName && "Please enter last name"}
                    </p>
                  </div>
                </div>
                <div className="email-input">
                  {/* <label
              htmlFor="email-input"
              className={isTyped3 ? "label-active" : "label"}
              >
              Email
              </label> */}
                  <input
                    type="email"
                    name=""
                    id="email"
                    autoComplete={"off"}
                    className={`input-field-rcf-review ${
                      !isValid ? "error" : ""
                    }`}
                    placeholder="Email*"
                    value={orderNo ? ordersData?.[0]?.customerEmail : email}
                    onChange={handleInputChange3}
                    style={{ color: settings?.fontColor ?? "#000" }}
                    disabled={orderNo}
                  />
                  <p className="error-description" style={{ color: "red" }}>
                    {!isValid ? "Please enter valid email" : ""}
                  </p>
                </div>
              </div>
              <p className="error-description" style={{ color: "red" }}>
                {allAttributes && "Please fill all required fields {*}"}
              </p>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "0px" }}
              >
                <div>
                  <p
                    className="rcf-policy-term-tag"
                    style={{
                      color: settings?.toolTipColor ?? "grey",
                    }}
                  >
                    By continuing you agree to Quoli’s{" "}
                    <a
                      className="rcf-policy-term-tag"
                      href="https://quoli.io/terms-of-service/"
                      target="_blank"
                      style={{
                        color: settings?.toolTipColor ?? "grey ",
                      }}
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      className="rcf-policy-term-tag"
                      href="https://quoli.io/privacy-policy/"
                      target="_blank"
                      style={{
                        color: settings?.toolTipColor ?? "grey ",
                      }}
                    >
                      Privacy Policy.
                    </a>{" "}
                  </p>
                  <p
                    className="rcf-policy-term-tag"
                    style={{
                      color: settings?.toolTipColor ?? "grey",
                    }}
                  >
                    Your email won’t be shown publicly and we never share emails
                    with others.
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className=" d-flex gap-2">
            {orderProductIndex !== ordersData[0]?.products.length ? (
              <div style={{ width: "100%" }} className="skip-next">
                <Button
                  className="rcf-skip-btn"
                  onClick={() => {
                    setOrderProductIndex((prevIndex) => prevIndex + 1)
                    window.scrollTo({ top: 0 })
                  }}
                >
                  Skip
                </Button>
              </div>
            ) : (
              ""
            )}

            <div
              style={{ width: "100%" }}
              className={`${true ? "skip-next" : ""}`}
            >
              <Button
                // disabled={isDisable}
                className={`rcf-submit-btn ${
                  isTyped1 && isTyped2 && isTyped3 && isValid
                    ? "next-btn"
                    : "next-btn-dis"
                } `}
                onClick={() => {
                  console.log(Boolean(isTyped1), "this is error data")
                  console.log(isTyped, "hithereStarCheck")
                  console.log(errorAttribute, "hithereReviewcheck")
                  console.log(orderNo, "orderNo")
                  console.log(
                    ordersData[0]?.products.length,
                    "jhdkjashdkajdhkds"
                  )
                  console.log(orderProductIndex, "jhdkjashdkajdhkds")

                  setError({
                    ...error,
                    firstName: !Boolean(isTyped1) && !orderNo,
                    lastName: !Boolean(isTyped2) && !orderNo,
                    email: !Boolean(isTyped3) && !orderNo,
                  })
                  console.log(data.attributes, "asjdasdasss")
                  if (
                    data?.attributes === undefined ||
                    data.attributes.length === 0
                  ) {
                    console.log(data?.attributes, "akjshdakjsdhkajs")
                    setRequiredAttributes(true)
                  }
                  var areAllRequiredOptionsSelected = attributes
                    ?.filter((obj) => obj.required)
                    .every((requiredObj) => {
                  
                      return data.attributes?.some(
                        (selected) => selected._id === requiredObj._id
                      )
                    })
                  // submitReview(true)
                  console.log(
                    areAllRequiredOptionsSelected,
                    "areAllRequiredOptionsSelected"
                  )
                  setAllAttributes(!areAllRequiredOptionsSelected)

                  // else if (areAllRequiredOptionsSelected) {
                  //   swiper.slideNext()
                  //   window.scrollTo({ top: 0 })
                  // }

                  // console.log("this is SetIsdisbale site", se)
                  console.log("Boolean(isTyped1)", Boolean(isTyped1))
                  console.log("Boolean(isTyped1)", Boolean(isTyped2))
                  console.log("Boolean(isTyped1)", Boolean(isTyped3))
                  console.log("Boolean(isTyped1)", isValid)
                  console.log("Boolean(isTyped1)", starCheck)
                  console.log("Boolean(isTyped1)", starReview)
                  console.log(
                    "Boolean(isTyped1)",
                    areAllRequiredOptionsSelected
                  )

                  console.log("hello=========>", email, fName, lName)
                  if (
                    Boolean(isTyped1) &&
                    Boolean(isTyped2) &&
                    Boolean(isTyped3) &&
                    isValid &&
                    starCheck &&
                    starReview &&
                    areAllRequiredOptionsSelected
                  ) {
                    console.log(starCheck, starReview, "starcech")

                    onContinueClick({
                      reviewerName: fName + " " + lName,
                      reviewerEmail: email,
                    })
                    setAnswer(3)
                    console.log(showDetail, "===>showdetail")
                    setOrderProductIndex((prevIndex) => prevIndex + 1)
                    // setShowLoaderSub(false)
                  } else {
                    setAllAttributes(true)
                  }
                }}
              >
                {orderProductIndex !== ordersData[0]?.products?.length
                  ? "Submit & Next"
                  : "Submit Review"}
              </Button>
            </div>
          </div>
          <PoweredByQuoli />
          {/* <div className="last-submit-heading-main-container">
            <span className="rcf-company-heading-powered">Powered by</span>
            <div className="quoli-logo-main-div">
              <img src={QuoliLogo} alt="QuoliLogo" />
              <span className="quoli-title-text-tag">Quoli</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
