import React from "react"
import { useState } from "react"
import ThankYou from "../components/ThankYou"

import "../styles/reviews.css"
import { useSearchParams } from "react-router-dom"
import { decryptData, encryptData, uploadToS3 } from "../helper"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import RateItemPopup from "../components/RatePopup"
import ShareMedia from "../components/ShareMedia"
import AddMore from "../components/AddMore"
import TellUsStarter from "../components/TellUsStarter"
import ThatsAll from "../components/ThatsAll"
import ReviewSubSimple from "../components/ReviewSubSimple"
import ApiManager from "../classes/ApiManager"
import { link } from "../helper"

function Rcf() {
  const apiManager = ApiManager.getInstance()

  const [searchParams, setSearchParams] = useSearchParams()
  apiManager._setXToken(searchParams.get("merchantId"))

  const [currentComponentIndex, setCurrentComponentIndex] = useState(0)
  const [error, setError] = useState(true)
  const [data, setData] = useState({})
  const [settings, setSettings] = useState({})
  const [collectSettings, setCollectSettings] = useState({})

  const [alreadyAdded, setAlreadyAdded] = useState(false)
  const [files, setFiles] = useState([])
  const [codeData, setCodeData] = useState(null)
  const [attributes, setAttributes] = useState([])
  const [displayReviewData, setDisplayReviewData] = useState([])

  const handleContinueClick = () => {
    setCurrentComponentIndex(currentComponentIndex + 1)
    window.scrollTo({ top: 0 })
  }
  const handleBack = () => {
    setCurrentComponentIndex(currentComponentIndex - 1)
    window.scrollTo({ top: 0 })
  }
  // function getQueryString() {
  //   var queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');
  //   var qsJsonObject = {};
  //   if (queryStringKeyValue != '') {
  //     for (let i = 0; i < queryStringKeyValue.length; i++) {
  //       qsJsonObject[queryStringKeyValue[i].split('=')[0]] = queryStringKeyValue[i].split('=')[1];
  //     }
  //   }
  //   return qsJsonObject;
  // }
  let rcfvalue

  React.useEffect(() => {
    const receiveMessage = (event) => {
      rcfvalue = event.data // Access the rcf value sent from parent
      // Use the received value here
      console.log("Received rcf value:", rcfvalue)
    }

    window.addEventListener("message", receiveMessage)

    return () => {
      // Clean up event listener when the component unmounts
      window.removeEventListener("message", receiveMessage)
    }
  }, [])
  const submit = async (userData) => {
    console.log("submit")
    let body = {
      productID: searchParams.get("productId"),
      merchantId: searchParams.get("merchantId"),
      productImage: searchParams.get("productImage"),
      reviewRating: data?.review,
      reviewTitle: data?.title ?? "",
      reviewDescription: data?.description,
      productName: searchParams.get("productName"),
      title: searchParams.get("productName"),
      mediaType: 3,
      contentType: 2,
      reviewerName: userData?.reviewerName ?? data?.reviewerName,
      reviewerEmail: userData?.reviewerEmail ?? data?.reviewerEmail,
      attributes: data?.attributes,
      images: [],
      isAuthorized: rcfvalue ? rcfvalue : false,
    }

    console.log("submit", body)
    if (files?.length > 0) {
      for await (const file of files) {
        console.log(file)
        let image = await uploadToS3(file)
        console.log("image", image)
        body.images.push({
          mediaUrl: image?.location,
          mediaType: file?.type?.startsWith("video/") ? 1 : 2,
          contentType: 1,
        })
      }
      body.contentType = 1
    }
    if (!body.mediaUrl) {
    }
    console.log("body==>", body)
    console.log("data", { data: encryptData(body) })

    try {
      fetch(link + "addVReview", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": searchParams.get("id"),
        },
        body: JSON.stringify({ data: encryptData(body) }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("submit", res)
          setCodeData(res?.review)
          if (res?.message === 2003) handleContinueClick()
          else alert("already added")
        })
        .catch((error) => {
          console.error("Error fetching data:", error)
        })
    } catch (error) {
      console.error("Error fetching data:2", error)
    }
  }
  console.log("data==>", data)

  React.useEffect(() => {
    getPlan()
    try {
      let body = {
        productID: searchParams.get("productId"),
        merchantId: searchParams.get("merchantId"),
        productImage: searchParams.get("productImage"),
        item: {
          image: searchParams.get("productImage"),
          title: searchParams.get("productName"),
        },
      }
      console.log("body==>", body)
      setData({ ...body, attributes: [] })
      console.log("bodycheckStatus", body)
      console.log("body", encryptData(body))

      fetch(link + "getCollectReviews?merchantId=" + body?.merchantId)
        .then((response) => response.json())
        .then((res) => {
          console.log("getCollectReviews", res?.result)
          setSettings(res?.result?.reviewForm)
          setCollectSettings(res?.result)
        })
        .catch((error) => {
          console.error("Error fetching data:", error)
        })

      getAttributes()
      setError(false)
    } catch (error) {
      setError(true)
    }
  }, [])

  const getPlan = async () => {
    apiManager
      .get("getPlan")
      .then((res) => {
        console.log("getPlan232", res)
        apiManager?._setSubscription(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  const getAttributes = async () => {
    apiManager
      .get("getAttributes")
      .then((res) => {
        console.log("setAttributes", res)
        setAttributes(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }
  let videoReviews = collectSettings?.videoReviews?.show ?? true
  let photoReviews = collectSettings?.photoReviews?.show ?? true
  return (
    // alreadyAdded ? (
    //   <div className="container2">
    //     <main className={`content error}`}>
    //       <p>{"Review already added"}</p>
    //     </main>
    //   </div>
    // ) : error ? (
    //   <div className="container2">
    //     <main className={`content error}`}>
    //       <p>{"You cannot place this review"}</p>
    //     </main>
    //   </div>
    // ) : (
    <div className="rcf-swiper-maindiv" style={{ background: "#000" }}>
      <Swiper className="mySwiper rcf-swiper" allowTouchMove={false}>
        <SwiperSlide>
          <RateItemPopup
            onContinueClick={handleContinueClick}
            handleBack={handleBack}
            onTitleChange={(e) => {
              setData({ ...data, title: e.target.value })
            }}
            onReviewSelect={(rating) => {
              setData({ ...data, review: rating })
            }}
            data={data}
            settings={settings}
          />
        </SwiperSlide>
        {(videoReviews || photoReviews) && (
          <SwiperSlide>
            <ShareMedia
              onContinueClick={submit}
              handleBack={handleBack}
              settings={settings}
              collectSettings={collectSettings}
              videoReviews={videoReviews}
              photoReviews={photoReviews}
              setFiles={setFiles}
              files={files}
            />
          </SwiperSlide>
        )}
        <SwiperSlide>
          <TellUsStarter
            onContinueClick={submit}
            handleBack={handleBack}
            settings={settings}
            setFile={setFiles}
            attributes={attributes}
            onDescriptionChange={(e) => {
              setData({ ...data, description: e.target.value })
            }}
            onTitleChange={(e) => {
              setData({ ...data, title: e.target.value })
            }}
            onAttributeSelect={(attribute) => {
              setData({ ...data, attributes: attribute })
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ThatsAll
            onContinueClick={submit}
            handleBack={handleBack}
            settings={settings}
            setFile={setFiles}
            onNameChange={(e) => {
              setData({ ...data, reviewerName: e })
            }}
            onEmailChange={(e) => {
              setData({ ...data, reviewerEmail: e })
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ReviewSubSimple
            onContinueClick={submit}
            handleBack={handleBack}
            settings={settings}
            setFile={setFiles}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ThankYou settings={settings} data={codeData} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Rcf
