import React from "react"
import "../styles/rate-popup.css"
import { useSwiper } from "swiper/react"
import ProductImg from "../images/item-img.png"
import { CrossIcon, RateStar } from "../Svgs/Svg"

export default function RateItemPopup(props) {
  let { settings, onReviewSelect, data } = props
  const swiper = useSwiper()

  const selectReview = (e) => {
    onReviewSelect(e)
    setTimeout(() => {
      swiper.slideNext()
      window.scrollTo({ top: 0 })
    }, 250)
  }
  console.log("settings---rate", data)
  console.log("settings-->",settings)
  return (
    <div className="rate-popup-container" style={{'--star-hover-color':settings?.filledStarColor??'#EB2136','--rate-background':settings?.backgroundColor??'#fff','--rate-tooltip':settings?.toolTipColor??'#000'}}>
      <div className="rate-popup" style={{}} >
        <div className="cross-icon" onClick={() => {
          setTimeout(() => window.parent.postMessage({ 'showRcf': true }, '*'), 200)
        }}>
          <CrossIcon />
        </div>
        <div className="popup-content ">
          <div className="item-bubble">
            <img src={data?.item?.image ?? ProductImg} alt="product" />
            <p className="product-name">
              {(data?.item?.title.length > 30 ? data?.item?.title.substring(0, 30) + "..." : data?.item?.title) ?? "Product Review"}
            </p>
          </div>
          <h3 className="rate-highlight" style={{color: settings?.fontColor??'#000'}}>
            {settings?.reviewPageTitle?.text}
          </h3>
          <div className="rate-btns" >
            <button
              onClick={() => {
                selectReview(1)
              }}
            >
              <RateStar color={data?.review > 0 ? settings?.filledStarColor??'#EB2136': settings?.emptyStarColor??"#e1dfdf"} />
            </button>
            <button onClick={() => selectReview(2)}>
            <RateStar color={data?.review > 1 ? settings?.filledStarColor??'#EB2136' : settings?.emptyStarColor??"#e1dfdf"} />
            </button>
            <button onClick={() => selectReview(3)}>
            <RateStar color={data?.review > 2 ? settings?.filledStarColor??'#EB2136' : settings?.emptyStarColor??"#e1dfdf"} />
            </button>
            <button onClick={() => selectReview(4)}>
            <RateStar color={data?.review > 3 ? settings?.filledStarColor??'#EB2136' : settings?.emptyStarColor??"#e1dfdf"} />
            </button>
            <button onClick={() => selectReview(5)}>
            <RateStar color={data?.review > 4 ? settings?.filledStarColor??'#EB2136' : settings?.emptyStarColor??"#e1dfdf"} />
            </button>
          </div>
          <div className="hated-liked">
            <p className="hated">{settings?.option1?.text ?? "Hated It"}</p>
            <p className="Loved">{settings?.option2?.text ?? "Liked It"}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
