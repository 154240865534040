import React from "react"
import { Button } from "reactstrap"
import { useSwiper } from "swiper/react"
import QuoliLogo from "../../../asst/quoli-logo.svg"
import PoweredByQuoli from "../PoweredByQuoli"

export default function NewReviewSubSimple(props) {
  let { settings, onReviewSelect, data } = props
  const swiper = useSwiper()
  console.log("hellonuman", settings)

  return (
    <div
      className="review-simple-container review-rcf-sub"
      onLoad={() => {
        setTimeout(() => {
          window.parent.postMessage({ showRcf: true }, "*")
          window.location.reload()
        }, 3000)
      }}
    >
      <div className="review-sub-simple-popup">
        <div className="popup-content my-submit-popup">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <h3 className="highlight review-text-heading">
              {settings?.headingSubmission?.text ?? "Review Submitted!"}
            </h3>
            <p className="review-simple-highlight review-text-submit">
              {settings?.descriptionSubmission?.text ??
                "Thank you! We appreciate your feedback."}
            </p>
          </div>
          <div className="submit-btn-rcf-main-div-cont">
            <Button
              className="rcf-submit-btn-sub"
              onClick={() => {
                window.parent.postMessage({ showRcf: true }, "*")

                window.location.reload()
              }}
            >
              Close
            </Button>
          </div>
          <div className="text-center w-100">
            <PoweredByQuoli />
          </div>
          {/* <div className="last-submit-heading-main-container">
            <span className="rcf-company-heading-powered">Powered by</span>
            <div className="quoli-logo-main-div">
              <img src={QuoliLogo} alt="QuoliLogo" />
              <span className="quoli-title-text-tag">Quoli</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
