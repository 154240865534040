import { VerifiedIcon } from "../PDPWidget/PDPConstants/PDPContants"
import "swiper/css"
import "swiper/css/navigation"
import "./NuggetsSlider.scss"

// import required modules
import { Autoplay, Navigation } from "swiper"
import { SwiperSlide, Swiper } from "swiper/react"
import ApiManager from "../../classes/ApiManager"
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { checkAccess, getFirstNameAndLastInitial } from "../../helper"
import { isMobile } from "react-device-detect"
import ManCraftNuggets from "../ManCraft/ManCraftNuggets"

const NuggetsSlider = () => {
  const [searchParams] = useSearchParams()

  const apiManager = ApiManager.getInstance()
  let xToken = searchParams.get("store")
  let productId = searchParams.get("productId")
  let hideCustomerName = searchParams.get("hideCustomerName") === "true"
  let hideStoreName = searchParams.get("hideStoreName") === "true"

  let storeName = searchParams.get("storeName")
  let displayRatings = searchParams.get("displayRatings")
  let autoScroll = searchParams.get("autoScroll") == "true"
  let autoSpeed = parseInt(searchParams.get("autoSpeed")) * 1000
  let backgroundColor = "#" + searchParams.get("backgroundColor")
  let textColor = "#" + searchParams.get("textColor")

  const [brandSettingsData, setBrandSettingsData] = useState({})

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsPDPWidget", response.result)
        setBrandSettingsData(response.result)
        console.log(brandSettingsData, "")
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
  }, [])

  let nuggetLayout = searchParams.get("nuggetLayout") ?? ""
  apiManager._setXToken(xToken)

  const [nuggets, setNuggets] = useState([])
  const [nuggetSettings, setNuggetSettings] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getNuggetSettings()
    getNuggets()
    getPlan()
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    // sendNuggetsHeightToParent()
    // window.addEventListener("load", sendNuggetsHeightToParent)

    // return () => {
    //   window.removeEventListener("load", sendNuggetsHeightToParent)
    // }
  }, [])

  const getNuggetSettings = () => {
    apiManager
      .get("getNuggetSettings", {})
      .then((value) => {
        console.log(value, "setIsLoading==>")
        setNuggetSettings(value.result)
        console.log("nuggetSettings--->", nuggetSettings)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const getPlan = async () => {
    apiManager
      .get("getPlan")
      .then((res) => {
        console.log("getPlan", res)
        apiManager?._setSubscription(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  const getNuggets = () => {
    let body = { status: 1 }
    if (productId) body.productId = productId

    apiManager
      .get("getNuggets", body)
      .then((res) => {
        console.log("ressss", res)
        setNuggets(res?.result?.docs)
        console.log("nuggets--->", res?.result?.docs)

        setTimeout(() => {
          sendNuggetsHeightToParent(res?.result?.docs)
        }, 2000)
      })
      .catch((err) => { })
  }

  const sendNuggetsHeightToParent = (docs) => {
    let NuggetsHeight = document.body.scrollHeight
    console.log(
      "NuggetsHeight === 0 && nuggets?.length > 0",
      docs?.length > 0,
      document.body.scrollHeight
    )
    if (NuggetsHeight === 0 && docs?.length > 0) {
      NuggetsHeight = nuggetLayout === "2" ? 200 : 85
    }

    console.log("myNuggetsIframe", NuggetsHeight)
    window.parent.postMessage({ myNuggetsIframe: NuggetsHeight }, "*")
  }

  let activeNuggets = nuggetSettings?.generalSetting?.activeNuggets ?? true
  // console.log("storeName---->", storeName)

  return (
    <>
      {nuggets?.length > 0 && (
        <div
          className="nuggests-slider-wrapper"
        // onLoad={() => setTimeout(sendNuggetsHeightToParent, 600)}
        >
          {!isLoading &&
            activeNuggets &&
            checkAccess(
              "displayNuggets",
              <>
                {xToken === "7ddac4-3.myshopify.com" ? (
                  <ManCraftNuggets
                    nuggetSettings={nuggetSettings}
                    nuggets={nuggets}
                    storeName={storeName}
                  />
                ) : (
                  <div
                    className="nuggests-slider-content"
                    style={{
                      background:
                        backgroundColor,
                      "--nugget-scroll-color":
                        "#000",
                    }}
                  >
                    <Swiper
                      navigation={true}
                      autoplay={
                        autoScroll
                          ? {
                            delay:
                              autoSpeed ??
                              5000,
                          }
                          : false
                      }
                      slidesPerView={1}
                      spaceBetween={42}
                      modules={[Navigation, Autoplay]}
                      className="nuggets-slider"
                    >
                      {nuggets?.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div className="nuggets-slide">
                            <div
                              className="top-section"
                              style={{
                                color: textColor,
                                fontSize: isMobile
                                  ? "12px"
                                  : "14px",
                              }}
                            >
                              {!hideCustomerName && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                                    fill={
                                      brandSettingsData?.generalAppearance?.verifiedColor
                                    }
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7.55665 12.5737L4.6211 9.65576C4.53907 9.57373 4.49805 9.46826 4.49805 9.33936C4.49805 9.21045 4.53906 9.10498 4.6211 9.02296L5.27149 8.39014C5.35352 8.2964 5.45606 8.24953 5.5791 8.24953C5.70216 8.24953 5.81055 8.2964 5.90429 8.39014L7.87305 10.3589L12.0918 6.14014C12.1855 6.0464 12.294 5.99951 12.417 5.99951C12.54 5.99951 12.6426 6.04638 12.7246 6.14014L13.375 6.77296C13.457 6.85498 13.498 6.96046 13.498 7.08936C13.498 7.21827 13.457 7.32373 13.375 7.40576L8.18943 12.5737C8.10741 12.6675 8.00193 12.7144 7.87303 12.7144C7.74412 12.7144 7.63866 12.6675 7.55663 12.5737L7.55665 12.5737Z"
                                    fill="white"
                                  />
                                </svg>
                              )}
                              {/* {getFirstNameAndLastInitial(item?.reviewerName)} */}
                              {!hideCustomerName && item?.reviewerName
                                ? getFirstNameAndLastInitial(item?.reviewerName)
                                : ""}
                              &nbsp;&nbsp;
                              {(!hideStoreName &&
                                storeName) ??
                                storeName}
                            </div>
                            <p
                              style={{
                                color: textColor,
                                fontSize: isMobile
                                  ? "18px"
                                  : "24px",
                              }}
                            >
                              “{item?.reviewText}”
                            </p>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </>
            )}
        </div>
      )}
    </>
  )
}

export default NuggetsSlider
