import React from "react"
import "../styles/share-media.css"
import { useSwiper } from "swiper/react"
import TipIcon from "../images/tip-icon.png"
import {
  CircleCheck,
  CircleEmpty,
  CircleFill,
  ProggressLineDark,
  ProggressLineLite,
  ShareImgIcon,
  ShareVidIcon,
  CrossIcon,
} from "../Svgs/Svg"
import ReviewImage from "../images/review-popup-image.png"
import { checkAccess } from "../helper"

export default function ShareMedia(props) {
  let {
    settings,
    files,
    setFiles,
    collectSettings,
    photoReviews,
    videoReviews,
  } = props
  const swiper = useSwiper()
  return (
    <div className="share-media-container" style={{color: settings?.fontColor??'#000','--rate-background':settings?.backgroundColor??'#fff',}}>
      <div className="share-media-popup">
        <div className="share-media-progress-bar">
          <CircleCheck />
          <ProggressLineDark />
          <CircleFill />
          <ProggressLineLite />
          <CircleEmpty />
          <ProggressLineLite />
          <CircleEmpty />
        </div>
        <div className="popup-content">
          <h3 className="share-media-highlight" >
            {settings?.uploadMedia?.text ??
              (collectSettings?.discountForPhotoAndVideoReview?.typeOfDiscount ===
              "no"
              ? "Share Photo"
              : `Share photo & get ${collectSettings?.discountForPhotoAndVideoReview
                ?.typeOfDiscountValue === "Custom"
                ? collectSettings?.discountForPhotoAndVideoReview
                  ?.typeOfDiscountValueCustom
                : collectSettings?.discountForPhotoAndVideoReview
                  ?.typeOfDiscountValue ?? "15"
              }${collectSettings?.discountForPhotoAndVideoReview
                ?.typeOfDiscount === "perc"
                ? "%"
                : "$"
              } off`)}
          </h3>
          <div className="share-section">
            {photoReviews ? (
              <div className="share-img">
                <input
                  type="file"
                  name="share"
                  id="share-img"
                  accept="image/*"
                  multiple
                  onChange={(e) => {
                    console.log(e.target.files[0])
                    setFiles((prevFiles) => [...prevFiles, ...e.target.files])
                  }}
                />
                <label htmlFor="share-img">
                  <ShareImgIcon />
                  <p className="share-description">Add Photos</p>
                </label>
              </div>
            ) : (
              ""
            )}
            {checkAccess(
              "videoReviews",
              videoReviews ? (
                <div className="share-video">
                  <input
                    type="file"
                    name="share"
                    id="share-video"
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={(e) => {
                      console.log(e.target.files[0])
                      setFiles((prevFiles) => [...prevFiles, e.target.files[0]])
                    }}
                  />
                  <label htmlFor="share-video">
                    <ShareVidIcon />
                    <p className="share-description">Add Videos</p>
                  </label>
                </div>
              ) : (
                ""
              )
            )}
          </div>
          <div className="share-tip" style={{color: settings?.fontColor}}>
            <img src={TipIcon} alt="" />
            <p className="tip">
              {settings?.uploadMediaTootlTip?.text ??
                "Photo reviews help other customers make better decisions."}
            </p>
          </div>
          {files.map((file, index) => {
            return (
              <div className="added-img">
                <div className="img-info">
                  {file.type.startsWith("video/") ? (
                    <video
                      src={URL.createObjectURL(file)}
                      alt={`review ${index + 1}`}
                      style={{
                        width: "37px",
                        height: "37px",
                      }}
                    />
                  ) : (
                    <img
                      key={index}
                      src={URL.createObjectURL(file) ?? ReviewImage}
                      alt={`review ${index + 1}`}
                      style={{
                        width: "37px",
                        height: "37px",
                      }}
                    />
                  )}
                  <div className="info">
                    <p className="image-name">{file.name}</p>
                    <p className="image-size">{file.size} kb</p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setFiles((prevFiles) => {
                      return prevFiles.filter((prevFile) => {
                        return prevFile.name !== file.name
                      })
                    })
                  }}
                >
                  <button className="cross-icon">
                    <CrossIcon />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
        <div className="popup-footer-btns">
          <button
            className="back-btn"
            onClick={() => {
              swiper.slidePrev()
            }}
          >
            {" "}
            &lt; Back{" "}
          </button>
          <button
            className="next-btn"
            onClick={() => {
              swiper.slideNext()
              window.scrollTo({ top: 0 })
            }}
          >
            {files.length > 0 ? "Next" : "Skip"}
          </button>
        </div>
      </div>
    </div>
  )
}
