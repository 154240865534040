import React from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestIcon,
  PinterestShareButton,
} from "react-share"
const baseUrl = "https://d1zdq1lsqiesh.cloudfront.net"

export default function GetLink({ settings, id }) {
  let firstDisc =
    settings?.setupReferelOffer?.typeOfDiscount === "percentage"
      ? settings?.setupReferelOffer?.percentageValue + "%"
      : settings?.setupReferelOffer?.percentageValue
  let secondDisc = "$" + settings?.setupReferelOffer?.advocateReward
  let minimumPurchaseAmount = settings?.setupReferelOffer?.minimumPurchaseAmount

  return (
    <div class="referral-card">
      <div className="container-thank-you">
        <div className="thank-you-card disc-card ">
          {settings?.setupReferelOffer?.incentive === "2" ? (
            <header className="ty-submit">
              <h3>
                Give {firstDisc}, Get {secondDisc}
              </h3>
              <p>
                For every friend that uses their {firstDisc} discount, you’ll
                get a {secondDisc} discount as well!
              </p>
            </header>
          ) : (
            <header className="ty-submit">
              <h3>Get {secondDisc} off right now</h3>
              <p>You'll Get a {secondDisc} discount!</p>
            </header>
          )}

          <div className="social-icons p-0 d-flex justify-content-center gap-4">
            <TwitterShareButton
              url={`${baseUrl}/d/${id}`}
              quote={`Use this link to get discount `}
              hashtag="#quoli-product-reviews-ugc"
            >
              <TwitterIcon size={24} round />
            </TwitterShareButton>
            <FacebookShareButton
              url={`${baseUrl}/d/${id}`}
              quote={`Use this link to get discount `}
              hashtag="#quoli-product-reviews-ugc"
              className="ms-2"
            >
              <FacebookIcon size={24} round />
            </FacebookShareButton>
            <LinkedinShareButton
              url={`${baseUrl}/d/${id}`}
              quote={`Use this link to get discount `}
              hashtag="#quoli-product-reviews-ugc"
              media="/favicon.ico"
              className="ms-2"
            >
              <LinkedinIcon size={24} round />
            </LinkedinShareButton>
            <PinterestShareButton
              url={`${baseUrl}/d/${id}`}
              quote={`Use this link to get discount `}
              hashtag="#quoli-product-reviews-ugc"
              media="/favicon.ico"
              className="ms-2"
            >
              <PinterestIcon size={24} round />
            </PinterestShareButton>
          </div>

          <section className="disc-section pb-0">
            <div className="copy-code">
              <p className="code">
                {baseUrl}/d/{id}
              </p>
              <button
                className="copy"
                onClick={() =>
                  navigator.clipboard.writeText(`${baseUrl}/d/${id}`)
                }
              >
                Copy
              </button>
            </div>
            <p class="terms-cond">
              Minimum purchase amount: ${minimumPurchaseAmount}{" "}
              <a href="/">Privacy Policy.</a>
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}
