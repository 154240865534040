import React, { useEffect, useState } from "react"
import { RateStar, RateStar1 } from "../../../Svgs/Svg"
import uploaderImg from "../../../asst/ProductImageUploader.svg"
import { isMobile } from "react-device-detect"
import ApiManager from "../../../classes/ApiManager"
import { useSearchParams } from "react-router-dom"

const NewRatePopup = (props) => {
  const apiManager = ApiManager.getInstance()

  const [searchParams, setSearchParams] = useSearchParams()
  apiManager._setXToken(props.xToken)
  let storeName = props.storeName

  console.log(storeName, "askjdlkasjdlkasj")
  let { settings, onReviewSelect, data, setStarCheck, starCheck } = props

  const selectReview = (e) => {
    onReviewSelect(e)
    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 250)
  }

  console.log("new-rcf-rate", data)
  useEffect(() => {
    if (data.review > 0) {
      setStarCheck(false)
    }
  }, [data])

  const [hoverStarValue, setHoverStarValue] = useState(null)

  console.log(hoverStarValue, "====>>>hoverStarValue")

  return (
    <div>
      <div className="mobile-responsive-tab d-flex justify-content-center">
        <div
          className="rcf-product-upload-main-div d-flex g-2"
          style={{ gap: "20px", paddingLeft: "0px" }}
        >
          <div
            className="store-review-heading"
            style={{
              color: settings?.fontColor ?? "#000",
              textAlign: "center",
            }}
          >
            {" "}
            Rate {storeName ?? "this"} store out of 5
          </div>
          <div
            className="rate-popup-container new-rcf-rating"
            style={{
              "--star-hover-color": settings?.filledStarColor ?? "#FFC803",
              "--rate-background": settings?.backgroundColor ?? "#fff",
              "--rate-tooltip": settings?.toolTipColor ?? "#000",
            }}
          >
            <div className="rate-popup new-rcf-rate">
              <div className="popup-content new-rcf-rate">
                {isMobile ? (
                  <div className="rate-btns new-rcf-rate">
                    <button
                      onClick={() => {
                        selectReview(1)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 1 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(1)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 0
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                        className={`star-active-check ${hoverStarValue >= 1 ? "active" : ""
                          }`}
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(2)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 2 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(2)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 1
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(3)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 3 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(3)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 2
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(4)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 4 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(4)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 3
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(5)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 5 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(5)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 4
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                  </div>
                ) : (
                  <div className="new-rcf-rate-star" style={{ gap: "20px" }}>
                    <button
                      onClick={() => {
                        selectReview(1)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 1 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(1)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 0
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                        className={`star-active-check ${hoverStarValue >= 1 ? "active" : ""
                          }`}
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(2)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 2 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(2)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 1
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(3)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 3 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(3)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 2
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(4)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 4 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(4)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 3
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(5)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${hoverStarValue >= 5 ? "active" : ""
                        }`}
                      onMouseEnter={() => {
                        setHoverStarValue(5)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 4
                            ? settings?.filledStarColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div>
            <p className="error-description">
              {starCheck && "Please set your Rating"}
            </p>
          </div> */}
        </div>
      </div>
      <p className="required-rating-fields">
        {" "}
        {starCheck && "* Required field"}
      </p>
    </div>
  )
}

export default NewRatePopup
