export const CrossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <line
      x1="0.5"
      y1="-0.5"
      x2="15.6029"
      y2="-0.5"
      transform="matrix(0.70711 0.707103 -0.70711 0.707103 0.295166 1.07031)"
      stroke="black"
      stroke-linecap="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="15.6029"
      y2="-0.5"
      transform="matrix(0.70711 -0.707103 0.70711 0.707103 0.617188 12.3408)"
      stroke="black"
      stroke-linecap="round"
    />
  </svg>
)

export const CrossIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
  >
    <line
      x1="0.5"
      y1="-0.5"
      x2="15.6029"
      y2="-0.5"
      transform="matrix(0.70711 0.707103 -0.70711 0.707103 0.295166 1.07031)"
      stroke="black"
      stroke-linecap="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="15.6029"
      y2="-0.5"
      transform="matrix(0.70711 -0.707103 0.70711 0.707103 0.617188 12.3408)"
      stroke="black"
      stroke-linecap="round"
    />
  </svg>
)

export const RateStar = ({ color }) => (
  <svg
    width="50"
    height="48"
    viewBox="0 0 50 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9603 4.55918L30.3588 17.5388L30.7416 18.459L31.735 18.5386L45.7476 19.662L35.0714 28.8073L34.3146 29.4556L34.5458 30.425L37.8075 44.099L25.8108 36.7714L24.9603 36.2519L24.1098 36.7714L12.113 44.099L15.3748 30.425L15.606 29.4556L14.8492 28.8073L4.17301 19.662L18.1856 18.5386L19.179 18.459L19.5618 17.5388L24.9603 4.55918Z"
      fill={color}
      stroke={color}
      stroke-width="3.26321"
    />
  </svg>
)

export const RateStar1 = ({ color }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 50 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9603 4.55918L30.3588 17.5388L30.7416 18.459L31.735 18.5386L45.7476 19.662L35.0714 28.8073L34.3146 29.4556L34.5458 30.425L37.8075 44.099L25.8108 36.7714L24.9603 36.2519L24.1098 36.7714L12.113 44.099L15.3748 30.425L15.606 29.4556L14.8492 28.8073L4.17301 19.662L18.1856 18.5386L19.179 18.459L19.5618 17.5388L24.9603 4.55918Z"
      fill={color}
      stroke={color}
      stroke-width="3.26321"
    />
  </svg>
)

export const CircleCheck = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.06787"
      cy="6.88672"
      r="3.79998"
      fill="black"
      stroke="black"
      stroke-width="4.40003"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.14679 4.70707L5.0119 7.7325L4.18002 6.8437C4.02677 6.69922 3.78597 6.69046 3.61083 6.81305C3.44008 6.94002 3.39191 7.16332 3.497 7.34283L4.48212 8.9453C4.57844 9.09417 4.74482 9.18611 4.93309 9.18611C5.1126 9.18611 5.28336 9.09417 5.37968 8.9453C5.5373 8.73952 8.54522 5.15366 8.54522 5.15366C8.93927 4.75086 8.46203 4.39621 8.14679 4.70269V4.70707Z"
      fill="white"
    />
  </svg>
)

export const CircleFill = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.36694" cy="6.88672" r="6" fill="black" />
  </svg>
)

export const CircleEmpty = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.66577"
      cy="6.88672"
      r="5.68571"
      stroke="#BFBFBF"
      stroke-width="0.628576"
    />
  </svg>
)

export const ProggressLineDark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="2"
    viewBox="0 0 21 2"
    fill="none"
  >
    <path d="M0.0678711 0.886719H20.3669" stroke="black" />
  </svg>
)

export const ProggressLineLite = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="2"
    viewBox="0 0 21 2"
    fill="none"
  >
    <path d="M0.366943 0.886719H20.6659" stroke="#BFBFBF" />
  </svg>
)

export const ShareImgIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.416 23.4137L24.912 12.8949C23.3867 9.3263 20.5807 9.18241 18.6957 12.5784L15.9761 17.4852C14.5947 19.9746 12.019 20.1905 10.2347 17.9601L9.9181 17.5572C8.06184 15.2261 5.44293 15.5139 4.1047 18.1759L1.62969 23.1403C-0.111452 26.5938 2.40673 30.6661 6.26314 30.6661H24.6242C28.3655 30.6661 30.8837 26.8529 29.416 23.4137Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.24901 10.5205C10.6331 10.5205 12.5659 8.58774 12.5659 6.2036C12.5659 3.81946 10.6331 1.88672 8.24901 1.88672C5.86487 1.88672 3.93213 3.81946 3.93213 6.2036C3.93213 8.58774 5.86487 10.5205 8.24901 10.5205Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const ShareVidIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8038 20.3783C12.2416 20.3783 11.7196 20.2445 11.2511 19.9767C10.1802 19.361 9.56445 18.1027 9.56445 16.5098V12.5475C9.56445 10.968 10.1802 9.69626 11.2511 9.08051C12.3219 8.46475 13.7141 8.55846 15.0928 9.36163L18.5331 11.3427C19.8984 12.1325 20.6882 13.2971 20.6882 14.5286C20.6882 15.7601 19.8984 16.9247 18.5331 17.7145L15.0928 19.6956C14.3164 20.1508 13.5267 20.3783 12.8038 20.3783ZM12.8172 10.6868C12.603 10.6868 12.4156 10.727 12.2684 10.8207C11.84 11.075 11.5857 11.7042 11.5857 12.5475V16.5098C11.5857 17.3531 11.8266 17.9822 12.2684 18.2366C12.6967 18.4909 13.366 18.3838 14.1023 17.9555L17.5425 15.9743C18.2787 15.546 18.6937 15.0239 18.6937 14.5286C18.6937 14.0333 18.2787 13.4979 17.5425 13.0829L14.1023 11.1018C13.6204 10.8207 13.1786 10.6868 12.8172 10.6868Z"
      fill="black"
    />
    <path
      d="M14.464 28.9187C6.52605 28.9187 0.0739746 22.4666 0.0739746 14.5287C0.0739746 6.59075 6.52605 0.138672 14.464 0.138672C22.4019 0.138672 28.854 6.59075 28.854 14.5287C28.854 22.4666 22.4019 28.9187 14.464 28.9187ZM14.464 2.14658C7.63709 2.14658 2.08188 7.70179 2.08188 14.5287C2.08188 21.3556 7.63709 26.9108 14.464 26.9108C21.2909 26.9108 26.8461 21.3556 26.8461 14.5287C26.8461 7.70179 21.2909 2.14658 14.464 2.14658Z"
      fill="black"
    />
  </svg>
)

export const ProductLinkIcon = () => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect
      x="0.283203"
      y="0.18457"
      width="8.19238"
      height="8.19238"
      fill="url(#23e42dsdsddda)"
    />
    <defs>
      <pattern
        id="23e42dsdsddda"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_1925_58365" transform="scale(0.0078125)" />
      </pattern>
      <image
        id="image0_1925_58365"
        width="128"
        height="128"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAE8klEQVR4nO2dTWsdVRjHf00sFSziC6ggWhe60o1VaAsqWFz4AdKk0UgX+gXcuPALuHAjrqQLFbQaqQgiQv0CWpGufEFsJYK4qJS0ESNCmtTFyYVreu+dc2bOmTPnPP8fHLK4JzPPnf8vc+fl5hkQKbgXOA38DGwDNwYydoB14M10b10cBq6QP+ym8QPAfJptYJYDwFfAodyFeHAPcDB3EbXxHPn/skPG+lya7WCWw7kLCOQOCRCX9dwFBLJPAsTlfO4CQtFBYFz+xB0APp67EJGP24Gz5D/A8xr7Em0EAceBZ4BHSLenPQQcAZSjQV4ArtN9LyAKJFb4EqBAYoYvAQpjmbjhS4CCSBG+BCiENuHv+MwbP304CDwPPAHcmfodDYSrwAXgHPB35lqmsQx8QNip5DbwHXDU9xeeBtaIv3spZazhztmHRpu//Ou4A8VVz/k8AGwErqTGsQE85BVLP3QJHwIEeD9wJTWP1cZY+qFr+BAgwMXAFdU8fp8ZSz+0DX95z3K8BfA6WjQyNsl7XT1W+BAgQO6NPqTx9cRY+iFm+CABWo1TUzZmak4SN3yQAMHjvRkbMyUpwgcJ4DX+Bb4BVho2ZipShQ+eAtziWehnwMuec0tiE9jKtO6TwIeEX+F7Cfg4VhG+AmwB12KtVAwjfAB9K7h/BhM+SIC+GVT44P8RILrTNvwVEl6i1h6gHwYZPkiAPhhs+CABUrPEgMMHCZCSJeAMAw4fJEAqiggfJEAKigkfJEBsigofJEBMigsfJEAshhj+tsecHV0J7E7b8F8EPklSkeMXjzkXwe8e9FC+LTs0lmh3P3+ph9qOe9RyGs+iJcDNDDn8EbM6lVzBdTSVAC0oIXxw7WrenVDLj4y1tJMAYSxSRvjjPAa8AryGa2Z5YPxFCeBPieE3IgH8qDJ8kAA+LOK+F1ld+CABmqg6fJAAs6g+fJAA0zARPkiASbQNfzFHsV2RAP/HVPggAcZpE/4WBYcPEmDECQyGDxIADIcPEsB0+GBbAPPhg10BFP4uFgUoMfxk3cusCVBa+Kdw3cs2cX0MV4nc0dSSAG3DP5GjWFzjqkk1bRCxt7EVAUoLf6WhtjUiPfvXggClhQ+ue1lTjQsxVlS7ACWGvx/Xwq6pzje6rqj2/wxaAD4irBXOqOv22SQV+XEbe764OYXOD/aoWYAFXGOl0sLvlVoFUPie1CiAwg+gNgEUfiA1CfAUCj+YWgS4FXfVTOEHUkt/gCPAwwHzRy3XP01TTjnUsgd4NGCuwh+jFgH+8Jyn8PdQiwDngX8a5ij8CdQiwGXg9RmvK/wp1CIAwNvAq9y8J7gEPIvCn0gtZwHg7o69hbsWcBS4H9cK5VvcnTUxgZoEGHEZ+Dx3EaVQ00eAaIEEMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI4EMI5vm7hjlP3ksNLY39eKfAV4cHeIytBHgHEkgHEkgHHmgGu5ixCtudp1AXPAhQiFiDx0zm4OeCdCIaJ/fgPOdV3IPPATcB/wZNeFid74C/d080tdFzS/+/NL3MMV7gbuwj2HTwyLG8CvwBe48L+PsdD/AHjMLw8TLqGoAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
)

export const QuoliVerifyIcon = () => (
  <svg
    width="60"
    height="21"
    viewBox="0 0 60 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.519 10.5077L12.2501 12.7574C12.2626 12.7957 12.2869 12.829 12.3195 12.8527C12.3521 12.8763 12.3914 12.8891 12.4317 12.8891H14.8018C14.8421 12.8892 14.8813 12.9021 14.9139 12.9258C14.9464 12.9495 14.9706 12.9829 14.9831 13.0212C14.9956 13.0594 14.9956 13.1007 14.9833 13.139C14.9709 13.1774 14.9468 13.2108 14.9143 13.2346L12.9973 14.6326C12.9646 14.6559 12.9403 14.6891 12.9279 14.7273C12.9155 14.7655 12.9156 14.8066 12.9282 14.8447L13.6593 17.0943C13.6717 17.1327 13.6717 17.174 13.6592 17.2123C13.6467 17.2507 13.6224 17.2841 13.5898 17.3078C13.5572 17.3315 13.5179 17.3443 13.4776 17.3443C13.4372 17.3444 13.3979 17.3317 13.3653 17.308L11.4483 15.9197C11.4157 15.8963 11.3767 15.8837 11.3366 15.8837C11.2965 15.8837 11.2574 15.8963 11.2249 15.9197L9.30791 17.3129C9.27524 17.3365 9.23594 17.3492 9.19561 17.3492C9.15529 17.3491 9.11601 17.3363 9.08339 17.3126C9.05077 17.2889 9.02648 17.2555 9.01399 17.2172C9.0015 17.1788 9.00145 17.1375 9.01385 17.0991L9.74659 14.8495C9.75855 14.8112 9.75816 14.7701 9.74547 14.7321C9.73278 14.694 9.70844 14.6609 9.67589 14.6374L7.75889 13.2394C7.7264 13.2156 7.70225 13.1822 7.68989 13.1439C7.67753 13.1055 7.67759 13.0643 7.69006 13.026C7.70253 12.9877 7.72677 12.9543 7.75933 12.9306C7.79188 12.9069 7.83109 12.8941 7.87137 12.894H10.2415C10.2818 12.8939 10.321 12.8812 10.3536 12.8575C10.3863 12.8338 10.4106 12.8005 10.4231 12.7622L11.1542 10.5126C11.1667 10.4744 11.1909 10.4411 11.2233 10.4173C11.2557 10.3935 11.2947 10.3804 11.3349 10.3799C11.375 10.3794 11.4144 10.3914 11.4474 10.4143C11.4804 10.4372 11.5054 10.4699 11.519 10.5077Z"
      fill="#0ABAAF"
    />
    <path
      d="M7.65869 14.8962C6.73201 14.8696 5.83359 14.5712 5.07529 14.0379C4.31698 13.5045 3.73228 12.76 3.39397 11.8969C3.05566 11.0337 2.97868 10.0902 3.17263 9.18363C3.36657 8.27708 3.82287 7.4476 4.48471 6.79844C5.14656 6.14929 5.98472 5.70913 6.89485 5.53277C7.80498 5.35641 8.74688 5.45164 9.60329 5.80659C10.4597 6.16155 11.1928 6.76055 11.7113 7.52904C12.2299 8.29753 12.5109 9.20155 12.5195 10.1286H15.1724C15.1636 8.67687 14.7268 7.26001 13.9167 6.05537C13.1065 4.85073 11.959 3.91184 10.6178 3.35623C9.27662 2.80062 7.80133 2.65299 6.37663 2.93181C4.95192 3.21063 3.64112 3.90352 2.60832 4.92373C1.57552 5.94394 0.86661 7.24614 0.570338 8.66732C0.274065 10.0885 0.403596 11.5655 0.942714 12.9134C1.48183 14.2613 2.40658 15.4202 3.6012 16.2451C4.79581 17.07 6.2072 17.5241 7.65869 17.5507V14.8962Z"
      fill="#0ABAAF"
    />
    <path
      d="M27.7384 14.9707C28.5063 15.0875 29.1741 15.4715 29.8752 16.0558L29.0572 17.0407C27.922 16.2728 27.3878 16.1392 26.8035 16.1392C26.3361 16.1392 26.0523 16.1893 25.5849 16.2895C25.2176 16.3729 24.8337 16.4063 24.3996 16.4063C20.8939 16.4063 18.9575 13.9189 18.9575 10.4132C18.9575 6.89086 20.8939 4.42017 24.3996 4.42017C27.9053 4.42017 29.8418 6.89086 29.8418 10.4132C29.8418 12.3497 29.1073 13.969 27.7384 14.9707ZM20.5601 10.4132C20.5601 13.4014 21.979 15.2711 24.3996 15.2711C26.8202 15.2711 28.2392 13.4014 28.2392 10.4132C28.2392 7.40836 26.8202 5.55535 24.3996 5.55535C21.979 5.55535 20.5601 7.40836 20.5601 10.4132ZM38.9967 7.32489V16.1726H37.561V14.6702L37.0101 15.7052C36.2589 16.2561 35.6078 16.4063 34.7064 16.4063C32.87 16.4063 31.8016 15.3713 31.6013 14.1527C31.5345 13.7019 31.5178 13.3514 31.5178 12.6335V7.32489H32.9535V12.3664C32.9535 12.6502 32.9702 13.1343 33.0203 13.5851C33.1538 14.5867 33.8717 15.2711 35.0736 15.2711C36.4592 15.2711 37.561 14.2361 37.561 11.2145V7.32489H38.9967ZM45.1867 7.09118C48.1916 7.09118 49.5771 9.24468 49.5771 11.7488C49.5771 14.2361 48.1916 16.4063 45.1867 16.4063C42.1818 16.4063 40.7962 14.2361 40.7962 11.7488C40.7962 9.24468 42.1818 7.09118 45.1867 7.09118ZM45.1867 15.3045C46.9729 15.3045 48.0079 13.8188 48.0079 11.7488C48.0079 9.66203 46.9729 8.19297 45.1867 8.19297C43.4004 8.19297 42.3654 9.66203 42.3654 11.7488C42.3654 13.8188 43.4004 15.3045 45.1867 15.3045ZM52.6811 3.90267V13.6352C52.6811 13.9023 52.6811 14.186 52.6978 14.4698C52.7312 15.0374 52.9983 15.2211 53.7829 15.1376V16.1726C53.4658 16.2227 53.0985 16.2394 52.7646 16.2394C51.9299 16.2394 51.3123 15.9723 51.2622 14.8538C51.2455 14.5533 51.2455 14.1694 51.2455 13.6852V3.90267H52.6811ZM56.1546 4.42017C56.6721 4.42017 57.0728 4.82083 57.0728 5.33833C57.0728 5.85584 56.6721 6.25649 56.1546 6.25649C55.6371 6.25649 55.2365 5.85584 55.2365 5.33833C55.2365 4.82083 55.6371 4.42017 56.1546 4.42017ZM56.8725 16.1726H55.4368V7.32489H56.8725V16.1726Z"
      fill="black"
    />
  </svg>
)
export const QuoliVerifyIconDark = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.416016" y="0.593506" width="11.3305" height="11.3305" rx="5.66527" fill="#9C9C9C" />
    <path d="M7.7316 6.40845L8.05549 7.40502C8.06103 7.42198 8.0718 7.43676 8.08624 7.44724C8.10069 7.45772 8.11808 7.46338 8.13592 7.46339H9.18588C9.20372 7.46344 9.22109 7.46912 9.23552 7.47962C9.24994 7.49012 9.26068 7.50491 9.2662 7.52188C9.27172 7.53884 9.27175 7.55712 9.26627 7.5741C9.2608 7.59108 9.2501 7.60589 9.23571 7.61644L8.38649 8.23573C8.37203 8.24608 8.36125 8.26078 8.35574 8.27769C8.35023 8.29459 8.35028 8.31282 8.35588 8.3297L8.67977 9.32627C8.68526 9.34327 8.68524 9.36157 8.67971 9.37855C8.67418 9.39554 8.66341 9.41034 8.64896 9.42084C8.63451 9.43134 8.61711 9.43701 8.59925 9.43703C8.58138 9.43705 8.56397 9.43142 8.5495 9.42094L7.70028 8.80592C7.68587 8.79555 7.66856 8.78997 7.65081 8.78997C7.63305 8.78997 7.61575 8.79555 7.60133 8.80592L6.75211 9.42308C6.73764 9.43355 6.72023 9.43918 6.70236 9.43916C6.6845 9.43914 6.6671 9.43348 6.65265 9.42298C6.6382 9.41247 6.62744 9.39767 6.62191 9.38069C6.61637 9.3637 6.61635 9.3454 6.62185 9.3284L6.94644 8.33183C6.95174 8.31486 6.95157 8.29666 6.94595 8.2798C6.94033 8.26293 6.92954 8.24827 6.91512 8.23787L6.0659 7.61857C6.05151 7.60803 6.04081 7.59321 6.03534 7.57623C6.02986 7.55925 6.02989 7.54098 6.03541 7.52401C6.04093 7.50705 6.05167 7.49226 6.0661 7.48176C6.08052 7.47126 6.09789 7.46558 6.11573 7.46553H7.16569C7.18354 7.46551 7.20092 7.45986 7.21537 7.44938C7.22981 7.4389 7.24058 7.42412 7.24613 7.40716L7.57001 6.41059C7.57556 6.39367 7.58626 6.37891 7.60061 6.36838C7.61496 6.35784 7.63224 6.35205 7.65004 6.35181C7.66784 6.35158 7.68527 6.35691 7.6999 6.36707C7.71452 6.37722 7.7256 6.39169 7.7316 6.40845Z" fill="white" />
    <path d="M6.0214 8.35295C5.61089 8.3412 5.21289 8.20897 4.87697 7.97272C4.54104 7.73647 4.28202 7.40664 4.13215 7.02428C3.98228 6.64191 3.94818 6.22392 4.0341 5.82232C4.12001 5.42073 4.32215 5.05327 4.61535 4.7657C4.90854 4.47813 5.27984 4.28314 5.68302 4.20501C6.08621 4.12689 6.50346 4.16907 6.88285 4.32631C7.26224 4.48356 7.587 4.74891 7.8167 5.08935C8.04641 5.42979 8.17091 5.83027 8.17471 6.24093H9.34995C9.34606 5.59783 9.15255 4.97017 8.79366 4.43652C8.43476 3.90287 7.92642 3.48695 7.33228 3.24082C6.73814 2.99468 6.08459 2.92928 5.45345 3.0528C4.82232 3.17632 4.24164 3.48326 3.78411 3.93521C3.32659 4.38716 3.01254 4.96403 2.8813 5.5936C2.75005 6.22317 2.80743 6.87748 3.04626 7.47459C3.28508 8.07171 3.69474 8.58511 4.22395 8.95053C4.75316 9.31594 5.3784 9.51713 6.0214 9.5289V8.35295Z" fill="white" />
  </svg>

)

export const TickFilter = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_653_14491)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.40242 0.684653C8.46028 0.732449 8.50816 0.791176 8.54333 0.857479C8.57849 0.923783 8.60025 0.996364 8.60736 1.07108C8.61447 1.14579 8.60679 1.22117 8.58477 1.29292C8.56274 1.36467 8.5268 1.43137 8.47899 1.48922L3.8847 7.04922L3.88299 7.05037C3.77856 7.17596 3.64742 7.27667 3.49915 7.34518C3.35087 7.41368 3.18917 7.44825 3.02585 7.44637C2.86008 7.44435 2.69691 7.40485 2.54857 7.33083C2.40023 7.25681 2.27056 7.15018 2.16928 7.01894L2.1687 7.0178L0.729847 5.16865C0.681803 5.10964 0.64603 5.04162 0.624628 4.9686C0.603226 4.89557 0.596625 4.819 0.605214 4.74339C0.613802 4.66778 0.637407 4.59465 0.674642 4.52828C0.711877 4.46191 0.761993 4.40365 0.822049 4.35692C0.882105 4.31018 0.950891 4.27591 1.02437 4.25612C1.09785 4.23633 1.17454 4.23141 1.24995 4.24166C1.32535 4.25191 1.39795 4.27712 1.46348 4.3158C1.52901 4.35449 1.58616 4.40587 1.63156 4.46694L3.03956 6.27665L7.59728 0.761224C7.64507 0.703361 7.7038 0.655479 7.7701 0.620315C7.83641 0.585151 7.90899 0.563392 7.9837 0.556281C8.05841 0.549171 8.1338 0.556848 8.20554 0.578874C8.27729 0.6009 8.34399 0.636844 8.40185 0.684653H8.40242Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_653_14491">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.609375)"
        />
      </clipPath>
    </defs>
  </svg>
)
export const OfferDiscountCode = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.20128 21.2045H19.3195C20.1719 21.2045 20.8628 20.5135 20.8628 19.6612V6.54295C20.8628 5.69059 20.1719 4.99963 19.3195 4.99963H6.20128C5.34892 4.99963 4.65796 5.69059 4.65796 6.54295V19.6612C4.65796 20.5135 5.34892 21.2045 6.20128 21.2045Z"
      stroke="black"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.799438 16.5744V2.68455C0.799438 2.27523 0.962038 1.88269 1.25147 1.59326C1.54089 1.30383 1.93344 1.14124 2.34276 1.14124H16.2326"
      stroke="black"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

  </svg>
)