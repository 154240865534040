import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"
import "./CustomDropdown.scss"

const CustomDropdown = ({
  items,
  textBefore,
  ratings,
  onSelect,
  value,
  setSelectedAnswer,
  sort,
  selectedRating
}) => {
  const [selectedItem, setSelectedItem] = React.useState(value)

  const [filterTick, setFilterTick] = useState([])

  // const handleItemClick = (value) => {
  //   setFilterTick((prev) =>
  //     prev.includes(value)
  //       ? prev.filter((item) => item !== value)
  //       : [...prev, value]
  //   )
  //   setSelectedAnswer([...filterTick])
  // }

  const handleDropdownSelect = (eventKey) => {
    // console.log("eventKey", items[eventKey])
    // console.log("eventKey", [eventKey])
    console.log("eventKey", eventKey)

    setSelectedItem(
      eventKey
    )

    onSelect && onSelect(eventKey)
  }

  const renderItem = (item) => {
    return item.label
  }

  return (
    <div className="pdp-custom-dropdown" style={{ lineHeight: "35px" }}>
      <div onSelect={handleDropdownSelect}>
        <div
          id="dropdown-basic"
          className="filter-attribute-heading sort-filter"
        >
          {textBefore}
          {/* {items.find((item) => item?.value === selectedItem)?.label ||
            selectedItem?.value} */}
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
          >
            <path
              d="M5.50037 7.84806L1.70312 4.05082L2.58915 3.16479L5.50037 6.07602L8.41159 3.16479L9.29761 4.05082L5.50037 7.84806Z"
              fill="black"
            />
          </svg> */}
        </div>

        <div
          style={{
            display: items[0].value == 5 ? "flex" : "block",

            paddingTop: items[0].value == 5 ? "5px" : "",
            flexDirection: items[0].value == 5 ? "column-reverse" : "none",
            justifyContent: items[0].value == 5 ? "center" : "none",
            gap: "5px"
          }}
        >
          {items.map((item, index) => (
            <div className="new-sort-filter-item" onClick={() => handleDropdownSelect(item?.value)}>
              <span key={index} eventKey={item?.value}>
                {renderItem(item)} {sort === item?.value || selectedRating === item?.value ? <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_653_14491)"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.40242 0.684653C8.46028 0.732449 8.50816 0.791176 8.54333 0.857479C8.57849 0.923783 8.60025 0.996364 8.60736 1.07108C8.61447 1.14579 8.60679 1.22117 8.58477 1.29292C8.56274 1.36467 8.5268 1.43137 8.47899 1.48922L3.8847 7.04922L3.88299 7.05037C3.77856 7.17596 3.64742 7.27667 3.49915 7.34518C3.35087 7.41368 3.18917 7.44825 3.02585 7.44637C2.86008 7.44435 2.69691 7.40485 2.54857 7.33083C2.40023 7.25681 2.27056 7.15018 2.16928 7.01894L2.1687 7.0178L0.729847 5.16865C0.681803 5.10964 0.64603 5.04162 0.624628 4.9686C0.603226 4.89557 0.596625 4.819 0.605214 4.74339C0.613802 4.66778 0.637407 4.59465 0.674642 4.52828C0.711877 4.46191 0.761993 4.40365 0.822049 4.35692C0.882105 4.31018 0.950891 4.27591 1.02437 4.25612C1.09785 4.23633 1.17454 4.23141 1.24995 4.24166C1.32535 4.25191 1.39795 4.27712 1.46348 4.3158C1.52901 4.35449 1.58616 4.40587 1.63156 4.46694L3.03956 6.27665L7.59728 0.761224C7.64507 0.703361 7.7038 0.655479 7.7701 0.620315C7.83641 0.585151 7.90899 0.563392 7.9837 0.556281C8.05841 0.549171 8.1338 0.556848 8.20554 0.578874C8.27729 0.6009 8.34399 0.636844 8.40185 0.684653H8.40242Z" fill="black"></path></g><defs><clipPath id="clip0_653_14491"><rect width="8" height="8" fill="white" transform="translate(0.609375)"></rect></clipPath></defs></svg> : ""}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CustomDropdown
