import { encryptData } from "../helper"

export default class ApiManager {
  static POST = "POST"
  static GET = "GET"
  static DELETE = "DELETE"
  static PUT = "PUT"
  static PATCH = "PATCH"

  //returns instance of this class
  static getInstance() {
    if (ApiManager.singleton == null) {
      ApiManager.singleton = new ApiManager()
    }
    return ApiManager.singleton
  }

  //Use this function to make post calls to the server
  post = (endpoint, body) => {
    return new Promise((resolve, reject) => {
      let endpointUrl = this.generateEndpointUrl(endpoint)
      this._callServer(endpointUrl, body, ApiManager.POST)
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  //Use this function to make get calls to the server
  get = (endpoint, params) => {
    return new Promise((resolve, reject) => {
      let endpointUrl =
        this.generateEndpointUrl(endpoint) + this.convertToGetParams(params)
      this._callServer(endpointUrl, null, ApiManager.GET)
        .then((json) => {
          resolve(json)
        })

        .catch((error) => {
          reject(error)
        })
    })
  }

  //Use this function to make put calls to the server
  put = (endpoint, body) => {
    return new Promise((resolve, reject) => {
      let endpointUrl = this.generateEndpointUrl(endpoint)
      this._callServer(endpointUrl, body, ApiManager.PUT)
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  patch = (endpoint, body) => {
    return new Promise((resolve, reject) => {
      let endpointUrl = this.generateEndpointUrl(endpoint)
      this._callServer(endpointUrl, body, ApiManager.PATCH)
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  //Use this function to make delete calls to the server
  delete = (endpoint, body) => {
    return new Promise((resolve, reject) => {
      let endpointUrl = this.generateEndpointUrl(endpoint)
      this._callServer(endpointUrl, body, ApiManager.DELETE)
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  //generate the url for the specific endpoint
  generateEndpointUrl = (endpoint) => {
    // return "http://localhost:5001/api/" + endpoint
    // return "http://192.168.18.12:5001/api/" + endpoint
    // return "https://stagingapi.quoli.io/api/" + endpoint

    return (process.env.REACT_APP_API_URL || "https://api.quoli.io/api/") + endpoint
  }

  //convert object to GET params
  convertToGetParams = (params) => {
    let getParams = ""
    for (var key in params) {
      if (getParams != "") {
        getParams += "&"
      }
      getParams += key + "=" + params[key]
    }
    return "?" + getParams
  }

  //Make the actual call to server here. this should not be called from outside this class
  _callServer = (endpoint, body = null, method = ApiManager.GET) => {
    console.log("Endpoint: ", endpoint)

    //check if this api is called before login and is one of api with/without login

    return new Promise((resolve, reject) => {
      let headers = {}
      const lastSegment = endpoint.split("/").pop()
      //if token is present then qomie_session_id will be there already
      console.log("last", lastSegment)
      if (true) {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization:
            // "Basic Y2d2ZHc2cjV0MXRld3gzcGttemc6ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5SWpvaVkyZDJaSGMyY2pWME1YUmxkM2d6Y0d0dGVtY2lMQ0pwWVhRaU9qRTJPREV6TVRjME1UQjkudFM4UDFDYTNUTG81U0FFR1g4VTlhRTNNZUhST3BydXg5bjM3NmNsTGpQUQ==",
            "Basic Y2c5MGRteXBqNGJxdGYxY2pzejA6ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5SWpvaVkyYzVNR1J0ZVhCcU5HSnhkR1l4WTJwemVqQWlMQ0pwWVhRaU9qRTJOemsxTmpZeU9EZDkuSHhBNE9zRnFacXd1UUtOa2xsbEw0WHFwaFowZlBxN0hTcHU2UnJqY0hJNA==",
          xxToken: encryptData(this.xToken),
        }
        //if token is not present but qomie_session_id is present
      } else {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      }

      console.log("headers: ", headers)

      fetch(endpoint, {
        method: method,
        headers: headers,
        mode: "cors",
        body: body !== null ? JSON.stringify(body) : null,
      })
        .then((response) => response.json())
        .then((json) => {
          console.log("json in fetch: ", endpoint, json)
          if (
            json.message === "1003" ||
            json.message === "1004" ||
            json.message === 5014 ||
            json.message === 5067
          ) {
            reject(json.message)
          } else if (
            json.message === "Unauthenticated." ||
            json.message === "Unauthenticated"
          ) {
            reject({ message: "Unauthenticated" })
          } else resolve(json)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  //sets the token in the session
  _setToken = (email, token) => {
    this.token = token
  }

  _setSubscription = (subscription) => {
    this.subscription = subscription
  }

  //return the token in the session
  _getSubscription = () => {
    return this.subscription
  }

  // setting token without encoded
  _setXToken = (token) => {
    console.log("successsuccess", token)
    this.xToken = token
  }

  //return the token in the session
  _getToken = () => {
    return this.token
  }

  _setGeneralSettings = (generalSettings) => {
    this.generalSettings = generalSettings
  }

  //return the token in the session
  _getGeneralSettings = () => {
    return this.generalSettings
  }
}
