import React, { useEffect, useState } from "react"
import { RateStar, RateStar1 } from "../../../Svgs/Svg"
import uploaderImg from "../../../asst/ProductImageUploader.svg"
import { isMobile } from "react-device-detect"

const NewRatePopup = (props) => {
  let {
    settings,
    onReviewSelect,
    data,
    setStarCheck,
    starCheck,
    ordersData,
    orderProductIndex,
    brandSettingsData
  } = props
   
  const selectReview = (e) => {
    onReviewSelect(e)
    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 250)
  }

  // console.log(item, "===>item")
  console.log("new-rcf-rate", data)
  useEffect(() => {
    if (data.review > 0) {
      setStarCheck(true)
    }
  }, [data])

  const [hoverStarValue, setHoverStarValue] = useState(null)

  console.log(hoverStarValue, "====>>>hoverStarValue")
  // console.log(ordersData[0]?.products, "==>ordersData")

  return (
    <div>
      {ordersData[0]?.products?.length > 1 ? (
        <span className="product-count">
          Product {orderProductIndex} Of {ordersData[0]?.products.length}
        </span>
      ) : (
        ""
      )}
      <div className="mobile-responsive-tab">
        <div>
          {ordersData ? (
            <img
              className="uploaderImg-new-rcf"
              src={
                ordersData[0]?.products[orderProductIndex - 1]?.image ??
                uploaderImg
              }
              alt="product like"
            />
          ) : (
            <img
              className="uploaderImg-new-rcf"
              src={data?.item?.image ?? uploaderImg}
              alt="product one"
            />
          )}
        </div>
        <div className="rcf-product-upload-main-div">
          {ordersData ? (
            <span
              className="rcf-pruduct-name"
              style={{
                color: settings?.fontColor ?? "#000",
              }}
            >
              {" "}
              {/* {item?.title} */}
              {ordersData[0]?.products[orderProductIndex - 1]?.title}
            </span>
          ) : (
            <span
              className="rcf-pruduct-name"
              style={{
                color: settings?.fontColor ?? "#000",
              }}
            >
              {" "}
              {data?.item?.title}
            </span>
          )}
          <div
            className="rate-popup-container new-rcf-rating"
            style={{
              "--star-hover-color": brandSettingsData?.generalAppearance?.starColor ?? "#FFC803",
              "--rate-background": settings?.backgroundColor ?? "#fff",
              "--rate-tooltip": settings?.toolTipColor ?? "#000",
            }}
          >
            <div className="rate-popup new-rcf-rate">
              <div className="popup-content new-rcf-rate">
                {isMobile ? (
                  <div className="rate-btns new-rcf-rate">
                    <button
                      onClick={() => {
                        selectReview(1)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 1 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(1)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar1
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 0
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                        className={`star-active-check ${
                          hoverStarValue >= 1 ? "active" : ""
                        }`}
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(2)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 2 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(2)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar1
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 1
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(3)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 3 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(3)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar1
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 2
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(4)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 4 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(4)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar1
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 3
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(5)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 5 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(5)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar1
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 4
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                  </div>
                ) : (
                  <div className="new-rcf-rate-star">
                    <button
                      onClick={() => {
                        selectReview(1)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 1 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(1)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 0
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                        className={`star-active-check ${
                          hoverStarValue >= 1 ? "active" : ""
                        }`}
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(2)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 2 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(2)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 1
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(3)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 3 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(3)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 2
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(4)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 4 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(4)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 3
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                    <button
                      onClick={() => {
                        selectReview(5)
                        setHoverStarValue(null)
                      }}
                      className={`star-active-check ${
                        hoverStarValue >= 5 ? "active" : ""
                      }`}
                      onMouseEnter={() => {
                        setHoverStarValue(5)
                      }}
                      onMouseLeave={() => {
                        setHoverStarValue(null)
                      }}
                    >
                      <RateStar
                        hoverStarValue={hoverStarValue}
                        color={
                          data?.review > 4
                            ? brandSettingsData?.generalAppearance?.starColor ?? "#FFC803"
                            : settings?.emptyStarColor ?? "#e1dfdf"
                        }
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            {/* <p className="error-description">
              {starCheck && "Please set your Rating"}
            </p> */}
          </div>
        </div>
      </div>
      <p className="required-rating-fields">* Required fields</p>
    </div>
  )
}

export default NewRatePopup
