import { SearchIcon } from "../../PDPConstants/PDPContants"
import "./SearchInput.scss"

const SearchInput = ({
  placeholder,
  onChange,
  secondaryColor,
  primaryColor,
}) => {
  return (
    <div className="pdp-search-input-wrapper">
      <SearchIcon />
      <input
        style={{
          outlineColor: secondaryColor,
          color: primaryColor,
          width: "66%",
          borderRadius: "6px",
          fontWeight: "400",
        }}
        type="search"
        className="pdp-search-input"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}

export default SearchInput
